import { AccountCircle, Comment, Search } from '@mui/icons-material';
import { Button, Checkbox, FormControl, FormControlLabel, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Radio, RadioGroup, Select, TextField, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startGetCustomers } from '../../../redux/actions/customers';
import { setResultsByCustomerFile, startDownloadReportByCustomer, startGetReportByCustomer } from '../../../redux/actions/reports';
import ButtonGenerateReport from '../../formControls/ButtonGenerateReport';
import { InputSeatch } from '../../formControls/InputSeatch';
import { ReportByCustomerResult } from './ReportByCustomerResult';
import DateRange from '../../formControls/DateRange';
import CustomCheckBox from '../../formControls/CustomCheckBox';
import { openSnackbar } from '../../../helpers/helpers';


export const ReportByCustomer = () => {

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const { customers } = useSelector(state => state.customers);
    const [customersToDisplay, setCustomersToDisplay] = useState([]);

    const { reportByCustomerFile } = useSelector(state => state.reports);


    const [selectedCustomer, setSelectedCustomer] = useState('');

    const [groupByProjects, setGroupByProjects] = useState(false);
    const [groupByUsers, setGroupByUsers] = useState(false);

    const [allCustomers, setAllCustomers] = useState(false);

    const [loading, setLoading] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);


    const [dateFrom, setDateFrom] = useState();
    const [dateTo, setDateTo] = useState();


    const handleOnChangeFrom = (df) => {
        setDateFrom(df);
    }

    const handleOnChangeTo = (dt) => {
        setDateTo(dt);
    }


    useEffect(() => {
        dispatch(startGetCustomers());
    }, []);

    useEffect(() => {
        setCustomersToDisplay(customers);
    }, [customers]);

    useEffect(() => {
        setGroupByProjects(false);
        setGroupByUsers(false);
    }, [allCustomers]);

    useEffect(() => {
        if (reportByCustomerFile) {
            const byteArray = new Uint8Array(atob(reportByCustomerFile).split('').map(char => char.charCodeAt(0)));
            const blob = new Blob([byteArray], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = getReportName();
            document.body.appendChild(link);
            link.click();
            dispatch(setResultsByCustomerFile(null));
        }
    }, [reportByCustomerFile])

    const handleGetPreview = () => {
        if (selectedCustomer == 0 && !allCustomers) {
            openSnackbar(enqueueSnackbar, 'Select Customer', 'error');
            return;
        }
        setLoading(true);
        dispatch(startGetReportByCustomer(
            dateFrom,
            dateTo,
            selectedCustomer == '' ? 0 : selectedCustomer,
            groupByProjects,
            groupByUsers,
            allCustomers,
            { enqueueSnackbar, setLoading }));
    }

    const filterCustomers = (e) => {
        const value = e.target.value;
        if (value) {
            const results = customers.filter(x => x.name.toLowerCase().includes(value.toLowerCase()));
            setCustomersToDisplay(results);
        } else {
            setCustomersToDisplay(customers);
        }
    }

    const allCustomersClicked = () => {
        setAllCustomers(!allCustomers);
    }

    const generateFile = (type) => {
        setLoadingDownload(true);
        dispatch(startDownloadReportByCustomer(dateFrom, dateTo, selectedCustomer == '' ? 0 : selectedCustomer, groupByProjects, groupByUsers, allCustomers, { enqueueSnackbar, setLoading: setLoadingDownload }));
    }

    const getReportName = () => {
        if (allCustomers) {
            return 'Customers report.pdf';
        } else {
            var selectedCustomer = this.customers.filter(x => x.id == selectedCustomer)[0];
            if (selectedCustomer) {
                return selectedCustomer.name + ' report.pdf';

            } else {
                return 'Customer report.pdf';
            }

        }
    }

    return (
        <div className="d-flex flex-column flex-grow-1 justify-content-between">

            <div>

                <div className="c-form-group">
                    <label>Select Customer</label>
                    <InputSeatch onChange={filterCustomers} placeholder={"Filter..."} />
                    <CustomersList customers={customersToDisplay} selectedCustomer={selectedCustomer} setSelectedCustomer={setSelectedCustomer} allCustomers={allCustomers} />
                </div>

                <div className="form-check c-form-group mt-12px">
                    <input className="form-check-input c-checkbox" type="checkbox" onChange={allCustomersClicked} checked={allCustomers} id="allCustomers" />
                    <label className="form-check-label c-checkbox-label" htmlFor="allCustomers">
                        All Customers
                    </label>
                </div>

                <Options
                    groupByProjects={groupByProjects}
                    setGroupByProjects={setGroupByProjects}
                    groupByUsers={groupByUsers}
                    setGroupByUsers={setGroupByUsers}
                    allCustomers={allCustomers}
                />
            </div>

            <div className="d-flex flex-column mt-12px">
                <DateRange onChangeFrom={handleOnChangeFrom} onChangeTo={handleOnChangeTo} />
                <div className='mt-12px'>

                    <button className="mb btn-blue w-100" disabled={loading} onClick={handleGetPreview} >Preview Data</button>
                    <div className="mt-12px d-flex">
                        <ButtonGenerateReport generateFile={generateFile} loading={loadingDownload} />
                    </div>
                </div>
                {/* <ReportByUserResult /> */}
            </div>
        </div>
    )
}

const Options = ({ groupByProjects, setGroupByProjects, groupByUsers, setGroupByUsers, allCustomers }) => {


    const handleGroupByProjects = () => {
        setGroupByProjects(!groupByProjects);
    }

    const handleGroupByUsers = () => {
        setGroupByUsers(!groupByUsers);
    }

    return (
        <>
            <div className="c-form-group mt-12px">
                <label>Options</label>
                <CustomCheckBox id={'groupByProjects'} text="Group by projects" checked={groupByProjects} disabled={allCustomers} handleChange={handleGroupByProjects} />
                <CustomCheckBox id={'groupByUsers'} text="Group by users" checked={groupByUsers} disabled={allCustomers} handleChange={handleGroupByUsers} />
            </div>
        </>
    )
}

const CustomersList = ({ customers, selectedCustomer, setSelectedCustomer, allCustomers }) => {

    const handleToggle = (event) => {
        setSelectedCustomer(event.target.value);
    }

    useEffect(() => {
        setSelectedCustomer('');
    }, [allCustomers])


    return (

        <div className="reports__items-list">
            <select value={selectedCustomer}
                className="mi mt-2"
                name="selectedCustomer"
                disabled={allCustomers}
                onChange={handleToggle}>
                <option value="" disabled></option>
                {
                    customers?.map((c) => <option key={c.id} value={c.id}>{c.name}</option>)
                }
            </select>
        </div>

    )
}


