import { MoreVert } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, FormControl, IconButton, Menu, MenuItem, Select } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { calculateHoursDiff, dateIsToday, formatTime, sameDates, sumEntriesHours, validTime } from "../../../helpers/helpers";
import { useDispatch, useSelector } from "react-redux";
import { startDeleteEntry, startDuplicateEntry, startUpdateEntry } from "../../../redux/actions/entry";
import { useForm } from "../../../hooks/useForm";
import { useSnackbar } from 'notistack';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import Duration from "../../formControls/Duration";



const borderOnHover = (hover) => ({
    border: hover ? '1px solid #212121' : '1px solid transparent',
})

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 200,
        },
    },
};

const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

const EntriesTableItem = ({ entries, entry, lastEntry, nextEntry, groupByDays }) => {

    const dispatch = useDispatch();

    const [isHover, setIsHover] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const { userProjects } = useSelector(state => state.projects);
    const { activities } = useSelector(state => state.activities);

    const [formValues, handleInputChange, reset] = useForm({ ...entry });

    const { date, timeStart, timeFinish, description, projectID, activityID } = formValues;

    useEffect(() => {

    }, []);

    useEffect(() => {
        setIsHover(false);
    }, [date, projectID, activityID]);

    useEffect(() => {
        reset({ ...entry, timeStart: formatTime(entry.timeStart), timeFinish: formatTime(entry.timeFinish) });
    }, [entry])

    //menu
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setIsHover(false);
    };
    //end menu

    const mouseEnter = (hover) => {
        setIsHover(hover)
    }

    const onDateChange = (newDate) => {
        setIsHover(false);

        handleInputChange({
            target: {
                name: 'date',
                value: newDate
            }
        });

        dispatch(startUpdateEntry(entry.id, { ...formValues, date: newDate }, true, reset, enqueueSnackbar))
    }

    const handleDescriptionChange = ({ target }) => {
        if (formValues.description !== entry.description) {
            dispatch(startUpdateEntry(entry.id, { ...formValues, description: formValues.description }, false, reset, enqueueSnackbar))
        }
    }

    const handleTimeStartChange = ({ target }) => {

        let { value } = target;
        if (validTime(value)) {
            let timeStart = formatTime(value);
            if (timeStart !== entry.timeStart) {
                dispatch(startUpdateEntry(entry.id, { ...formValues, timeStart }, true, reset, enqueueSnackbar));
            } else {
                handleInputChange({
                    target: {
                        name: 'timeStart',
                        value: entry.timeStart
                    }
                });
            }
        } else {
            handleInputChange({
                target: {
                    name: 'timeStart',
                    value: entry.timeStart
                }
            });
        }
    }

    const handleTimeFinishChange = ({ target }) => {
        let { value } = target;
        if (validTime(value)) {
            let timeFinish = formatTime(value);
            if (timeFinish !== entry.timeFinish) {
                dispatch(startUpdateEntry(entry.id, { ...formValues, timeFinish }, true, reset, enqueueSnackbar));
            } else {
                handleInputChange({
                    target: {
                        name: 'timeStart',
                        value: entry.timeFinish
                    }
                });
            }
        } else {
            handleInputChange({
                target: {
                    name: 'timeFinish',
                    value: entry.timeFinish
                }
            });
        }
    }

    const handleSelectChange = ({ target }) => {
        handleInputChange({
            target: {
                name: target.name,
                value: target.value
            }
        });
        dispatch(startUpdateEntry(entry.id, { ...formValues, [target.name]: target.value }, false, reset, enqueueSnackbar));
    }

    const [loadingAction, setLoadingAction] = useState(false);

    const handleDeleteEntry = () => {
        setLoadingAction(true);
        dispatch(startDeleteEntry(entry.id, { enqueueSnackbar, setLoading: setLoadingAction }))
    }

    const handleDuplicateEntry = () => {
        setLoadingAction(true);
        dispatch(startDuplicateEntry(entry, { enqueueSnackbar, setLoading: setLoadingAction }));
    }

    const [popover, setPopover] = useState(true);

    const handleSetPopover = () => {
        if (!loadingAction) {
            setPopover(true);
        }
    }

    const handleSaveDescription = () => {
        setPopover(false);
        setIsHover(false);
        handleDescriptionChange({});
    }

    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
            e.target.blur(e);
            //Write you validation logic here
        }
    }

    return (
        <>

            <tr key={entry.id} className={loadingAction ? 'loading-action' : ''} onMouseEnter={() => mouseEnter(true)} onMouseLeave={() => mouseEnter(false)}
            >
                <td>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            disabled={loadingAction}
                            label="Custom input"
                            value={date}
                            onChange={(newValue) => onDateChange(newValue)}
                            renderInput={({ inputRef, inputProps, InputProps }) => (
                                <Box sx={{ display: 'flex', alignItems: 'center', svg: { color: isHover ? '' : 'transparent' } }}>
                                    {
                                        dateIsToday(new Date(inputProps.value)) ?
                                            <input ref={inputRef} value="Today" disabled className="entry-date-input fw-600" />
                                            :
                                            <input ref={inputRef} value={moment(new Date(inputProps.value)).format('LL')} disabled className="entry-date-input fw-600" />
                                    }
                                    {InputProps?.endAdornment}
                                </Box>
                            )}
                        />
                    </LocalizationProvider>
                </td>
                <td style={{ verticalAlign: 'middle' }}>

                    <PopupState variant="popover" popupId="demo-popup-popover">
                        {(popupState) => (
                            <div>
                                <span onClick={handleSetPopover}>
                                    <span
                                        className="entry-description-input"
                                        title={entry.description}
                                        placeholder="Write something..."
                                        style={borderOnHover(isHover)}
                                        {...(loadingAction ? {} : bindTrigger(popupState))}
                                    // {  ...bindTrigger(popupState)}
                                    >{entry.description}</span>
                                </span>

                                {
                                    popover && <>
                                        <Popover
                                            {...bindPopover(popupState)}
                                            open={popupState.isOpen}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                        >
                                            <div className="popover-container">
                                                <textarea
                                                    className="popover-textarea"
                                                    name="description"
                                                    rows={4}
                                                    onChange={handleInputChange}
                                                    value={description} />
                                                <button className="mb btn-blue w-100 text-center" onClick={handleSaveDescription} >Save</button>

                                            </div>
                                        </Popover>
                                    </>
                                }

                            </div>
                        )}
                    </PopupState>
                </td>
                <td>
                    <div className="d-flex align-items-center">

                        <FormControl sx={{ m: 0, minWidth: 60, padding: '2px 6px' }} variant="standard" size="small">
                            <Select
                                disabled={loadingAction}
                                className="fs-14 entry-select"
                                value={projectID}
                                name="projectID"
                                autoWidth
                                onChange={handleSelectChange}
                                disableUnderline={true}
                                MenuProps={MenuProps}
                            >

                                {
                                    !(userProjects?.map(x => x.id).includes(entry.project.id)) &&
                                    <MenuItem disabled value={entry.project.id} >
                                        <em>{entry.project.name}</em>
                                    </MenuItem>
                                }
                                {
                                    userProjects?.map((p, index) => (
                                        <MenuItem key={index} value={p.id}>
                                            {/* <span className="select-project-name">{p.name} <span>({p.customer.name})</span> </span> */}
                                            <span className="select-project-name">{p.name} </span>
                                        </MenuItem>
                                    ))
                                }

                            </Select>
                        </FormControl>
                        {/* <span className="mx-1 blue-grey-text"> / </span> */}
                        <FormControl sx={{ m: 0, minWidth: 30, background: '#E5F5FD', padding: '2px 6px', borderRadius: '2px' }} className="ms-1" variant="standard" size="small">
                            <Select
                                className="fs-14 entry-select"
                                value={activityID}
                                name="activityID"
                                autoWidth
                                disabled={loadingAction}
                                onChange={handleSelectChange}
                                disableUnderline={true}
                                MenuProps={MenuProps}
                                style={{ color: '#24577C' }}
                            >
                                <MenuItem disabled value={-1}>
                                    <em>Activity</em>
                                </MenuItem>

                                {
                                    activities?.map((a, index) => (
                                        <MenuItem key={index} value={a.id}>{a.name}</MenuItem>
                                    ))
                                }

                            </Select>
                        </FormControl>
                    </div>

                </td>

                <td>
                    <input className="entry-time-input" disabled={loadingAction} style={borderOnHover(isHover)} name="timeStart" onBlur={handleTimeStartChange} onKeyDown={(e) => handleKeyPress(e)} onChange={handleInputChange} value={timeStart} />
                    <span>-</span>
                    <input className="entry-time-input" disabled={loadingAction} style={borderOnHover(isHover)} name="timeFinish" onBlur={handleTimeFinishChange} onKeyDown={(e) => handleKeyPress(e)} onChange={handleInputChange} value={timeFinish} />
                </td>
                <td>
                    <span className="total-duration">
                        <Duration value={entry.totalTime} />

                    </span>

                </td>
                <td>

                    <IconButton component="label" id={entry.id}
                        disabled={loadingAction}
                        aria-controls={open ? entry.id : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        style={{ width: '26px', height: '26px', marginLeft: '8px', color: isHover ? 'unset' : 'white' }}
                        onClick={handleClick}>
                        <MoreVert />
                    </IconButton>

                    <Menu
                        id={entry.id}
                        aria-labelledby={entry.id}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem onClick={() => { handleClose(); handleDuplicateEntry() }}>Duplicate</MenuItem>
                        <MenuItem onClick={() => { handleClose(); handleDeleteEntry() }}>Delete</MenuItem>
                    </Menu>
                </td>
            </tr>

        </>
    )
}



export default EntriesTableItem;