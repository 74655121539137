import { ArrowBack, Edit } from '@mui/icons-material'
import { Alert, Autocomplete, Button, ButtonBase, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemText, MenuItem, Paper, Radio, RadioGroup, Select, Skeleton, TextField, Tooltip, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useSnackbar } from 'notistack'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { setActiveProject, startAddUserProject, startDeleteProject, startGetProjectDetails } from '../../redux/actions/projects'
import { startGetUsers } from '../../redux/actions/users'
import DeleteIcon from '@mui/icons-material/Delete';
import ProjectMembersList from './ProjectMembersList'

export const ProjectDetail = () => {

    const { enqueueSnackbar } = useSnackbar();

    const { activeDetail } = useSelector(state => state.projects);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { id } = useParams();



    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (id) {
            dispatch(startGetProjectDetails(id, { setLoading }));
            dispatch(startGetUsers());
        } else {
            navigateBack();
        }
    }, []);


    const navigateBack = () => {
        navigate("/projects");

    }

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDeleteItem = () => {
        var res = window.confirm("Sure?");
        if (res) {
            dispatch(startDeleteProject(activeDetail, { enqueueSnackbar, navigateBack }));
        }

    }

    const handleUpdateItem = () => {
        dispatch(setActiveProject(activeDetail));
        navigate(`/update-project/${activeDetail.id}`);

    }

    return (
        <div className="container mt-2">

            <div className="page-header">
                <div className="d-flex align-items-center">
                    <Tooltip onClick={navigateBack}>
                        <IconButton>
                            <ArrowBack />
                        </IconButton>
                    </Tooltip>
                    <h2 className="ms-2">Project Details</h2>
                </div>


            </div>
            {
                (activeDetail && !loading) && (
                    <>
                       
                            <div className="projectDetail__container">
                                <div className="projectDetail__header">

                                    <div>
                                        <h1>{activeDetail.name} <span className="blue-grey-text">/ {activeDetail.customer.name}</span></h1>
                                    </div>
                                    <div className='d-flex'>

                                        <Tooltip onClick={handleClickOpen}>

                                            <button className="mb btn-blue">
                                                Add user
                                            </button>
                                        </Tooltip>

                                        {/* <Tooltip title="Edit" onClick={handleUpdateItem} className="ms-2"> */}
                                        <button className="mb btn-blue ms-2" onClick={handleUpdateItem}>
                                            <Edit /> Edit project
                                        </button>
                                        {/* </Tooltip> */}
                                        {/* 
                                        <Tooltip title="Delete" onClick={handleDeleteItem} className="ms-2">
                                            <IconButton>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip> */}
                                    </div>

                                </div>
                                {
                                    activeDetail.userProjects?.length > 0 ?
                                        <ProjectMembersList />
                                        :
                                        (
                                            <Alert severity="info">No user has been added to the project —
                                                <button className="btn-link-info" onClick={handleClickOpen}>
                                                    add one by clicking here
                                                </button>!
                                            </Alert>
                                        )
                                }

                            </div>
                        
                        <AddUserDialog open={open} handleClose={handleClose} project={activeDetail} />
                    </>
                )
            }
            {
                (!activeDetail && !loading) && (
                    <h1>Project not found.</h1>
                )
            }
            {
                loading && (
                    <LoadingDetails />
                )
            }
        </div>
    )
}


const AddUserDialog = ({ open, handleClose, project }) => {

    const roles = [{ value: 0, name: 'Member' }, { value: 1, name: 'Project Manager' }];

    const { users } = useSelector(state => state.users);

    const dispatch = useDispatch();

    const { enqueueSnackbar } = useSnackbar();


    const [selectedUser, setSelectedUser] = useState('');

    const [selectedRole, setSelectedRole] = useState(0);


    const [loading, setLoading] = useState(false);

    const handleChange = (event) => {
        setSelectedUser(event.target.value);
    };



    const handleToggle = (event) => {
        setSelectedRole(event.target.value);
    }

    const handleAddUser = () => {
        const userProject = {
            userToAddID: selectedUser,
            userRoleInProject: Number(selectedRole),
            projectID: project.id
        };

        setLoading(true);
        dispatch(startAddUserProject(userProject, { enqueueSnackbar, setLoading, handleClose }))
    }

    const handleCloseDialog = () => {
        if (!loading) {
            handleClose();
        }
    }


    return (
        <>
            <Dialog open={open} onClose={handleCloseDialog} sx={{ '& .MuiDialog-paper': { maxHeight: 800 } }}>
                <DialogTitle>Add user to {project?.name}</DialogTitle>
                <DialogContent>
                    <div className="d-flex flex-column">
                        <FormLabel id="demo-select-small">
                            User
                        </FormLabel>
                     
                        <select className="mi mt-2" value={selectedUser} name="customerID" onChange={handleChange}>
                            <option value="">None</option>
                            {
                                users?.map(u => (
                                    <option value={u.id} key={u.id}>{u.email}</option>
                                ))
                            }
                        </select>


                    </div>

                    <FormControl className="mt-3 w-100">
                        <FormLabel id="demo-radio-buttons-group-label">Role</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={selectedRole}
                            onChange={handleToggle}
                            name="selectedCustomer"
                        >
                            {roles.map((c) => <FormControlLabel
                                key={c.value}
                                value={c.value} control={<Radio />}
                                label={c.name}
                            />)}
                        </RadioGroup>
                    </FormControl>
                </DialogContent>

                <DialogActions>
                   
                    <button onClick={handleCloseDialog} disabled={loading} className="mb btn-outline-blue">
                    Cancel
                    </button>
                    <button onClick={handleAddUser} disabled={loading} className="ms-2 mb btn-blue">
                        Add
                    </button>


                </DialogActions>
            </Dialog>
        </>
    )
}

const LoadingDetails = () => {
    return (
        <Stack spacing={1} style={{ marginTop: '4px' }}>
            {/* For variant="text", adjust the height via font-size */}
            <Skeleton variant="rounded" height={64} />
            <Skeleton variant="rounded" height={400} style={{ marginTop: '16px' }} />
        </Stack>
    )
}