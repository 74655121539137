
const customersTypes = {
    customersSetCustomers: '[customers] Set customers',
    customersAddCustomer: '[customers] Add customer',
    customersDeleteCustomer: '[customers] Delete Customer',
    customersUpdateCustomer: '[customers] Update Customer',
    customersSetActive: '[customers] Set active',
    customersSetUserCustomers: '[customers] Set user customers',
    customersClearAll: '[customers] Clear All'
}

export default customersTypes;