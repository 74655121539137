import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Checkbox, FormControlLabel, FormGroup, Paper } from '@mui/material';
import { useForm } from '../../hooks/useForm';
import { useEffect } from 'react';
import { useState } from 'react';
import md5 from 'md5';
import { useDispatch } from 'react-redux';
import { startChangePassword } from '../../redux/actions/users';
import { useSnackbar } from 'notistack';
import { Preferences } from './preferences';
import ChangePassword from './change-password';
import AccountData from './account-data';
import { useLocation, useParams } from 'react-router-dom';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (

                { children }

            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const AccountLayout = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const location = useLocation();

    useEffect(() => {
      const hash = location.hash.substring(1);
      if(hash){
        if(hash=='data') {
            setValue(0);
          } else if(hash=='preferences'){
            setValue(2);
          }
      }
    }, [location]);

    return (
        <div>
            <div className="container pt-2">
                <div className="page-header">
                    <h2>Account</h2>
                </div>
            </div>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} className="container" aria-label="basic tabs example">
                    <Tab className="account-tab" label="Personal Data" {...a11yProps(0)} />
                    <Tab className="account-tab" label="Change Password" {...a11yProps(1)} />
                    <Tab className="account-tab" label="Preferences" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <div className="container">

                <CustomTabPanel value={value} index={0}>
                    <AccountData />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <ChangePassword />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <Preferences />
                </CustomTabPanel>
            </div>

        </div>


    );
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div className="p-3">
                    {children}
                    </div>
            )}
        </div>
    );
}


export default AccountLayout;