import { fetchWithToken } from "../../helpers/fetch";
import { displayError } from "../helpers";
import usersTypes from "../types/usersTypes"

const controller = 'users';

export const startGetUsers = () => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/get-all`, null, 'GET');
        const body = await res.json();
        if (body.succeeded) {
            dispatch(setUsers(body.data));

        } else {
            alert(body.message);
        }
    }
}

export const startCreateUser = (user, view) => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/create-user`, user, 'POST');
        const body = await res.json();
        view.setLoading(false);
        if (body.succeeded) {
            dispatch(addUsers(body.data));
            view.enqueueSnackbar('The user has been created', { variant: 'success', autoHideDuration: 3000 });
            view.setSuccess(true);
            view.reset();
        } else {
            displayError(body, view.enqueueSnackbar);
        }
    }
}

export const startChangePassword = (currentPassword, newPassword, view) => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/change-password`, {currentPassword, newPassword}, 'POST');
        const body = await res.json();
        view.setLoading(false);

        if(body.succeeded) {
            view.enqueueSnackbar('Password changed!', { variant: 'success', autoHideDuration: 3000 });
            view.reset();

        } else {
            displayError(body, view.enqueueSnackbar);
        }
    }
}

export const startGetUser = (id, view) => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/get-user/${id}`, null, 'GET');
        const body = await res.json();

        view.setLoadingUser(false);
        if (body.succeeded) {
            dispatch(setActiveUser(body.data));

        } else {
            alert(body.message);
        }
    }
}


export const startChangeUserRoles = (user, roles, view) => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/change-user-roles`, {userID: user.id, roles: roles.join(',')}, 'POST');
        const body = await res.json();
        view.setLoading(false);
        if (body.succeeded) {
            //TODO updateuser
            user.roles = roles;
            dispatch(updateUser(user));
            view.enqueueSnackbar('Changes saved', { variant: 'success', autoHideDuration: 3000 });
            view.handleCloseDialog();
        } else {
            displayError(body, view.enqueueSnackbar);
        }
    }
}


const setUsers = (users) => ({
    type: usersTypes.setUsersList,
    payload: users
});

const addUsers = (user) => ({
    type: usersTypes.addUser,
    payload: user
});

const updateUser = (user) => ({
    type: usersTypes.updateUser,
    payload: user
})

export const usersClearAll = () => ({
    type: usersTypes.usersClearAll
})

export const setActiveUser = (user) => ({
    type: usersTypes.setActiveUser,
    payload: user
})

export const removeProjectFromActiveUser = (projectID) => ({
    type: usersTypes.removeProjectFromActiveUser,
    payload: projectID
})

export const changeUserRoleInProjectFromActiveUser = (projectID, role) => ({
    type: usersTypes.changeUserRoleInProjectFromActiveUser,
    payload: {projectID, role}
})