import entryTypes from './../types/entryTypes';

const initialState = {
    baseEntries: [],
    entries: [],

    currentDateFrom: '',
    currentDateTo: '',

    filterByProjectsIDs: [],
    filterByCustomersIDs: [],
    filterByActivitiesIDs: [],

    active: null

}

const entryReducer = (state = initialState, action) => {
    switch (action.type) {
        case entryTypes.entrySetEntries:
            return {
                ...state,
                entries: [...action.payload],
                baseEntries: [...action.payload]
            }

        case entryTypes.entrySetFilteredEntries:
            return  {
                ...state,
                entries: [...action.payload]
            }

        case entryTypes.entryDelete:
            return {
                ...state,
                entries: state.entries.filter(x => x.id != action.payload),
                baseEntries: state.entries.filter(x => x.id != action.payload)
            }

        case entryTypes.entrySetActive:
            return {
                ...state,
                active: action.payload
            }

        case entryTypes.entrySetDateFrom:
            return {
                ...state,
                currentDateFrom: action.payload
            }

        case entryTypes.entrySetDateTo:
            return {
                ...state,
                currentDateTo: action.payload
            }

        case entryTypes.entryFilterByProjects:
            return {
                ...state,
                filterByProjectsIDs: action.payload
            }
        case entryTypes.entryFilterByCustomers:
            return {
                ...state,
                filterByCustomersIDs: action.payload
            }
        case entryTypes.entryFilterByActivities:
            return {
                ...state,
                filterByActivitiesIDs: action.payload
            }
        case entryTypes.entryClearAll: 
            return initialState;
        default:
            return state;
    }
}

export default entryReducer;