

export const useHoursHelper = (initialState = {}) => {

    const convertHoursToDecimal = (hoursString) => {
        const parts = hoursString.split(':');
        const hours = parseInt(parts[0]);
        const minutes = parseInt(parts[1]);
      
        const totalHours = hours + minutes / 60;
        return totalHours;
    }

    return {convertHoursToDecimal};

}

