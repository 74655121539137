import { DateRangeOutlined as DateRangeIcon, KeyboardArrowRight } from '@mui/icons-material'
import { Button, ButtonBase, Paper,Popover, TextField } from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import moment from 'moment'
import React, { useEffect, useState } from 'react'


const MONTH = 'month';
const LAST_MONTH = 'lsat_month';
const WEEK = 'week';

export const DateRange = ({
    initialDateFrom,
    initialDateTo,
    onChangeFrom,
    onChangeTo
}) => {

    const [dateFrom, setDateFrom] = useState();
    const [dateTo, setDateTo] = useState();
    const [rangeSelected, setRangeSelected] = useState('');

    useEffect(() => {
        var currentDate = new Date();

        let dateFrom = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        let dateTo = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        setDateFrom(moment(dateFrom));
        onChangeFrom(moment(dateFrom).format('DD-MM-yyyy'));
        setDateTo(moment(dateTo));
        onChangeTo(moment(dateTo).format('DD-MM-yyyy'));
    }, []);

    const handleOnChangeFrom = (df) => {
        setDateFrom(moment(new Date(df)));
        onChangeFrom(moment(new Date(df)).format('DD-MM-yyyy'));
    }

    const handleOnChangeTo = (dt) => {
        setDateTo(moment(new Date(dt)));
        onChangeTo(moment(new Date(dt)).format('DD-MM-yyyy'));
    }

    const getDaysOfMonth = () => {
        var currentDate = new Date();
        var firstDayOfMonth = moment(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
        var lastDayOfMonth = moment(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0));

        return { firstDayOfMonth: firstDayOfMonth.format('DD/MM/YYYY'), lastDayOfMonth: lastDayOfMonth.format('DD/MM/YYYY') };
    }

    const getDaysOfWeek = () => {
        var currentDate = new Date();

        let firstDayOfWeek = new Date(currentDate);
        firstDayOfWeek.setDate(currentDate.getDate() - currentDate.getDay() + (currentDate.getDay() === 0 ? -6 : 1));

        let lastDayOfWeek = new Date(currentDate);
        lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

        return { firstDayOfWeek: moment(firstDayOfWeek).format('DD/MM/YYYY'), lastDayOfWeek: moment(lastDayOfWeek).format('DD/MM/YYYY') };
    }

    const getDaysOfLastMonth = () => {

        var currentDate = new Date();
        var firstDayOfLastMonth = moment(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
        var lastDayOfLastMonth = moment(new Date(currentDate.getFullYear(), currentDate.getMonth(), 0));

        return { firstDayOfLastMonth: firstDayOfLastMonth.format('DD/MM/YYYY'), lastDayOfLastMonth: lastDayOfLastMonth.format('DD/MM/YYYY') };

    }



    const setRange = (value) => {
        let newDateFrom;
        let newDateTo;

        var currentDate = new Date();

        if (value == MONTH) {
            newDateFrom = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            newDateTo = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        } else if (value == WEEK) {
            newDateFrom = new Date(currentDate);
            newDateFrom.setDate(currentDate.getDate() - currentDate.getDay() + (currentDate.getDay() === 0 ? -6 : 1));

            newDateTo = new Date(currentDate);
            newDateTo.setDate(newDateFrom.getDate() + 6);
        } else if (value == LAST_MONTH) {
            newDateFrom = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
            newDateTo = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
        }

        setDateFrom(moment(newDateFrom));
        setDateTo(moment(newDateTo));

        setRangeSelected(value);
     
        onChangeFrom(moment(newDateFrom).format('DD-MM-YYYY'));
        onChangeTo(moment(newDateTo).format('DD-MM-YYYY'));
    }

    useEffect(() => {

        if (dateFrom && dateTo) {
          
            var dateFromMoment = dateFrom.format('DD/MM/YYYY');
            var dateToMoment = dateTo.format('DD/MM/YYYY');

            const { firstDayOfMonth, lastDayOfMonth } = getDaysOfMonth();

            if (dateFromMoment == firstDayOfMonth && dateToMoment == lastDayOfMonth) {
                setRangeSelected(MONTH);
                return;
            }

            const { firstDayOfWeek, lastDayOfWeek } = getDaysOfWeek();
            if (dateFromMoment == firstDayOfWeek && dateToMoment == lastDayOfWeek) {
                setRangeSelected(WEEK);
                return;
            }

       
            const { firstDayOfLastMonth, lastDayOfLastMonth } = getDaysOfLastMonth();
            if (dateFromMoment == firstDayOfLastMonth && dateToMoment == lastDayOfLastMonth) {
                setRangeSelected(LAST_MONTH);
                return;
            }

            setRangeSelected('');
        }

    }, [dateFrom, dateTo])

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>

            <div>

                <div className="fake-mi date-range ">
                    <button className="date-range-display d-flex align-items-center justify-content-center" aria-describedby={id} onClick={handleClick}>
                        <DateRangeIcon/>
                        <span className='ms-16px'>
                        {dateFrom?.format('DD/MM/YYYY')} <span className="mx-2">{'-'}</span> {dateTo?.format('DD/MM/YYYY')}

                        </span>
                    </button>
                </div>


                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div className="range-options-container">
                        <ButtonBase type="submit" onClick={(e) => setRange(LAST_MONTH)} className={`btn-set-time entries__btn-set-time ${rangeSelected == LAST_MONTH ? 'selected' : ''}`} variant="contained">
                            last month
                        </ButtonBase>
                        <ButtonBase type="submit" onClick={(e) => setRange(MONTH)} className={`btn-set-time entries__btn-set-time ${rangeSelected == MONTH ? 'selected' : ''}`} variant="contained">
                            this month
                        </ButtonBase>
                        <ButtonBase type="submit" onClick={(e) => setRange(WEEK)} className={`btn-set-time entries__btn-set-time  ${rangeSelected == WEEK ? 'selected' : ''}`} variant="contained">
                            this week
                        </ButtonBase>
                    </div>
                    <div className="d-flex align-items-center dates-div p-3">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Paper className="paper-range-input-date" >
                                <DesktopDatePicker
                                    className="range-input-date"
                                    inputFormat="DD/MM/YYYY"
                                    name="date"
                                    value={dateFrom === '' ? '' : dateFrom?.format("YYYY-MM-DD")}
                                    onChange={handleOnChangeFrom}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </Paper>
                        </LocalizationProvider>
                        <span className="mx-2">
                            <KeyboardArrowRight fontSize="small" />
                        </span>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Paper className="paper-range-input-date">
                                <DesktopDatePicker
                                    className="range-input-date"
                                    inputFormat="DD/MM/YYYY"
                                    name="date"
                                    value={dateTo === '' ? '' : dateTo?.format("YYYY-MM-DD")}
                                    onChange={handleOnChangeTo}
                                    renderInput={(params) => <TextField style={{ fontWeight: 500 }} {...params} />}
                                />
                            </Paper>
                        </LocalizationProvider>
                    </div>
                </Popover>
            </div>

            
        </>
    )
}

export default DateRange;