import { combineReducers } from "redux";
import activitiesReducer from "./activitiesReducer";
import authReducer from "./authReducer";
import customersReducer from "./customersReducer";
import entryReducer from "./entryReducer";
import preferencesReducer from "./preferencesReducer";
import projectsReducer from "./projectsReducers";
import reportsReducer from "./reportsReducer";
import usersReducer from "./usersReducer";
import hourlyAnalisysReducer from "./hourlyAnalysisReducer";
import entryRecordReducer from "./entryRecord";
import invitationsReducer from "./invitationsReducer";
import dashboardReducer from "./dashboardReducer";

const rootReducer = combineReducers({
    auth: authReducer,
    entry: entryReducer,
    projects: projectsReducer,
    activities: activitiesReducer,
    customers: customersReducer,
    preferences: preferencesReducer,
    reports: reportsReducer,
    users: usersReducer,
    hourlyAnalysis: hourlyAnalisysReducer,
    entryRecord: entryRecordReducer,
    invitations: invitationsReducer,
    dashboard: dashboardReducer
});

export default rootReducer;