



const activitiesTypes = {
    activitiesSetActivities: '[activities] Set activities',
    activitiesAddActivity: '[activities] Add activity',
    activitiesDeleteActivity: '[activities] Delete activity',
    activitiesUpdateActivity: '[activities] Update activity',
    activitiesSetActive: '[activities] Set active',
    activitiesClearAll: '[activities] Clear all'
}

export default activitiesTypes;