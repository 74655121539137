import moment from "moment";


export const calculateHoursDiff = (timeStart, timeFinish) => {
    if (timeStart <= timeFinish) {
        var from = new Date("01/01/2007 " + timeStart);
        var to = new Date("01/01/2007 " + timeFinish);

        var hoursDiff = to.getHours() - from.getHours();
        var minsDiff = to.getMinutes() - from.getMinutes();

        if (minsDiff < 0) {
            hoursDiff = hoursDiff - 1;
            minsDiff = 60 - (minsDiff * -1);
        }
        const timeDiff = `${(hoursDiff < 10 ? `0${hoursDiff}` : hoursDiff)}:${minsDiff < 10 ? `0${minsDiff}` : minsDiff}`;
        return timeDiff;
    } else {
        return '00:00';
    }
}

export const sortEntries = (entries) => {
    entries.sort(function (a, b) {
        if (moment(a.date).format('DD/MM/YYYY') === moment(b.date).format('DD/MM/YYYY')) {
            let result = a.timeFinish < b.timeFinish;
            return result ? 1 : -1;
        }
        return new Date(b.date) - new Date(a.date);
    });
    return entries;
}

export const addAndSortEntries = (entries, newEntry) => {
}


export const sortByHours = (entityEntries) => {

    entityEntries.sort((a, b) => {
        if (a.hours === b.hours) {
            return 0;
        }

        // nulls sort after anything else
        if (a.hours === null) {
            return 1;
        }
        if (b.hours === null) {
            return -1;
        }

        // otherwise, if we're ascending, lowest sorts first
        // if (ascending) {
        //     return a.hours < b.hours ? -1 : 1;
        // }

        // if descending, highest sorts first
        return a.hours < b.hours ? 1 : -1;
    });
}

export const validTime = (value) => {
    const regexp = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/;

    if (regexp.test(value)) {
        return true;
    }
    else {
        return false;
    }
}

export const formatTime = (value) => {

    let values = value.split(':');
    let hours = values[0];
    let minutes = values[1];

    if (hours.length == 1) {
        hours = `0${hours}`
    }

    if (minutes.length == 1) {
        minutes = `0${minutes}`;
    }

    return `${hours}:${minutes}`;
}

export const dateIsToday = (d1) => {
    return moment(d1).format('DD/MM/YYYY') == moment().format('DD/MM/YYYY')
}

export const sameDates = (d1, d2) => {
    if (!d2 || !d1) {
        return false;
    }
    return moment(d1).format('DD/MM/YYYY') == moment(d2).format('DD/MM/YYYY')
}

export const sumEntriesHours = (entries) => {

    let totalTime = '00:00';
    entries?.map(e => {
        let totalMinutes = moment.duration(totalTime).asMinutes() + moment.duration(e.totalTime).asMinutes();
        let totalHours = Math.trunc(totalMinutes / 60);
        let otherMinutes = totalMinutes % 60;
        const timeDiff = `${(totalHours < 10 ? `0${totalHours}` : totalHours)}:${otherMinutes < 10 ? `0${otherMinutes}` : otherMinutes}`;
        totalTime = timeDiff;
    });

    return totalTime;
}

export const getProjectRole = (role) => {
    switch (role) {
        case 0:
            return 'Member';

        case 1:
            return 'Project Manager';

        default:
            return '';
    }
}

export const openSnackbar = (enqueueSnackbar, msg, type) => {
    enqueueSnackbar(msg, {
        variant: type, autoHideDuration: 3000, anchorOrigin: {horizontal:'left', vertical:'bottom'}
    });
}
