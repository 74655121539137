

import { InputBase, TextField } from '@mui/material';
import { Info } from '@mui/icons-material';

import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../hooks/useForm'
import { startLogin } from '../../redux/actions/auth';

// import logo from './../../assets/logo-black.svg';
import bgimage from './../../assets/1.webp';
import logo from './../../assets/logos/tt-logo-black.svg';
// import bgimage from './../../assets/logo-black.png';
// import bgimage from './../../assets/test-bg-1.jpg';
// import logo from "./../../assets/tt-logo-white.png";

import { withStyles } from '@mui/material/styles'
import { useSnackbar } from 'notistack';
import md5 from 'md5';
import { Link } from 'react-router-dom';

export const Login = () => {

  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const [formValues, handleInputChange, reset, setLoginUser] = useForm({
    email: "",
    password: ""
  });

  const { email, password } = formValues;


  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const passwordHash = md5(password);
    dispatch(startLogin(email, passwordHash, { enqueueSnackbar, setLoading }))
  }

  const setUser = (email, password) => {
    setLoading(true);
    dispatch(startLogin(email, password, { enqueueSnackbar, setLoading }))
  }

  return (
    <>

      <div className="auth-container">

        <div>
          <div className="auth-header d-flex flex-column">
            {/* <img src={logo} className="tt-logo" /> */}
            <span className="login-title">
              <span className="login-title-time-text">
                TIME
              </span>
              <span className="login-title-tracker-text">
                TRACKER
              </span>
            </span>
          </div>
          <div className="d-flex justify-content-center">
            <h1 className="auth-title">LOGIN</h1>
          </div>
          <form className="auth-form" onSubmit={handleSubmit} >
            <div>
              <input type="text" placeholder="Email" className="mi mi-large mb-4 w-100" name="email" defaultValue={email || null} onChange={handleInputChange} />
              <input type="password" placeholder="Password" className="mi mi-large mb-4 w-100" name="password" defaultValue={password || null} onChange={handleInputChange} />
            </div>

            <div className="d-flex flex-column">

              <Link to="/auth/recover-password" className="auth-link">Forgot Your Password?</Link>

              <button type="submit" className={`mt-4 mb btn-darkk btn-login-large w-100 position-relative ${loading ? ' spinner login-spinner' : ''}`} disabled={loading}>

                {
                  !loading && <span>Log In </span>
                }
              </button>


              <div className="version-container">
                <span className="version">v0.1</span>
              </div>
              <div className="mt-4 testing-box d-flex flex-column secret-div">
                <div>
                  <Info className='text-primary' /> Testing - Click to login as the role
                </div>
                <a onClick={() => { setUser('santivespa@outlook.com', '123456') }}>admin</a>
                <a onClick={() => { setUser('manager@santivespa.com', '123456') }}>manager</a>
                <a onClick={() => { setUser('henry.scott@santivespa.com', '123456') }}>collaborator</a>
              </div>

            </div>

          </form>
        </div>

        {/*         
        <div className="col-7 position-relative" style={{
          backgroundImage: `url(${bgimage})`,
          backgroundSize: 'cover',
          overflow: 'hidden',
          backgroundPositionX: 'center'

        }}>
          <div className='position-absolute' style={{
            top: 0, 
            bottom: 0, 
            right: 0,
            left: 0,
            background: 'black',
            opacity: 0.5}}>

          </div>
        </div> */}

      </div>
    </>
  )
}
