import { fetchWithToken } from '../../helpers/fetch';
import customersTypes from '../types/customersTypes';

const controller = 'customer';

export const startGetCustomers = () => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/get-all`, {}, 'GET');
        const body = await res.json();

        if (body.succeeded) {
            dispatch(setCustomers(body.data));
        } else {
            alert(body.message);
        }
    }
}

export const startSetActiveCustomer = (id, view) => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/get/${id}`, {}, 'GET');
        const body = await res.json();
        view.setLoading(false);
        if (body.succeeded) {
            dispatch(setActiveCustomer(body.data));
        } else {
            alert(body.message);
        }
    }
}

export const startCreateCustomer = (customer, view) => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/create`, customer, 'POST');
        const body = await res.json();
        view.setLoading(false);

        if (body.succeeded) {
            customer.id = body.message;
            dispatch(addCustomer(customer));
            view.enqueueSnackbar('The customer has been created', { variant: 'success', autoHideDuration: 3000 });
            view.resetForm();
        } else {
            alert(body.message);
        }
    }
}

export const startUpdateCustomer = (customer, view) => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/update/${customer.id}`, customer, 'POST');
        const body = await res.json();
        view.setLoading(false);

        if (body.succeeded) {
            dispatch(updateCustomer(customer));
            view.enqueueSnackbar('The customer has been updated', { variant: 'success', autoHideDuration: 3000 });
        } else {
            alert(body.message);
        }
    }
}

export const startDeleteCustomer = (customer, view) => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/delete/${customer.id}`, {}, 'GET');
        const body = await res.json();

        if (body.succeeded) {
            dispatch(deleteCustomer(customer));
            view.enqueueSnackbar('The customer has been deleted', { variant: 'success', autoHideDuration: 3000 });
            view.setSelectedItem(null);
        } else {
            alert(body.message);
        }
    }
}

export const startGetUserCustomers = () => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/get-by-user`, {}, 'GET');
        const body = await res.json();

        if (body.succeeded) {
            dispatch(setUserCustomers(body.data));

        } else {
            alert(body.message);
        }
    }
}

const setCustomers = (customers) => ({
    type: customersTypes.customersSetCustomers,
    payload: customers
})

const addCustomer = (customer) => ({
    type: customersTypes.customersAddCustomer,
    payload: customer
})

const updateCustomer = (customer) => ({
    type: customersTypes.customersUpdateCustomer,
    payload: customer
})


const deleteCustomer = (customer) => ({
    type: customersTypes.customersDeleteCustomer,
    payload: customer.id
})

export const setActiveCustomer = (customer) => ({
    type: customersTypes.customersSetActive,
    payload: customer
})

const setUserCustomers = (customers) => ({
    type: customersTypes.customersSetUserCustomers,
    payload: customers
})


export const customersClearAll = () => ({
    type: customersTypes.customersClearAll
})