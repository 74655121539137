import { useDispatch, useSelector } from "react-redux";
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { startDownloadData, startSaveDateFrom, startSaveDateTo } from "../../redux/actions/entry";
import { Button, ButtonBase, IconButton, Paper, Popover, TextField, Tooltip, Typography } from "@mui/material";
import { sumEntriesHours } from "../../helpers/helpers";
import { useEffect, useState } from "react";
import { DateRange, Delete, FileDownload, Filter, FilterList, KeyboardArrowRight, PictureAsPdf, PlayArrow } from "@mui/icons-material";
import moment from "moment";
import { RecordEntry } from "../recordEntry/RecordEntry";
import { EntriesCustomers } from "./filtersByItems/EntriesCustomers";
import { EntriesProjects } from "./filtersByItems/EntriesProjects";
import { EntriesActivities } from "./filtersByItems/EntriesActivities";


const MONTH = 'month';
const LAST_MONTH = 'lsat_month';
const WEEK = 'week';

const EntriesHeader = () => {

    const dispatch = useDispatch();

    const { currentDateFrom, currentDateTo } = useSelector(state => state.entry);
    const { entries } = useSelector(state => state.entry);

    const handleOnChangeFrom = (dateFrom) => {
        dispatch(startSaveDateFrom(dateFrom));
    }

    const handleOnChangeTo = (dateTo) => {
        dispatch(startSaveDateTo(dateTo));
    }

    const [rangeSelected, setRangeSelected] = useState('');

    useEffect(() => {
        var currentDate = new Date();

        if (!currentDateFrom) {
            let dateFrom = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            dispatch(startSaveDateFrom(moment(dateFrom)));
        }
        if (!currentDateTo) {
            let dateTo = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
            dispatch(startSaveDateTo(moment(dateTo)));
        }

        if (currentDateFrom && currentDateTo) {


            var dateFromMoment = currentDateFrom?.format('DD/MM/YYYY');
            var dateToMoment = currentDateTo?.format('DD/MM/YYYY');

            const { firstDayOfMonth, lastDayOfMonth } = getDaysOfMonth();
            if (dateFromMoment == firstDayOfMonth && dateToMoment == lastDayOfMonth) {
                setRangeSelected(MONTH);
                return;
            }

            const { firstDayOfWeek, lastDayOfWeek } = getDaysOfWeek();
            if (dateFromMoment == firstDayOfWeek && dateToMoment == lastDayOfWeek) {
                setRangeSelected(WEEK);
                return;
            }

            const { firstDayOfLastMonth, lastDayOfLastMonth } = getDaysOfLastMonth();
            if (dateFromMoment == firstDayOfLastMonth && dateToMoment == lastDayOfLastMonth) {
                setRangeSelected(LAST_MONTH);
                return;
            }

            setRangeSelected('');
        }

    }, [currentDateFrom, currentDateTo])


    const getDaysOfMonth = () => {
        var currentDate = new Date();
        var firstDayOfMonth = moment(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
        var lastDayOfMonth = moment(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0));

        return { firstDayOfMonth: firstDayOfMonth.format('DD/MM/YYYY'), lastDayOfMonth: lastDayOfMonth.format('DD/MM/YYYY') };
    }

    const getDaysOfWeek = () => {
        var currentDate = new Date();

        let firstDayOfWeek = new Date(currentDate);
        firstDayOfWeek.setDate(currentDate.getDate() - currentDate.getDay() + (currentDate.getDay() === 0 ? -6 : 1));

        let lastDayOfWeek = new Date(currentDate);
        lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

        return { firstDayOfWeek: moment(firstDayOfWeek).format('DD/MM/YYYY'), lastDayOfWeek: moment(lastDayOfWeek).format('DD/MM/YYYY') };
    }

    const getDaysOfLastMonth = () => {

        var currentDate = new Date();
        var firstDayOfLastMonth = moment(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
        var lastDayOfLastMonth = moment(new Date(currentDate.getFullYear(), currentDate.getMonth(), 0));

        return { firstDayOfLastMonth: firstDayOfLastMonth.format('DD/MM/YYYY'), lastDayOfLastMonth: lastDayOfLastMonth.format('DD/MM/YYYY') };

    }

    const setRange = (value) => {
        let dateFrom;
        let dateTo;

        var currentDate = new Date();

        if (value == MONTH) {
            dateFrom = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            dateTo = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        } else if (value == WEEK) {
            dateFrom = new Date(currentDate);
            dateFrom.setDate(currentDate.getDate() - currentDate.getDay() + (currentDate.getDay() === 0 ? -6 : 1));

            dateTo = new Date(currentDate);
            dateTo.setDate(dateFrom.getDate() + 6);
        } else if (value == LAST_MONTH) {
            dateFrom = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
            dateTo = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
        }

        dispatch(startSaveDateFrom(moment(dateFrom)));
        dispatch(startSaveDateTo(moment(dateTo)));

        setRangeSelected(value);

    }

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    const [loadingDownload, setLoadingDownload] = useState(false);

    const { filterByCustomersIDs, filterByProjectsIDs, filterByActivitiesIDs } = useSelector(state => state.entry);


    const downloadData = async () => {

        const dateFrom = moment(currentDateFrom).format('DD-MM-YYYY');
        const dateTo = moment(currentDateTo).format('DD-MM-YYYY');

        setLoadingDownload(true);
        const data = await startDownloadData(
            dateFrom, 
            dateTo,
            filterByCustomersIDs.length !=0 ? filterByCustomersIDs?.join(',') : 0,
            filterByProjectsIDs.length !=0? filterByProjectsIDs?.join(',') : 0,
            filterByActivitiesIDs.length !=0? filterByActivitiesIDs?.join(',') : 0,
            { setLoading: setLoadingDownload });

        if (data) {
            const byteArray = new Uint8Array(atob(data).split('').map(char => char.charCodeAt(0)));
            const blob = new Blob([byteArray], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'file.pdf';
            document.body.appendChild(link);
            link.click();
        }
    }





    return (
        <>
            <div className="entries-header">
                <div className="d-flex justify-content-between align-items-center mb-24px">
                    <div className="entriesHeader__button-group d-flex">

                        <Tooltip title="Filter">
                            <IconButton className="btn-icon-blue entriesHeader__icon-btn" color="primary" data-bs-toggle="collapse" data-bs-target="#filter">
                                <FilterList />
                            </IconButton>

                        </Tooltip>

                        <RecordEntry />


                    </div>

                    <div className="d-flex justify-content-center align-items-center">


                        <Tooltip title="Download Table Data">
                            <span>

                                <IconButton disabled={loadingDownload || entries?.length == 0} className="btn-icon-blue entriesHeader__icon-btn" color="primary" onClick={downloadData}>
                                    <FileDownload />
                                </IconButton>
                            </span>
                        </Tooltip>

                        {
                            (currentDateFrom && currentDateTo) ?
                                <div className="fake-mi">
                                    <button className="date-range-display d-flex align-items-center" aria-describedby={id} onClick={handleClick}>
                                        <DateRange/>
                                        <span className="ms-16px">
                                        {currentDateFrom?.format('DD/MM/YYYY')} <span className="mx-2">{'-'}</span> {currentDateTo?.format('DD/MM/YYYY')}

                                        </span>
                                    </button>
                                </div>
                                : <></>
                        }

                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <div className="range-options-container">
                                <ButtonBase type="submit" onClick={(e) => setRange(LAST_MONTH)} className={`btn-set-time entries__btn-set-time ${rangeSelected == LAST_MONTH ? 'selected' : ''}`} variant="contained">
                                    last month
                                </ButtonBase>
                                <ButtonBase type="submit" onClick={(e) => setRange(MONTH)} className={`btn-set-time entries__btn-set-time ${rangeSelected == MONTH ? 'selected' : ''}`} variant="contained">
                                    this month
                                </ButtonBase>
                                <ButtonBase type="submit" onClick={(e) => setRange(WEEK)} className={`btn-set-time entries__btn-set-time  ${rangeSelected == WEEK ? 'selected' : ''}`} variant="contained">
                                    this week
                                </ButtonBase>
                            </div>
                            <div className="d-flex align-items-center dates-div p-3">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Paper className="paper-range-input-date" >
                                        <DesktopDatePicker
                                            className="range-input-date"
                                            inputFormat="DD/MM/YYYY"
                                            name="date"
                                            value={currentDateFrom === '' ? '' : currentDateFrom?.format("YYYY-MM-DD")}
                                            onChange={handleOnChangeFrom}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Paper>
                                </LocalizationProvider>
                                <span className="mx-2">
                                    <KeyboardArrowRight fontSize="small" />
                                </span>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Paper className="paper-range-input-date">
                                        <DesktopDatePicker
                                            className="range-input-date"
                                            inputFormat="DD/MM/YYYY"
                                            name="date"
                                            value={currentDateTo === '' ? '' : currentDateTo?.format("YYYY-MM-DD")}
                                            onChange={handleOnChangeTo}
                                            renderInput={(params) => <TextField style={{ fontWeight: 500 }} {...params} />}
                                        />
                                    </Paper>
                                </LocalizationProvider>
                            </div>
                        </Popover>
                    </div>



                </div>
                {/* <div>
                    <IconButton className="ms-2 btn-icon-blue" color="primary" data-bs-toggle="collapse" data-bs-target="#filter">
                        <FilterList />
                    </IconButton>
                </div> */}


                <div className="collapse" id="filter">
                    <div className="row mb-24px">
                        <div className="col-12 col-md-4">
                            <EntriesCustomers/>
                        </div>
                        <div className="col-12 col-md-4">
                            <EntriesProjects/>
                        </div>
                        <div className="col-12 col-md-4">
                            <EntriesActivities/>
                        </div>
                    </div>
                </div>


            </div>


        </>
    )
}

const TotalHours = () => {

    const { entries } = useSelector(state => state.entry);
    const [totalHours, setTotalHours] = useState('');

    useEffect(() => {
        const result = sumEntriesHours(entries);
        setTotalHours(result);
    }, [entries]);


    return <Tooltip title="Total hours between dates (hh:mm)">
        <h2 className="entries-total-hours">{totalHours}</h2>
    </Tooltip>
}

export default EntriesHeader;