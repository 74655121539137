
import { Search } from '@mui/icons-material'
import React from 'react'

export const InputSeatch = ({onChange, placeholder}) => {
  return (
    <div className="input-search">
        <Search sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
        <input onChange={onChange} placeholder={placeholder}/>
    </div>
  )
}
