import { Paper } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { calculateHoursDiff, sortByHours } from "../../../helpers/helpers";
import { filterByProjects } from "../../../redux/actions/entry";
import Duration from "../../formControls/Duration";


export const EntriesProjects = () => {

    const dispatch = useDispatch();

    const { userProjects } = useSelector(state => state.projects);
    const { baseEntries: entries, filterByProjectsIDs } = useSelector(state => state.entry);

    const [projectEntries, setProjectEntries] = useState([]);

    useEffect(() => {
        loadProjectEntriesTimes();
    }, [entries, userProjects]);


    const loadProjectEntriesTimes = () => {

        setProjectEntries([]);

        let pe = [];
        userProjects?.map(p => {

            let projectEntries = entries?.filter(x => x.projectID === p.id);
            let totalProjectHours = '00:00';

            projectEntries?.map(e => {
                let totalMinutes = moment.duration(totalProjectHours).asMinutes() +
                    moment.duration(e.totalTime).asMinutes();

                let totalHours = Math.trunc(totalMinutes / 60);

                let otherMinutes = totalMinutes % 60;

                const timeDiff = `${(totalHours < 10 ? `0${totalHours}` : totalHours)}:${otherMinutes < 10 ? `0${otherMinutes}` : otherMinutes}`;

                totalProjectHours = timeDiff;
            })


            pe.push({
                project: p,
                hours: totalProjectHours === '00:00' ? null : totalProjectHours
            });

        });
        sortByHours(pe);
        setProjectEntries(pe);
    }

    const handleProjectSelected = (e, project) => {
        if (e.target.checked) {
            dispatch(filterByProjects([...filterByProjectsIDs, project.id]));
        } else {
            dispatch(filterByProjects(filterByProjectsIDs.filter(x => x !== project.id)));
        }
    }

    return (
        <>
            <div className="border-around border-radius-4px">
                <div className="footer-header">
                    <h2 className="footer-title">PROJECTS</h2>
                </div>
                <ul className="list-group list-group-flush footer-list entries-scrollbar">
                    {
                        projectEntries?.map(pe => {
                            return (
                                <li className="list-group-item d-flex justify-content-between align-items-center footer-list-item" key={pe.project.id}>
                                    <span className={pe.hours == null ? 'text-grey' : ''}>
                                        <input
                                            disabled={pe.hours == null}
                                            className="form-check-input me-1 c-checkbox-small c-checkbox"
                                            type="checkbox"
                                            onChange={(e) => handleProjectSelected(e, pe.project)}

                                            value={pe.project.id}
                                            id={pe.project.id}
                                        />
                                        <label className={'form-check-label' + (pe.hours == null ? 'text-grey' : '')} htmlFor={pe.project.id}>{pe.project.name} ({pe.project.customer.name})</label>
                                    </span>
                                    {/* <span className="badge bg-primary">{pe.hours}</span> */}
                                    <span className="text-success fw-600">
                                        <Duration value={pe.hours} />
                                    </span>

                                </li>
                            )
                        })
                    }
                </ul>
            </div>

        </>
    )
}



