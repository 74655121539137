import { AccountCircle, Comment, Search } from '@mui/icons-material';
import { Box, Button, Checkbox, FormControl, FormControlLabel, IconButton, Input, InputAdornment, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Radio, RadioGroup, Select, TextField, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { setResultsByUserFile, startGetReportByCustomer, startGetReportByUser, startGetReportUsers } from '../../../redux/actions/reports';
import ButtonGenerateReport from '../../formControls/ButtonGenerateReport';
import { InputSeatch } from '../../formControls/InputSeatch';
import { ReportByUserResult } from './ReportByUserResult';
import { startDownloadReportByUser } from '../../../redux/actions/reports';
import { saveAs } from 'file-saver';
import DateRange from '../../formControls/DateRange';
import CustomCheckBox from '../../formControls/CustomCheckBox';
import { openSnackbar } from '../../../helpers/helpers';

export const ReportByUser = () => {

    const { reportUsers: users, reportByUserFile } = useSelector(state => state.reports);

    const [usersToDisplay, setUsersToDisplay] = useState([]);

    const dispatch = useDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const [selectedUser, setSelectedUser] = useState('');

    const [groupByProjects, setGroupByProjects] = useState(false);
    const [groupByCustomers, setGroupByCustomers] = useState(false);

    const [allUsers, setAllUsers] = useState(false);

    const [detailed, setDetailed] = useState(true);
    const [showActivity, setShowActivity] = useState(true)
    const [showDescription, setShowDescription] = useState(true)
    const [showDate, setShowDate] = useState(true);

    const [loading, setLoading] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);

    const [dateFrom, setDateFrom] = useState();
    const [dateTo, setDateTo] = useState();


    const handleOnChangeFrom = (df) => {
        setDateFrom(df);
    }

    const handleOnChangeTo = (dt) => {
        setDateTo(dt);
    }

    useEffect(() => {
        dispatch(startGetReportUsers());
    }, []);

    useEffect(() => {
        setUsersToDisplay(users);
    }, [users]);

    const handleGetPreview = () => {
        if (selectedUser == '' && !allUsers) {
            openSnackbar(enqueueSnackbar, 'Select User', 'error');

            return;
        }
        setLoading(true);

        dispatch(startGetReportByUser(dateFrom,
            dateTo, selectedUser == '' ? 0 : selectedUser, groupByProjects, groupByCustomers, detailed, showActivity, showDescription, showDate, allUsers, { enqueueSnackbar, setLoading }));
    }

    const filterUsers = (e) => {
        const value = e.target.value;
        if (value) {
            const results = users.filter(x => x.email.toLowerCase().includes(value.toLowerCase()));
            setUsersToDisplay(results);
        } else {
            setUsersToDisplay(users);
        }
    }

    const allUsersClicked = (e) => {
        setAllUsers(!allUsers);
    }

    const generateFile = (type) => {
        // console.log(type);
        setLoadingDownload(true);
      
        dispatch(startDownloadReportByUser(dateFrom,
            dateTo, selectedUser, groupByProjects, groupByCustomers, detailed, showActivity, showDescription, showDate, allUsers, { enqueueSnackbar, setLoading: setLoadingDownload }));
    }

    useEffect(() => {
        // console.log(reportByUserFile);
        // console.log(typeof(reportByUserFile))
        if (reportByUserFile) {

            const byteArray = new Uint8Array(atob(reportByUserFile).split('').map(char => char.charCodeAt(0)));
            const blob = new Blob([byteArray], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'reporte.pdf';
            document.body.appendChild(link);
            link.click();
            dispatch(setResultsByUserFile(null));
        }
    }, [reportByUserFile])


    return (
        <div className="d-flex flex-column flex-grow-1 justify-content-between">
            <div>
                <div className="c-form-group">
                    <label>Select User</label>
                    <InputSeatch onChange={filterUsers} placeholder={"Filter..."} />
                    <UsersList users={usersToDisplay} selectedUser={selectedUser} setSelectedUser={setSelectedUser} allUsers={allUsers} />
                </div>


                {/* <ListItemButton role={undefined} onClick={allUsersClicked}
                sx={{ py: 0, minHeight: 32, marginTop: '12px' }} dense
            >
                <ListItemIcon style={{ minWidth: 16 }}>
                    <Checkbox
                        edge="start"
                        tabIndex={-1}
                        checked={allUsers}
                        disableRipple
                        className="p-0"
                        color="success"
                        inputProps={{ 'aria-labelledby': 'checkbox-list-label' }}
                    />
                </ListItemIcon>
                <ListItemText id={'checkbox-list-label'} primary={"All Users"} />
            </ListItemButton> */}

                <div className="form-check c-form-group mt-12px">
                    <input className="form-check-input c-checkbox" type="checkbox" onChange={allUsersClicked} checked={allUsers} id="allUsers" />
                    <label className="form-check-label c-checkbox-label" htmlFor="allUsers">
                        All Users
                    </label>
                </div>
                <hr />

                <Options
                    groupByProjects={groupByProjects}
                    setGroupByProjects={setGroupByProjects}
                    groupByCustomers={groupByCustomers}
                    setGroupByCustomers={setGroupByCustomers}
                    detailed={detailed}
                    setDetailed={setDetailed}
                    setShowActivity={setShowActivity}
                    setShowDescription={setShowDescription}
                    setShowDate={setShowDate}
                    showActivity={showActivity}
                    showDescription={showDescription}
                    showDate={showDate}
                    allUsers={allUsers}
                />


            </div>
            <div className="d-flex flex-column mt-12px">
                <DateRange onChangeFrom={handleOnChangeFrom} onChangeTo={handleOnChangeTo} />
                <div className='mt-12px'>

                    <button className="mb btn-blue w-100" disabled={loading} onClick={handleGetPreview} >Preview Data</button>
                    <div className="mt-12px d-flex">
                        <ButtonGenerateReport generateFile={generateFile} loading={loadingDownload} />
                    </div>
                </div>
                {/* <ReportByUserResult /> */}
            </div>
        </div>
    )
}

const Options = ({
    groupByProjects,
    setGroupByProjects,
    groupByCustomers,
    setGroupByCustomers,
    detailed,
    setDetailed,
    setShowActivity,
    setShowDescription,
    setShowDate,
    showActivity,
    showDescription,
    showDate,
    allUsers
}) => {

    const handleGroupByProjects = () => {
        setGroupByProjects(!groupByProjects);
    }

    const handleGroupByCustomers = () => {
        setGroupByCustomers(!groupByCustomers);
    }

    const handleSetDetailed = () => {
        setDetailed(!detailed);
    }

    useEffect(() => {
        if (groupByCustomers || groupByProjects) {
            setDetailed(false);
            setShowActivity(false);
            setShowDescription(false);
            setShowDate(false);
        }
    }, [groupByCustomers, groupByProjects])

    useEffect(() => {
        if (detailed) {
            setGroupByCustomers(false);
            setGroupByProjects(false);

            setShowActivity(true);
            setShowDescription(true);
            setShowDate(true);
        }

    }, [detailed])

    useEffect(() => {
        if (allUsers) {
            setDetailed(false);
            setShowActivity(false);
            setShowDescription(false);
            setShowDate(false);

            setGroupByCustomers(false);
            setGroupByProjects(false);
        }

    }, [allUsers])


    const handleSetShowActivity = () => {
        setShowActivity(!showActivity)
    }
    const handleSetShowDescription = () => {
        setShowDescription(!showDescription)
    }
    const handleSetShowDate = () => {
        setShowDate(!showDate)
    }

    return (

        <>
            <div className="c-form-group">
                <label>Options</label>

                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', padding: 0 }}>
                    <CustomCheckBox id={'groupByProjects'} text="Group by projects" disabled={allUsers} checked={groupByProjects} handleChange={handleGroupByProjects} />
                    <CustomCheckBox id={'groupByCustomers'} text="Group by customers" disabled={allUsers} checked={groupByCustomers} handleChange={handleGroupByCustomers} />
                    <CustomCheckBox id={'detailed'} text="Detailed" disabled={allUsers} checked={detailed} handleChange={handleSetDetailed} />

                </List>
            </div>


            <div style={{ marginLeft: '18px' }}>
                {
                    detailed &&
                    <>
                        <CustomCheckBox id={'showActivity'} text="Show Activity" checked={showActivity} handleChange={handleSetShowActivity} />
                        <CustomCheckBox id={'showDescription'} text="Show Description" checked={showDescription} handleChange={handleSetShowDescription} />
                        <CustomCheckBox id={'showDate'} text="Show Date" checked={showDate} handleChange={handleSetShowDate} />
                    </>
                }
            </div>

        </>
    )
}

const UsersList = ({ users, selectedUser, setSelectedUser, allUsers }) => {

    const handleToggle = (event) => {
        setSelectedUser(event.target.value);
    }

    useEffect(() => {
        setSelectedUser('');

    }, [allUsers])


    return (
        <div className="reports__items-list">
            <select value={selectedUser}
                className="mi mt-2"
                name="selectedUser"
                disabled={allUsers}
                onChange={handleToggle}>
                <option value="" disabled></option>

                {
                    users?.map((u) => <option key={u.id} value={u.id}>{u.email}</option>)
                }
            </select>
        </div>
    )
}
