

import { InputBase, TextField } from '@mui/material';
import { Info } from '@mui/icons-material';

import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../hooks/useForm'
import { startLogin, startSignIn } from '../../redux/actions/auth';

// import logo from './../../assets/logo-black.svg';
import bgimage from './../../assets/1.webp';
import logo from './../../assets/logos/tt-logo-black.svg';
// import bgimage from './../../assets/logo-black.png';
// import bgimage from './../../assets/test-bg-1.jpg';
// import logo from "./../../assets/tt-logo-white.png";

import { withStyles } from '@mui/material/styles'
import { useSnackbar } from 'notistack';
import md5 from 'md5';
import { Link, NavLink, useNavigate } from 'react-router-dom';

export const Register = () => {

    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [formValues, handleInputChange, reset, setLoginUser] = useForm({
        organizationName: "",
        email: "",
        fullName: "",
        password: "",
        confirmPassword: ""
    });

    const { organizationName, email, fullName, password, confirmPassword } = formValues;


    const handleSubmit = (e) => {
        e.preventDefault();
       
        var validForm = validateForm();
        setValidForm(validForm);
        if(validForm){
            setLoading(true);

            const passwordHash = md5(password);
            formValues.password = passwordHash;
            dispatch(startSignIn(formValues, { setLoading, enqueueSnackbar }));
        }
    }



    const [passwordError, setPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [organizationError, setOrganizationError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [fullNameError, setFullNameError] = useState(false);

    const [validForm, setValidForm] = useState(true);


    const handleOrgNameChange = (e) => {
        handleInputChange(e);
        const value = e.target.value;
        if (value.length < 1) {
            setOrganizationError(true);
        } else {
            setOrganizationError(false);
        }
    }

    const handleEmailChange = (e) => {
        handleInputChange(e);
        const value = e.target.value;
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(value)) {
            setEmailError(true);
        } else {
            setEmailError(false);
        }
    }

    const handleFullNameChange = (e) => {
        handleInputChange(e);
        const value = e.target.value;
        if (value.length < 2) {
            setFullNameError(true);
        } else {
            setFullNameError(false);
        }
    }

    const handlePasswordChange = (e) => {
        handleInputChange(e);
        setPasswordError(false);
        setConfirmPasswordError(false);
        if (e.target.value.length < 4) {
            setPasswordError(true);
        } else {
            if (e.target.value != password) {
                setConfirmPasswordError(true);
            } else if (e.target.value != confirmPassword) {
                setConfirmPasswordError(true);
            }
        }
    }

    const handleConfirmPasswordChange = (e) => {
        handleInputChange(e);
        setConfirmPasswordError(false);
        if (e.target.value != password) {
            setConfirmPasswordError(true);
        }
    }

    const validateForm = () => {

        let valid = true;
        if (confirmPassword != password) {
            setConfirmPasswordError(true);
            valid = false;
        }

        if (password.length < 4) {
            setPasswordError(true);
            valid = false;
        }

        if (fullName < 2) {
            setFullNameError(true);
            valid = false;
        } 

        if (organizationName < 1) {
            setOrganizationError(true);
            valid = false;
        } 

        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(email)) {
            setEmailError(true);
            valid = false;
        }

        return valid;
    }

    const errors = () => emailError || organizationError || fullNameError || passwordError || confirmPasswordError;

    return (
        <>
            <div className="auth-container">

                <div>
                    <div className="auth-header d-flex flex-column">
                        {/* <img src={logo} className="tt-logo" /> */}
                        <span className="login-title">
                            <span className="login-title-time-text">
                                TIME
                            </span>
                            <span className="login-title-tracker-text">
                                TRACKER
                            </span>
                        </span>
                    </div>

                    <div className="d-flex justify-content-center">
                        <h1 className="auth-title">REGISTER ORGANIZATION</h1>
                    </div>
                    <form className="auth-form" onSubmit={handleSubmit} >
                        <div>
                            <input type="text" placeholder="Organization Name" className="mi mi-large w-100" name="organizationName" defaultValue={organizationName || null} onChange={handleOrgNameChange} />
                            <span className="form-error-msg">
                                {
                                    organizationError && "Organization Name is required."
                                }
                            </span>
                            <input type="text" placeholder="Email" className="mi mi-large mt-2 w-100" name="email" defaultValue={email || null} onChange={handleEmailChange} />
                            <span className="form-error-msg">
                                {
                                    emailError && 'Enter a valid email address.'
                                }
                            </span>
                            <input type="text" placeholder="Full Name" className="mi mi-large mt-2 w-100" name="fullName" defaultValue={fullName || null} onChange={handleFullNameChange} />
                            <span className="form-error-msg">
                                {
                                    fullNameError && 'Full Name must have at least 2 characters.'
                                }
                            </span>
                            <input type="password" placeholder="Password" className="mi mi-large mt-2 w-100" name="password" defaultValue={password || null} onChange={handlePasswordChange} />
                            <span className="form-error-msg">
                                {
                                    passwordError && 'Password must have at least 4 characters.'
                                }
                            </span>
                            <input type="password" placeholder="Confirm Password" className="mi mi-large mt-2 w-100" name="confirmPassword" defaultValue={confirmPassword || null} onChange={handleConfirmPasswordChange} />
                            <span className="form-error-msg">
                                {
                                    confirmPasswordError && 'Passwords do not match'
                                }
                            </span>
                        </div>

                        <div className="d-flex flex-column mt-2">
                            <button type="submit" className={`mb btn-darkk btn-login-large w-100 position-relative ${loading ? ' spinner login-spinner' : ''}`} disabled={
                                loading || errors()}>
                                {
                                    !loading && <span>Register</span>
                                }
                            </button>
                            <Link to="/auth/login" className="mt-3 auth-link">Login</Link>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
