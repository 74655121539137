import * as React from 'react';

import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { setActiveActivity, startDeleteActivity, startGetactivities } from '../../redux/actions/activities';
import { getComparator, stableSort } from '../../helpers/tableHelpers/functions';
import { EnhancedTableHead } from '../../helpers/tableHelpers/components';
import { Edit } from '@mui/icons-material';
import { useNavigate, useNavigation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { NotWorkingYet } from '../helpers/NotWorkingYet';
import CustomMenu from '../formControls/Menu';


const headCells = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Name',
    }
];

const EnhancedTableToolbar = (props) => {

    const { selectedItem, setSelectedItem, handleFilter } = props;

    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch();

    const navigate = useNavigate();


    const handleDeleteItem = () => {
        var res = window.confirm("Sure?");
        if (res) {
            dispatch(startDeleteActivity(selectedItem, { enqueueSnackbar, setSelectedItem }));
        }

    }

    const handleUpdateItem = () => {
        dispatch(setActiveActivity(selectedItem));
        navigate(`/update-activity/${selectedItem.id}`);

    }

    return (
        <Toolbar
            className="p-0"
            sx={{

                ...(selectedItem != null && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {selectedItem != null ? (
                <div className="px-3 d-flex justify-content-between flex-grow-1">

                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        <span className="fw-500">{selectedItem.name}</span> selected
                    </Typography> <>
                        <button className="mb btn-blue ms-2" onClick={handleUpdateItem}>
                            <Edit /> Edit activity
                        </button>

                        <button className="mb btn-red ms-2" onClick={handleDeleteItem}>
                            <DeleteIcon /> Delete activity
                        </button>
                    </>
                </div>
            ) : (
                <input className="search-input" onChange={handleFilter} placeholder="Filter..." />
            )}


        </Toolbar>
    );
}

const ActivitiesList = () => {

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const { activities } = useSelector(state => state.activities);

    const [activitiesToDisplay, setActivitiesToDisplay] = useState(activities);

    useEffect(() => {
        dispatch(startGetactivities());
    }, []);

    useEffect(() => {
        setActivitiesToDisplay(activities);
    }, [activities]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const [selectedItem, setSelectedItem] = useState();

    const handleClick = (event, id) => {
        if (selectedItem?.id == id) {
            setSelectedItem(null);
        } else {
            setSelectedItem(activities.filter(x => x.id == id)[0]);
        }

    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilter = (e) => {
        const value = e.target.value.toLowerCase();
        if (value) {
            setActivitiesToDisplay(activities.filter(x => x.name.toLowerCase().includes(value)));
        } else {
            setActivitiesToDisplay(activities);
        }
    }

    const isSelected = (id) => id == selectedItem?.id

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - activities.length) : 0;

        const handleDeleteItem = (activity) => {
            var res = window.confirm("Sure?");
            if (res) {
                dispatch(startDeleteActivity(activity, { enqueueSnackbar, setSelectedItem }));
            }
    
        }
    
        const handleUpdateItem = (activity) => {
            dispatch(setActiveActivity(activity));
            navigate(`/update-activity/${activity.id}`);
    
        }

    return (
        <>
            <EnhancedTableToolbar selectedItem={null} handleFilter={handleFilter} setSelectedItem={setSelectedItem} />
            <TableContainer>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={'medium'}
                >
                    <EnhancedTableHead
                        numSelected={0}
                        order={order}
                        orderBy={orderBy}
                        selectedItem={null}
                        onRequestSort={handleRequestSort}
                        rowCount={activities.length}
                        headCells={headCells}
                        haveActions={true}
                        hideCheckbox={true}
                    />
                    <TableBody>
                        {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
                        {stableSort(activitiesToDisplay, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((activity, index) => {
                                const isItemSelected = isSelected(activity.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        // onClick={(event) => handleClick(event, activity.id)}
                                        // role="checkbox"
                                        // aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={activity.id}
                                        // selected={isItemSelected}
                                        // className="cursor-pointer"
                                    >
                                        {/* <TableCell padding="checkbox" style={{
                                            padding: '6px',
                                            paddingTop: '9px',
                                        }}>
                                            
                                            <div className="form-check c-form-group">
                                                <input className="form-check-input c-checkbox" type="checkbox" onChange={()=>{}} checked={isItemSelected} />

                                            </div>
                                        </TableCell> */}
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            className='padding-10px'
                                        >
                                            {activity.name}
                                        </TableCell>

                                        <TableCell className='padding-10px'>
                                            <CustomMenu actions={[
                                                { text: 'Edit', action: (event) =>{ handleUpdateItem(activity)}},
                                                { text: 'Delete', action: (event) =>{ handleDeleteItem(activity)}},
                                            ]} />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: (53) * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25]}
                component="div"
                count={activities.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
}

export default ActivitiesList;