
import { ArrowBack } from '@mui/icons-material';
import { Button, ButtonBase, IconButton, Paper, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from '../../../hooks/useForm';
import { startCreateActivity, startGetActiveActivity, startUpdateActivity } from '../../../redux/actions/activities';

const UpdateActivity = () => {

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const { active } = useSelector(state => state.activities);

  const navigate = useNavigate();

  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const [formValues, handleInputChange, reset] = useForm({ name: '' });
  const { name } = formValues;


  const handleOnSubmit = (e) => {
    e.preventDefault();
    setLoadingUpdate(true);
    dispatch(startUpdateActivity(formValues, { resetForm: reset, enqueueSnackbar, setLoading: setLoadingUpdate }));
  }


  useEffect(() => {
    const { id } = params;
    if (active) {
      reset(active);
    } else {
      if(!id){
        navigate('/activities');
      } else {
        dispatch(startGetActiveActivity(id, { setLoading }));
      }

    }
  }, [active]);

  const navigateBack = () => {
    navigate("/activities");
  }

  return (
    <div className="container mt-2">

      <div className="page-header">
        <div className="d-flex align-items-center">
          <Tooltip onClick={navigateBack}>
            <IconButton>
              <ArrowBack />
            </IconButton>
          </Tooltip>
          <h2 className="ms-2">Edit Activity</h2>
        </div>
      </div>


        <div className="row">
          {
            loading ?
              <><h1>Loading...</h1></> :
              <>
                <div className="col-md-6">
                  <form onSubmit={handleOnSubmit} className="default-form">
                    <div>
                      <label>Activity Name</label>
                      <input type="text" value={name} name="name" onChange={handleInputChange} className="mi w-100 mt-2" />
                    </div>
                    <div className="mt-3 d-flex justify-content-end">
                    <button className="mb btn-blue" disabled={loadingUpdate}>Save Changes</button>
                    </div>

                  </form>
                </div>
              </>
          }

        </div>

    </div>
  )
}

export default UpdateActivity;