import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "../../hooks/useForm";
import { useEffect, useState } from "react";
import { startChangePassword } from "../../redux/actions/users";
import md5 from "md5";
import { startUpdateAccount } from "../../redux/actions/account";



const AccountData = () => {

    const { user } = useSelector(state => state.auth);

    const dispatch = useDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);

    const [formValues, handleInputChange, reset] = useForm({
        fullName: user.fullName
    });


    const {fullName } = formValues;

    const handleSubmit = (e) => {
        e?.preventDefault();

        setLoading(true);
        dispatch(startUpdateAccount(formValues, { setLoading, enqueueSnackbar }));
    }

    const resetForm = () => {
        reset();
    }

    // const validateFields = () => {
    //     if (!currentPassword || !newPassword || !confirmPassword) {
    //         if (!currentPassword) {
    //             setCurrentPasswordTouched(true);
    //         }
    //         if (!newPassword) {
    //             setNewPasswordTouched(true);
    //         }
    //         if (!confirmPassword) {
    //             setConfirmPasswordTouched(true);
    //         }
    //         return false;
    //     }
    //     return true;
    // }


    return (
        <>

            <div className="row">
                <div className="col-md-6">
                    <form onSubmit={handleSubmit} className="default-form">
                        <div>
                            <label>Email</label>
                            <input
                                type="text"
                                value={user?.email}
                                disabled={true}
                                className="mi w-100 mt-2"
                            />
                            {/* {
                            (currentPasswosrdTouched && !currentPassword) && <span className="text-danger small">This field is required</span>
                        } */}
                        </div>


                        <div className="mt-3">
                            <label>Full Name</label>
                            <input
                                type="text"
                                value={fullName}
                                name="fullName"
                                onChange={(e) => {
                                    handleInputChange(e);
                                    // setCurrentPasswordTouched(true);
                                }}
                                className="mi w-100 mt-2"
                            />
                            {/* {
                            (currentPasswordTouched && !currentPassword) && <span className="text-danger small">This field is required</span>
                        } */}
                        </div>



                        <div className="mt-3 d-flex justify-content-end">
                            <button type="submit" className="mb btn-blue" disabled={loading} >Save Changes</button>
                        </div>
                    </form>
                </div>
            </div>
        </>

    )
}

export default AccountData;