import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { removeUserFromActiveProject, setActiveDetailProject, setActiveProject, startChangeRole, startRemoveUser, updateUserProjectRole } from '../../redux/actions/projects';
import { getComparator, stableSort } from '../../helpers/tableHelpers/functions';
import { EnhancedTableHead } from '../../helpers/tableHelpers/components';
import { Edit } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, Radio, RadioGroup } from '@mui/material';
import { getProjectRole } from '../../helpers/helpers';
import CustomMenu from '../formControls/Menu';



const headCells = [
    {
        id: 'email',
        numeric: false,
        disablePadding: true,
        label: 'Email',
    },
    {
        id: 'userRoleInProject',
        numeric: false,
        disablePadding: true,
        label: 'Role',
    },
];

const EnhancedTableToolbar = (props) => {
    const { selectedItem, setSelectedItem, setOpen, projectID } = props;

    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const handleChangeRole = () => {
        setOpen(true);
    }

    const handleRemoveUser = () => {
        const res = window.confirm('Sure?');
        if (res) {
            dispatch(startRemoveUser(projectID, selectedItem.userID, { enqueueSnackbar, success: userRemovedFromProject }))
        }
    }

    const userRemovedFromProject = (projectID, userID) => {
        setSelectedItem(null);
        dispatch(removeUserFromActiveProject(userID));
    }

    return (
        <Toolbar
            className="p-0"
            sx={{

                ...(selectedItem != null && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >

            {selectedItem != null ? (
                <div className="px-3 d-flex justify-content-between flex-grow-1">

                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        <span className="fw-500">{selectedItem.email}</span> selected
                    </Typography>
                    <button onClick={handleChangeRole} className='mb btn-outline-blue'>
                        Change role
                    </button>
                    <button onClick={handleRemoveUser} className='ms-2 mb btn-red'>
                        Remove user
                    </button>
                </div>
            ) : (
                <input className="search-input" placeholder="Filter users..." />

            )}

        </Toolbar>
    );
}

const ProjectMembersList = () => {

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const { activeDetail } = useSelector(state => state.projects);

    const [items, setItems] = useState([])

    const [selectedItem, setSelectedItem] = useState();


    const [open, setOpen] = useState(false);


    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {

    }, []);

    useEffect(() => {
        setSelectedItem(null);
        setItems(activeDetail.userProjects);
    }, [activeDetail]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleClick = (event, userID) => {
        if (selectedItem?.userID == userID) {
            setSelectedItem(null);
        } else {
            setSelectedItem(items.filter(x => x.userID == userID)[0]);
        }

    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const isSelected = (id) => id == selectedItem?.userID

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - items.length) : 0;

        const handleChangeRole = (userProject) => {
            setSelectedItem(userProject)
            setOpen(true);
        }

        const handleRemoveUser = (userProject) => {
            const res = window.confirm('Sure?');
            if (res) {
                dispatch(startRemoveUser(activeDetail.id, userProject.userID, { enqueueSnackbar, success: userRemovedFromProject }))
            }
        }
    
        const userRemovedFromProject = (projectID, userID) => {
            setSelectedItem(null);
            dispatch(removeUserFromActiveProject(userID));
        }

    return (
        <>

            <EnhancedTableToolbar selectedItem={null} setSelectedItem={setSelectedItem} setOpen={setOpen} projectID={activeDetail.id} />
            <TableContainer>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={'medium'}
                >
                    <EnhancedTableHead
                        numSelected={0}
                        order={order}
                        orderBy={orderBy}
                        selectedItem={null}
                        onRequestSort={handleRequestSort}
                        rowCount={items.length}
                        headCells={headCells}
                        haveActions={true}
                        hideCheckbox={true}
                    />
                    <TableBody>
                        {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
                        {stableSort(items, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((userProject, index) => {
                                const isItemSelected = isSelected(userProject.userID);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        // onClick={(event) => handleClick(event, userProject.userID)}
                                        // role="checkbox"
                                        // aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={userProject.userID}
                                        // selected={isItemSelected}
                                        // className="cursor-pointer"
                                    >
                                        {/* <TableCell padding="checkbox" style={{
                                            padding: '6px',
                                            paddingTop: '9px',
                                        }}>
                                          
                                            <div className="form-check c-form-group">
                                                <input className="form-check-input c-checkbox" type="checkbox" onChange={()=>{}} checked={isItemSelected} />
                                               
                                            </div>
                                        </TableCell> */}
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            className='padding-10px'
                                        >
                                            {userProject.email}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                        >
                                            {getProjectRole(userProject.userRoleInProject)}
                                        </TableCell>

                                        <TableCell className='padding-10px'>
                                            <CustomMenu actions={[
                                                { text: 'Change Role', action: (event) =>{ handleChangeRole(userProject)}},
                                                { text: 'Remove', action: (event) =>{ handleRemoveUser(userProject)}},
                                            ]} />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: (53) * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25]}
                component="div"
                count={items.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {
                selectedItem && <ChangeRoleDialog open={open} handleClose={handleClose} userProject={selectedItem} projectID={activeDetail.id} setSelectedItem={setSelectedItem} />
            }

        </>
    );
}

const ChangeRoleDialog = ({ open, userProject, handleClose, projectID, setSelectedItem }) => {

    const roles = [{ value: 0, name: 'Member' }, { value: 1, name: 'Project Manager' }];

    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch();

    const [selectedRole, setSelectedRole] = useState(userProject.userRoleInProject);
    const [loading, setLoading] = useState(false);

    const handleToggle = (event) => {
        setSelectedRole(event.target.value);
    }

    const handleCloseDialog = () => {
        handleClose();
    }

    const handleChangeRole = () => {
        const changeRole = {
            projectID: projectID,
            userToChangeID: userProject.userID,
            userRoleInProject: Number(selectedRole)
        };
        setLoading(true);
        dispatch(startChangeRole(changeRole, { enqueueSnackbar, setLoading, success }));
    }

    const success = (changeRole) => {
        setSelectedItem(null);
        dispatch(updateUserProjectRole(changeRole));
        handleClose();
    }
    return (
        <Dialog open={open} onClose={handleCloseDialog} sx={{ '& .MuiDialog-paper': { maxHeight: 800 } }}>
            <DialogTitle>Change Role</DialogTitle>

            <DialogContent>
                <div className="d-flex flex-column" style={{ width: '420px' }}>
                    <FormLabel>User</FormLabel>
                    <span className="fw-500">{userProject.email}</span>
                </div>
                <div className="d-flex flex-column mt-3" style={{ width: '420px' }}>
                    <FormLabel>Current Role</FormLabel>
                    <span className="fw-500">{getProjectRole(userProject.userRoleInProject)}</span>
                </div>
                <FormControl className="mt-3 w-100" style={{ width: '100%' }}>
                    <FormLabel id="demo-radio-buttons-group-label">Role</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={selectedRole}
                        onChange={handleToggle}
                        name="selectedCustomer"
                    >
                        {roles.map((c) => <FormControlLabel
                            key={c.value}
                            value={c.value} control={<Radio />}
                            label={c.name}
                        />)}
                    </RadioGroup>
                </FormControl>


            </DialogContent>

            <DialogActions>
                <button onClick={handleCloseDialog} disabled={loading} className="mb btn-outline-blue">
                    Cancel
                </button>
                <button onClick={handleChangeRole} disabled={loading} className="ms-2 mb btn-blue">
                    Save changes
                </button>
            </DialogActions>
        </Dialog>
    )
}

export default ProjectMembersList;