

import { Button, InputBase, TextField } from '@mui/material';
import { Info } from '@mui/icons-material';

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../hooks/useForm'
import { startLogin, startResetPassword, startSignIn } from '../../redux/actions/auth';

// import logo from './../../assets/logo-black.svg';
import bgimage from './../../assets/1.webp';
import logo from './../../assets/logos/tt-logo-black.svg';
// import bgimage from './../../assets/logo-black.png';
// import bgimage from './../../assets/test-bg-1.jpg';
// import logo from "./../../assets/tt-logo-white.png";

import { withStyles } from '@mui/material/styles'
import { useSnackbar } from 'notistack';
import md5 from 'md5';
import { Link, NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { startCompleteInvitation, startValidateInvitation } from '../../redux/actions/invitations';

export const ResetPassword = () => {


    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState();
    const [email, setEmail] = useState();

    const [formValues, handleInputChange, reset] = useForm({
        password: "",
        confirmPassword: ""
    });

    const { password, confirmPassword } = formValues;

    const location = useLocation();

    useEffect(() => {

        const search = location.search
        const searchParams = new URLSearchParams(search)
        const token = searchParams.get('token');
        const email = searchParams.get('email');
        setToken(token);
        setEmail(email);
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();

        var validForm = validateForm();
        if (validForm) {
            setLoading(true);

            const passwordHash = md5(password);

            await startResetPassword(
                email,
                passwordHash,
                token
                , { setLoading, enqueueSnackbar, success });
        }
    }

    const success = () => {
        navigate('/auth/login');
        // enqueueSnackbar("✅ Password successfully changed.", {
        //     // variant: 'success',
        //     autoHideDuration: 3000,
        //     anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
        //     action: (key) => (

        //         <Button className="btn-action-snackbar" color="secondary" onClick={() => {
        //             closeSnackbar(key);
        //             navigate('/auth/login');
        //         }}>
        //             Go to Login
        //         </Button>
        //     ),
        // });
       enqueueSnackbar("✅ Password successfully changed.", {
            // variant: 'success',
            autoHideDuration: 3000,
            anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
        });

    }

    const [passwordError, setPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);


    const handlePasswordChange = (e) => {
        handleInputChange(e);
        setPasswordError(false);
        setConfirmPasswordError(false);
        if (e.target.value.length < 4) {
            setPasswordError(true);
        } else {
            if (e.target.value != password) {
                setConfirmPasswordError(true);
            } else if (e.target.value != confirmPassword) {
                setConfirmPasswordError(true);
            }
        }
    }

    const handleConfirmPasswordChange = (e) => {
        handleInputChange(e);
        setConfirmPasswordError(false);
        if (e.target.value != password) {
            setConfirmPasswordError(true);
        }
    }

    const validateForm = () => {

        let valid = true;
        if (confirmPassword != password) {
            setConfirmPasswordError(true);
            valid = false;
        }

        if (password.length < 4) {
            setPasswordError(true);
            valid = false;
        }

        return valid;
    }

    const errors = () => passwordError || confirmPasswordError;

    return (
        <>
            <div className="auth-container">

                <div>
                    <div className="auth-header d-flex flex-column">
                        {/* <img src={logo} className="tt-logo" /> */}
                        <span className="login-title">
                            <span className="login-title-time-text">
                                TIME
                            </span>
                            <span className="login-title-tracker-text">
                                TRACKER
                            </span>
                        </span>
                    </div>

                    <div className="d-flex justify-content-center flex-column align-items-center">


                        <h1 className="auth-title">RESET PASSWORD</h1>

                        <form className="auth-form" onSubmit={handleSubmit} >
                            <div>
                                <input type="text" className="mi mi-large mt-2 w-100" disabled defaultValue={email || null} />
                                <span className="form-error-msg"></span>

                                <input type="password" placeholder="New Password" className="mi mi-large mt-2 w-100" name="password" defaultValue={password || null} onChange={handlePasswordChange} />
                                <span className="form-error-msg">
                                    {
                                        passwordError && 'Password must have at least 4 characters.'
                                    }
                                </span>
                                <input type="password" placeholder="Confirm New Password" className="mi mi-large mt-2 w-100" name="confirmPassword" defaultValue={confirmPassword || null} onChange={handleConfirmPasswordChange} />
                                <span className="form-error-msg">
                                    {
                                        confirmPasswordError && 'Passwords do not match'
                                    }
                                </span>
                            </div>

                            <div className="d-flex flex-column mt-2">
                                <button type="submit" className={`mb btn-darkk btn-login-large w-100 position-relative ${loading ? ' spinner login-spinner' : ''}`} disabled={
                                    loading || errors()}>
                                    {
                                        !loading && <span>Reset Password</span>
                                    }
                                </button>
                                <Link to="/auth/login" className="mt-4 auth-link">Login</Link>
                            </div>

                            <div className="version-container">
                                <span className="version">v0.1</span>
                            </div>
                        </form>


                    </div>
                </div>
            </div>
        </>
    )
}
