import activitiesTypes from '../types/acitivitiesTypes';

const initialState = {
    activities: [],
    active: null
}

const activitiesReducer = (state = initialState, action) => {
    switch (action.type) {

        case activitiesTypes.activitiesSetActivities:
            return {
                ...state,
                activities: [...action.payload]
            }

        case activitiesTypes.activitiesAddActivity:
            return {
                ...state,
                activities: [{ ...action.payload }, ...state.activities]
            }


        case activitiesTypes.activitiesUpdateActivity:
            return {
                ...state,
                activities: state.activities.map(a => {
                    return a.id == action.payload.id ? { ...action.payload } : a
                })
            }

        case activitiesTypes.activitiesDeleteActivity:
            return {
                ...state,
                activities: state.activities.filter(a => a.id !== action.payload)
            }

        case activitiesTypes.activitiesSetActive:
            return {
                ...state,
                active: action.payload
            }
        case activitiesTypes.activitiesClearAll:
            return initialState

        default:
            return state;
    }
}

export default activitiesReducer;