import { fetchWithToken } from '../../helpers/fetch';
import { displayError } from '../helpers';
import reportsTypes from './../types/reportsTypes';

const controller = 'reports';

export const startGetReportByCustomer = (dateFrom, dateTo,customerID, groupByProjects, groupByUsers, allCustomers,  view) => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/report-by-customer/${dateFrom}/${dateTo}/${customerID}/${groupByProjects}/${groupByUsers}/${allCustomers}`, null, 'GET');
        const body = await res.json();
        view.setLoading(false);
        
        if (body.succeeded) {

            dispatch(setResultsByCustomer(body.data));
            
        } else {
            dispatch(setResultsByUser(null));
            displayError(body, view.enqueueSnackbar);
        }
    }
}

export const startGetReportByProject = (dateFrom, dateTo,projectID, groupByUsers, allProjects, view) => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/report-by-project/${dateFrom}/${dateTo}/${projectID}/${groupByUsers}/${allProjects}`, null, 'GET');
        const body = await res.json();
        view.setLoading(false);

        if (body.succeeded) {

            dispatch(setResultsByProject(body.data));
            
        } else {
            dispatch(setResultsByUser(null));
            displayError(body, view.enqueueSnackbar);
        }
    }
}

export const startGetReportByUser = ( dateFrom, dateTo, userID, groupByProjects, groupByUsers, detailed, showActivity, showDescription, showDate, allUsers, view) => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/report-by-user/${dateFrom}/${dateTo}/${userID}/${groupByProjects}/${groupByUsers}/${detailed}/${showActivity}/${showDescription}/${showDate}/${allUsers}`, null, 'GET');
        const body = await res.json();
        view.setLoading(false);

        if (body.succeeded) {
            dispatch(setResultsByUser(body.data));
        } else {
            dispatch(setResultsByUser(null));
            displayError(body, view.enqueueSnackbar);
        }
    }
}

export const startDownloadReportByUser = ( dateFrom, dateTo, userID, groupByProjects, groupByUsers, detailed, showActivity, showDescription, showDate, allUsers, view) => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/download-by-user/${dateFrom}/${dateTo}/${userID}/${groupByProjects}/${groupByUsers}/${detailed}/${showActivity}/${showDescription}/${showDate}/${allUsers}`, null, 'GET');
        const body = await res.json();
        view.setLoading(false);
        // console.log(body);
        if (body.succeeded) {
            dispatch(setResultsByUserFile(body.data));
        } else {
            dispatch(setResultsByUserFile(null));
            displayError(body, view.enqueueSnackbar);
        }
    }
}
export const startDownloadReportByProject = ( dateFrom, dateTo,projectID, groupByUsers, allProjects, view) => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/download-by-project/${dateFrom}/${dateTo}/${projectID}/${groupByUsers}/${allProjects}`, null, 'GET');
        const body = await res.json();
        view.setLoading(false);
        // console.log(body);
        if (body.succeeded) {
            dispatch(setResultsByProjectFile(body.data));
        } else {
            dispatch(setResultsByProjectFile(null));
            displayError(body, view.enqueueSnackbar);
        }
    }
}

export const startDownloadReportByCustomer = ( dateFrom, dateTo,customerID, groupByProjects, groupByUsers, allCustomers, view) => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/download-by-customer/${dateFrom}/${dateTo}/${customerID}/${groupByProjects}/${groupByUsers}/${allCustomers}`, null, 'GET');
        const body = await res.json();
        view.setLoading(false);
        if (body.succeeded) {
            dispatch(setResultsByCustomerFile(body.data));
        } else {
            dispatch(setResultsByCustomerFile(null));
            displayError(body, view.enqueueSnackbar);
        }
    }
}


export const startGetReportUsers = () => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/get-report-users`, null, 'GET');
        const body = await res.json();
        if (body.succeeded) {
            dispatch(setReportUsers(body.data));
        }
    }
}

export const setResultsByUserFile = (result) => ({
    type: reportsTypes.reportSetResultByUserFile,
    payload: result
})

export const setResultsByProjectFile = (result) => ({
    type: reportsTypes.reportSetResultByProjectFile,
    payload: result
})

export const setResultsByCustomerFile = (result) => ({
    type: reportsTypes.reportSetResultByCustomerFile,
    payload: result
})


const setResultsByCustomer = (result) => ({
    type: reportsTypes.reportsSetGroupByCustomerResults,
    payload: result
})

const setResultsByProject = (result) => ({
    type: reportsTypes.reportsSetGroupByProjectResults,
    payload: result
})

const setResultsByUser = (result) => ({
    type: reportsTypes.reportsSetGroupByUserResults,
    payload: result
})

export const reportsClearAll = () => ({
    type: reportsTypes.reportsClearAll
})

const setReportUsers = (users) => ({
    type: reportsTypes.reportUsers,
    payload: users
})