import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';

export const ReportByProjectResult = () => {

  const { reportByProjectResults } = useSelector(state => state.reports);

  return (
    <>
      {
        reportByProjectResults && (
          <div className="reports__result-content">
            {
              reportByProjectResults.allProjects ?
                <div className='p-3 border-bottom'>
                  Projects Report
                </div> :   <div className="p-3 border-bottom">
                <span className="blue-grey-text">Project</span> <br /> <span className="customer-name fw-500">{reportByProjectResults.projectName}</span>
              </div>
            }
            <table className="table table-sm report-preview-table" >
              <tbody>

                {/* {
                  reportByProjectResults.projectsHours.length == 0 &&
                  <tr>
                    <td></td>
                    {
                      reportByProjectResults.usersHours?.length > 0 && <td></td>
                    }
                  </tr>
                } */}

                {
                  reportByProjectResults.usersHours?.length > 0 && (
                    <tr className="detailed-header">
                      <td>
                        User
                      </td>

                      <td className="text-align-right">
                        Hours
                      </td>
                    </tr>
                  )
                }
                {
                  reportByProjectResults.usersHours?.map((uh, i) => (
                    <tr key={i}>
                      <td>{uh.fullName}</td>
                      <td className="text-align-right">{uh.totalHours} h</td>
                    </tr>
                  ))
                }

                {
                  reportByProjectResults.projectsHours?.length > 0 && (
                    <tr className="detailed-header">
                      <td>
                        Project
                      </td>

                      <td className="text-align-right">
                        Hours
                      </td>
                    </tr>
                  )
                }
                {
                  reportByProjectResults.projectsHours?.map((ph, i) => (
                    <tr key={i}>
                      <td>{ph.projectName}</td>
                      <td className="text-align-right">{ph.totalHours} h</td>
                    </tr>
                  ))
                }

                <tr className="table-footer">

                  {
                    reportByProjectResults.usersHours?.length > 0 && <td></td>
                  }

                  {
                    reportByProjectResults.projectsHours?.length > 0 && (
                      <td></td>
                    )
                  }

                  <td className="text-align-right"><span className="fw-500 me-2"> Total: </span>{reportByProjectResults.totalHours} h</td>

                </tr>

              </tbody>
            </table>
          </div>
        )
      }

    </>
  )
}
