import moment from "moment";
import { fetchWithToken } from "../../helpers/fetch";
import { displayError, displaySuccess } from "../helpers";
import preferencesTypes from "../types/preferencesTypes";
import { startSaveDateFrom, startSaveDateTo } from "./entry";


const controller = 'preferences';



export const setDefaultDates = (dateFrom, dateTo) => ({
    type: preferencesTypes.setDefaultDates,
    payload: {
        dateFrom,
        dateTo
    }
})


export const startSaveDefaultPreferences = (preferences , view) => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/update-preferences`, preferences, 'POST');
        const body = await res.json();
        view.setLoading(false);
        console.log(preferences);
        if (body.succeeded) {
            dispatch(setDefaultPreferences(preferences));
            displaySuccess('Preferences saved successfully', view.enqueueSnackbar);
        } else {
            displayError(body, view.enqueueSnackbar);
        }
    }
}


export const startGetDefaultPreferences = (view) => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/get-preferences`, null, 'GET');
        const body = await res.json();
        view?.setLoading(false);

        if (body.succeeded) {

            if(body.data.dateFrom) dispatch(startSaveDateFrom(moment(body.data.dateFrom)));
            if(body.data.dateTo) dispatch(startSaveDateTo(moment(body.data.dateTo)));

            dispatch(setDefaultPreferences(body.data));
        } else {
            displayError(body, view.enqueueSnackbar);
        }
    }
}



export const setDefaultPreferences = (preferences) => ({
    type: preferencesTypes.setDefaultPreferences,
    payload: preferences
})

export const preferencesClearAll = () => ({
    type: preferencesTypes.preferencesClearAll
})