import React, { Component, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, HashRouter, Navigate, Route, Routes } from 'react-router-dom'
import { Login } from '../components/auth/Login'
import { AppLoading } from '../components/loading/AppLoading'
import MainLayout from '../components/MainLayout'
import { finishCheking, startChekingToken } from '../redux/actions/auth'
import { PrivateRoute } from './PrivateRoute'
import { PublicRoute } from './PublicRoute'
import { Register } from '../components/register/register'
import { CompleteInvitation } from '../components/completeInvitation/CompleteInvitation'
import { ResetPassword } from '../components/auth/ResetPassword'
import { RecoverPassword } from '../components/auth/RecoverPassword'

const AppRouter = () => {

  const dispatch = useDispatch();

  const { logged, checking } = useSelector(state => state.auth);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      dispatch(startChekingToken());
    } else {
      dispatch(finishCheking());
    }
  }, [dispatch]);

  if (checking && localStorage.getItem('token')) {
    return <AppLoading />
  }

  return (
    <HashRouter>
      <Routes>

        {/* {
          !logged &&<Route path="/register" element={
            <Register />
          } />
        } */}
  

        <Route path="/*" element={
          <PrivateRoute isAuthenticated={logged}>
            <Routes>
              <Route path="/*" element={<MainLayout />} />
            </Routes>
          </PrivateRoute>
        } />

        <Route path="/auth/*" element={
          <PublicRoute isAuthenticated={logged}>
            <Routes>
              <Route path="/login" exact element={<Login />} />
              <Route path="/recover-password" element={<RecoverPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/complete-invitation/:token" element={<CompleteInvitation />} />
              <Route path="/register" exact element={<Register />} />
              {/* <Route path="/" element={<Login />} /> */}
            </Routes>
          </PublicRoute>
        } />




      </Routes>
    </HashRouter>
  )
}

export default AppRouter;