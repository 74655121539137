



import { ArrowBack } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { startSendInvitation } from '../../redux/actions/invitations';
import { useEffect } from 'react';

const roles = [
    { value: 0, name: 'Collaborator' },
    { value: 1, name: 'Manager' }
]

export const SendInvitation = () => {

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailTouched, setEmailTouched] = useState(false);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setEmailTouched(true);
    }


    const [selectedRoles, setSelectedRoles] = useState([]);

    const handleRoleSeleced = (e) => {
        if (e.target.checked) {
            setSelectedRoles((roles) => [e.target.value, ...roles]);
        } else {
            setSelectedRoles((roles) => roles.filter(x => x != e.target.value));
        }
    }

    const handleOnSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        dispatch(startSendInvitation({ email, roles: selectedRoles.join(',') }, { setLoading, enqueueSnackbar }));
    }

    const navigate = useNavigate();

    const navigateBack = () => {
        navigate("/users");
    }


    useEffect(() => {
        validateEmail();
    }, [email])


    const validateEmail = () => {
        if (!email) {
            setValidEmail(false);
        }
        const value = email;
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(value)) {
            setValidEmail(false);
        } else {
            setValidEmail(true);
        }
    }


    return (

        <div className="p-3">
            <form onSubmit={handleOnSubmit} className="default-form">
                <div>
                    <label>Email</label>
                    <input type="text" value={email} name="email" onChange={handleEmailChange} className="mi w-100 mt-2" />
                    <span className="form-error-msg">
                        {
                            (!validEmail && emailTouched) && 'Enter a valid email address.'
                        }
                    </span>
                </div>

                <div className="mt-3">
                    <label>Roles</label>
                    {
                        roles.map(r => (
                            <div className="form-check c-form-group" key={r.value}>
                                <input className="form-check-input c-checkbox" type="checkbox" onChange={handleRoleSeleced} value={r.name} id={r.value} />
                                <label className="form-check-label c-checkbox-label" htmlFor={r.value}>
                                    {r.name}
                                </label>
                            </div>
                        ))
                    }
                </div>


                <div className="mt-3 d-flex justify-content-end">
                    <button className="mb btn-blue" disabled={loading || !validEmail || selectedRoles?.length == 0} >Send Invitation</button>
                </div>

            </form>
        </div>
    )
}