import { Paper, Tab, Tabs } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Box } from "@mui/system";
import { ReportByProject } from "./types/ReportByProject";
import { ReportByCustomer } from "./types/ReportByCustomer";
import { ReportByUser } from "./types/ReportByUser";
import { RolesCanDisplay } from "../roleHelpers/RolesCanDisplay";
import { RolesCantDisplay } from "../roleHelpers/RolesCantDisplay";
import ReportResult from "./ReportResult";


const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 200,
        },
    },
};

const roleManagerAdminProjectManager = ['Admin', 'Manager', 'ProjectManager'];
const roleProjectManager = ['ProjectManager'];
const roleManagerAdmin = ['Admin', 'Manager'];

const ReportsScreen = () => {

    const [value, setValue] = useState(1);

    const handleChange = (event) => {
        setValue(event.target.value);
    };


    const dateFromChange = (value) => {
        //console.log(value);
    }
    const dateToChange = (value) => {
        //console.log(value);
    }


    return (
        <>
            {/* <div className="page-header p-0 ps-4">
                <h2>Reports</h2>
            </div> */}

            <div className="row flex-grow-1 border-radius-0 m-0 w-100">

                <div className="col-md-9 p-3 ps-3">
                        <ReportResult />
                    
                </div>
                <div className="col-md-3 py-3 ps-0 d-flex flex-column">
                    <div className="c-form-group">
                        <label>Report type</label>
                        <select className="mi" onChange={handleChange} value={value}>
                            <option value={1}>Users report</option>
                            <option value={2}>Projects report</option>
                            <option value={3}>Customers report</option>
                        </select>
                    </div>
                    <hr />
                    {
                         value == 1 && <ReportByUser />
                    }
                    {
                        value == 2 && <ReportByProject />
                    }
                    {
                        value == 3 && <ReportByCustomer />
                    }
                </div>

                {/* 
                <RolesCanDisplay roles={roleManagerAdmin}>
                    <Box sx={{ borderBottom: 1, borderColor: '#94969D' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="BY PROJECT" {...a11yProps(0)} />
                            <Tab label="BY USER" {...a11yProps(1)} />
                            <Tab label="BY CUSTOMER" {...a11yProps(2)} />
                        </Tabs>
                    </Box>

                </RolesCanDisplay>

                <RolesCantDisplay roles={roleManagerAdmin}>
                    <Box sx={{ borderBottom: 1, borderColor: '#94969D' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="BY PROJECT" {...a11yProps(0)} />
                            <Tab label="BY USER" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                </RolesCantDisplay>

                <TabPanel value={value} index={0}>
                    <ReportByProject />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <ReportByUser />
                </TabPanel>

                <RolesCanDisplay roles={roleManagerAdmin} >
                    <TabPanel value={value} index={2}>
                        <ReportByCustomer />
                    </TabPanel>
                </RolesCanDisplay> */}

            </div>
        </>

    )
}

export default ReportsScreen;


const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <span
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 2 }}>
                    {children}
                </Box>
            )}
        </span>
    );
}