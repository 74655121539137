import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { MoreVert } from '@mui/icons-material';
import { IconButton } from '@mui/material';

 const CustomMenu = ({actions}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAction = (action) => {
        setAnchorEl(null);
        action();
    }

    return (
        <div className='d-flex justify-content-end'>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                style={{ width: '28px', height: '28px' }}
            >
                <MoreVert />
            </IconButton>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                {
                    actions?.map((x,i) => {
                        return <MenuItem disabled={x.disabled} key={i} onClick={() => {handleAction(x.action)} }>{x.text}</MenuItem>
                    })
                }
             
            </Menu>
        </div>
    );
}

export default CustomMenu;