import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { setActiveCustomer } from '../../redux/actions/customers';
import { useState } from 'react';
import { getComparator, stableSort } from '../../helpers/tableHelpers/functions';
import { EnhancedTableHead } from '../../helpers/tableHelpers/components';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { startChangeUserRoles, startGetUsers } from '../../redux/actions/users';
import { NotWorkingYet } from '../helpers/NotWorkingYet';
import { Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, FormLabel, IconButton, Tooltip } from '@mui/material';
import { FilterList } from '@mui/icons-material';
import Menu from '../formControls/Menu';
import CustomMenu from '../formControls/Menu';



const headCells = [
    {
        id: 'email',
        numeric: false,
        disablePadding: true,
        label: 'Email',
    },
    {
        id: 'fullName',
        numeric: false,
        disablePadding: true,
        label: 'Full Name',
    },
    {
        id: 'roles',
        numeric: false,
        disablePadding: true,
        label: 'Roles',
    }
];


const EnhancedTableToolbar = (props) => {


    const { selectedItem, setSelectedItem, handleFilter, setOpen, handleUserDetails } = props;

    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch();

    const navigate = useNavigate();


    const handleDeleteItem = () => {
        // var res = window.confirm("Sure?");
        // dispatch(startDeleteCustomer(selectedItem,{ enqueueSnackbar, setSelectedItem}));

    }

    const handleUpdateItem = () => {
        dispatch(setActiveCustomer(selectedItem));
        navigate(`/update-user/${selectedItem.id}`);

    }


    const handleSetOpen = () => {
        setOpen(true);
    }


    const [checked, setChecked] = useState(false);

    const handleChange = () => {
        setChecked(!checked);
    };

    return (
        <>
            <Toolbar
                className="p-0"
                sx={{
                    ...(selectedItem != null && {
                        bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                    }),

                }}
            >
                {selectedItem != null ? (
                    <div className="px-3 d-flex justify-content-between flex-grow-1">

                        <Typography
                            sx={{ flex: '1 1 100%' }}

                            color="inherit"
                            variant="subtitle1"
                            component="div"
                        >
                            <span className="fw-500">{selectedItem.email}</span> selected
                        </Typography>
                        <div className="d-flex">
                            {/* <Tooltip>
                                <Button onClick={handleUserDetails} style={{ width: '152px' }}>
                                    USER DETAILS
                                </Button>
                            </Tooltip>
                            <Tooltip>
                                <Button onClick={handleSetOpen} style={{ width: '152px' }}>
                                    CHANGE ROLE
                                </Button>
                            </Tooltip> */}
                            <button onClick={handleSetOpen} className='ms-2 mb btn-outline-blue'>
                                Change role
                            </button>
                            <button onClick={handleUserDetails} className='ms-2 mb btn-blue'>
                                User details
                            </button>

                        </div>
                    </div>

                ) : (
                    <>
                        <div className="d-flex justify-content-between w-100">
                            <input className="search-input" onChange={handleFilter} placeholder="Filter..." />
                            <div>
                                {/* <IconButton className="ms-2 btn-icon-blue" color="primary" onClick={handleChange}>
                                    <FilterList />
                                </IconButton> */}
                            </div>
                        </div>
                    </>
                )}

                {selectedItem != null ? (

                    <>
                        {/* <Tooltip title="Edit" onClick={handleUpdateItem}>
                        <IconButton>
                            <Edit />
                        </IconButton>
                    </Tooltip> */}

                        {/* <Tooltip title="Delete" onClick={handleDeleteItem} className="ms-2">
                        <IconButton>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip> */}
                    </>

                ) : (
                    // <Tooltip title="Filter list">
                    //     <IconButton>
                    //         <FilterListIcon />
                    //     </IconButton>
                    // </Tooltip>
                    <></>
                )}
            </Toolbar>
            <Collapse in={checked} collapsedSize={0} >
                <div className='px-3'>
                    <h1>hello world!</h1>
                </div>
            </Collapse>
        </>
    );
}

const UsersList = () => {

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = useState('email');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { users } = useSelector(state => state.users);
    const { user } = useSelector(state => state.auth);

    const [usersToDisplay, setUsersToDisplay] = useState(users);


    const [open, setOpen] = useState(false);

    useEffect(() => {
        dispatch(startGetUsers());
    }, []);

    useEffect(() => {
        setUsersToDisplay(users);
    }, [users]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const [selectedItem, setSelectedItem] = useState();

    const handleClick = (event, id) => {
        if (selectedItem?.id == id) {
            setSelectedItem(null);
        } else {
            setSelectedItem(users.filter(x => x.id == id)[0]);
        }

    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilter = (e) => {
        const value = e.target.value?.toLowerCase();
        if (value) {
            setUsersToDisplay(users.filter(x => x.email.toLowerCase().includes(value) || x.fullName?.toLowerCase().includes(value)));
        } else {
            setUsersToDisplay(users);
        }
    }

    const isSelected = (id) => id == selectedItem?.id

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;



    const handleUserDetails = () => {
        navigate(`/user-details/${selectedItem.id}`);
    }
    const handleSetOpen = () => {
        setOpen(true);
    }

    return (
        <>
            <EnhancedTableToolbar selectedItem={null} handleUserDetails={handleUserDetails} setOpen={setOpen} handleFilter={handleFilter} setSelectedItem={setSelectedItem} />
            <TableContainer>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={'medium'}
                >
                    <EnhancedTableHead
                        numSelected={0}
                        order={order}
                        orderBy={orderBy}
                        selectedItem={null}
                        onRequestSort={handleRequestSort}
                        rowCount={users.length}
                        headCells={headCells}
                        haveActions={true}
                        hideCheckbox={true}
                    />
                    <TableBody>
                        {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
                        {stableSort(usersToDisplay, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((user, index) => {
                                const isItemSelected = isSelected(user.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        // onClick={(event) => handleClick(event, user.id)}
                                        // role="checkbox"
                                        // aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={user.id}
                                        // selected={isItemSelected}
                                        // className="cursor-pointer"
                                    >
                                        {/* <TableCell padding="checkbox" style={{
                                            padding: '6px',
                                            paddingTop: '9px',
                                        }}>
                                           
                                            <div className="form-check c-form-group">
                                                <input className="form-check-input c-checkbox" type="checkbox" onChange={()=>{}} checked={isItemSelected} />

                                            </div>
                                        </TableCell> */}
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            className='padding-10px'
                                        >
                                            {user.email}
                                        </TableCell>
                                     
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                        >
                                            {user.fullName}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                        >
                                            {user.roles?.join(', ')}
                                        </TableCell>
                                        <TableCell className='padding-10px'>
                                            <CustomMenu actions={[
                                                { text: 'Change Role', disabled: user.roles?.includes('Admin'), action: (event) =>{ handleClick(event, user.id); handleSetOpen()}},
                                            ]} />
                                        </TableCell>
                                    </TableRow>
                                );

                           



                            })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: (53) * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25]}
                component="div"
                count={users.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            {
                selectedItem && <ChangeRoleDialog open={open} handleClose={handleClose} user={selectedItem} setSelectedItem={setSelectedItem} />
            }

            {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
        </>
    );
}

const ChangeRoleDialog = ({ open, user, handleClose, setSelectedItem }) => {

    const roles = [{ value: 0, name: 'Collaborator' },
    { value: 1, name: 'Manager' }];

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();


    const [selectedRoles, setSelectedRoles] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setSelectedRoles(roles.filter(x => user.roles.some(r => r == x.name)));
    }, [user])

    const handleRoleSeleced = (e) => {
        if (e.target.checked) {
            setSelectedRoles([...selectedRoles, roles.filter(x => x.value == e.target.value)[0]]);
        }
        else {
            setSelectedRoles(selectedRoles.filter(x => x.value != e.target.value));
        }
    }

    const handleCloseDialog = () => {
        if (!loading) {
            handleClose();
        }
    }


    const handleChangeRole = () => {
        if (selectedRoles.length == 0) {
            enqueueSnackbar("Select at least one role.", { variant: 'error', autoHideDuration: 3000 });
            return;
        }
        setLoading(true);
        dispatch(startChangeUserRoles(user, selectedRoles.map(x => x.name), { enqueueSnackbar, setLoading, handleCloseDialog }))
    }




    return (
        <Dialog open={open} onClose={handleCloseDialog} sx={{ '& .MuiDialog-paper': { maxHeight: 800 } }}>
            <DialogTitle>Change Role</DialogTitle>

            <DialogContent>
                <div className="d-flex flex-column" style={{ width: '420px' }}>
                    <FormLabel>User</FormLabel>
                    <span className="fw-500">{user.email}</span>
                </div>
                <div className="mt-3">
                    <label>Roles</label>
                    {
                        roles.map(r => (
                            <div className="form-check c-form-group mt-12px" key={r.value}>
                                <input className="form-check-input c-checkbox" type="checkbox" checked={selectedRoles.map(x => x.value).includes(r.value)} onChange={handleRoleSeleced} value={r.value} id={r.value} />
                                <label className="form-check-label c-checkbox-label" htmlFor={r.value}>
                                    {r.name}
                                </label>
                            </div>
                        ))
                    }
                </div>

            </DialogContent>

            <DialogActions>
                <button onClick={handleCloseDialog} disabled={loading} className="mb btn-outline-blue">
                    Cancel
                </button>
                <button onClick={handleChangeRole} disabled={loading} className="ms-2 mb btn-blue">
                    Save changes
                </button>
            </DialogActions>
        </Dialog>
    )
}

export default UsersList;