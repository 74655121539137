import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { useForm } from "../../hooks/useForm";
import { useEffect, useState } from "react";
import { startChangePassword } from "../../redux/actions/users";
import md5 from "md5";



const ChangePassword = () => {

    const dispatch = useDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const [formValues, handleInputChange, reset] = useForm({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        showPasswords: false
    })

    const { currentPassword, newPassword, confirmPassword, showPasswords } = formValues;


    const handleShowPassword = () => {
        handleInputChange({
            target: {
                name: 'showPasswords',
                value: !showPasswords
            }
        })
    }


    const [loading, setLoading] = useState(false);

    const [currentPasswordTouched, setCurrentPasswordTouched] = useState(false);
    const [newPasswordTouched, setNewPasswordTouched] = useState(false);
    const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);


    useEffect(() => {


    }, [currentPassword, newPassword, confirmPassword]);

    const handleSubmit = (e) => {
        e?.preventDefault();
        var validFields = validateFields();

        if (validFields) {
            var currentPasswordHash = md5(currentPassword);
            var newPasswordHash = md5(newPassword);
            setLoading(true);
            dispatch(startChangePassword(currentPasswordHash, newPasswordHash, { enqueueSnackbar, reset: resetForm, setLoading }))

        }

    }

    const resetForm = () => {
        reset();
        setCurrentPasswordTouched(false);
        setNewPasswordTouched(false);
        setConfirmPasswordTouched(false);
    }

    const validateFields = () => {
        if (!currentPassword || !newPassword || !confirmPassword) {
            if (!currentPassword) {
                setCurrentPasswordTouched(true);
            }
            if (!newPassword) {
                setNewPasswordTouched(true);
            }
            if (!confirmPassword) {
                setConfirmPasswordTouched(true);
            }
            return false;
        }
        return true;
    }

    const inputType = () => showPasswords ? 'text' : 'password'

    return (
        <>

            <div className="row">
                <div className="col-md-6">

                <form className="default-form">

                    <div >
                        <label>Current Password</label>
                        <input
                            type={inputType()}
                            value={currentPassword}
                            name="currentPassword"
                            onChange={(e) => {
                                handleInputChange(e);
                                setCurrentPasswordTouched(true);
                            }}
                            className="mi w-100 mt-2"
                        />
                        {
                            (currentPasswordTouched && !currentPassword) && <span className="text-danger small">This field is required</span>
                        }
                    </div>

                    <div className="mt-3">
                        <label>New Password</label>
                        <input
                            type={inputType()}
                            value={newPassword}
                            name="newPassword"
                            onChange={(e) => {
                                handleInputChange(e);
                                setNewPasswordTouched(true);
                            }}
                            className="mi w-100 mt-2"
                        />
                        {
                            (newPasswordTouched && !newPassword) && <span className="text-danger small">This field is required</span>
                        }
                    </div>

                    <div className="mt-3">
                        <label>Confirm Password</label>
                        <input
                            type={inputType()}
                            value={confirmPassword}
                            name="confirmPassword"
                            onChange={(e) => {
                                handleInputChange(e);
                                setConfirmPasswordTouched(true);
                            }}
                            className="mi w-100 mt-2"
                        />
                        {
                            (confirmPasswordTouched && !confirmPassword) && <span className="text-danger small">This field is required</span>
                        }
                        {
                            (confirmPasswordTouched && newPassword !== confirmPassword) && <span className="text-danger small">Passwords not match.</span>
                        }
                    </div>

                    <div className="form-check c-form-group mt-12px">
                        <input className="form-check-input c-checkbox" type="checkbox" onChange={handleShowPassword} checked={showPasswords} id="showPasswords" />
                        <label className="form-check-label c-checkbox-label" htmlFor="showPasswords">
                            Show Passwords
                        </label>
                    </div>

                    <div className="mt-3 d-flex justify-content-end">
                        <button type="button" onClick={handleSubmit} className="mb btn-blue" disabled={loading} >Save Changes</button>
                    </div>
                    </form>
                </div>
            </div>
        </>

    )
}

export default ChangePassword;