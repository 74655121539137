import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setInitialData, startGetInitialData } from '../../redux/actions/hourlyAnalysis';
import { useSnackbar } from 'notistack';
import DateRange from '../formControls/DateRange';



export const HoursCompliancev2 = () => {

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { data } = useSelector(state => state.hourlyAnalysis);

    const [loading, setLoading] = useState(false);



    const [currentDateFrom, setCurrentDateFrom] = useState('');
    const [currentDateTo, setCurrentDateTo] = useState('');

    useEffect(() => {
        if (currentDateFrom && currentDateTo) {
            // setLoading(true);
            // dispatch(startGetDashboard(currentDateFrom, currentDateTo, { enqueueSnackbar, setLoading }));
        }
    }, [currentDateFrom, currentDateTo])
    const handleOnChangeFrom = (dateFrom) => {
        setCurrentDateFrom(dateFrom);
    }
    const handleOnChangeTo = (dateTo) => {
        setCurrentDateTo(dateTo);
    }


    const getData = () => {
        setLoading(true);
        dispatch(setInitialData(null));
        dispatch(startGetInitialData(currentDateFrom?.format("YYYY-MM-DD HH:mm:ss"), currentDateTo?.format("YYYY-MM-DD HH:mm:ss"), { setLoading, enqueueSnackbar }));
    }


    const getStatusClass = (status) => {
        if (status == 0) {
            return 'text-success';
        } else if (status == 1) {
            return 'text-warning';
        } else if (status == 2) {
            return 'text-danger';
        }
        return '';
    }

    return (
        <>

            <div className="mt-2">
                <div className="page-header container">
                    <h2>Hours Compliance</h2>

                    <div className="date-range-container">
                        <DateRange onChangeFrom={handleOnChangeFrom} onChangeTo={handleOnChangeTo} />
                    </div>

                    {/* {
                        data?.businessDays ? <span className="working-days-value">Working days: {data?.businessDays}</span> : <span></span>
                    } */}
                </div>

                <div className="container">
                    <div className="no-entries">
                        <h2>TODO</h2>
                    </div>
                </div>
                {/* <div className="position-relative">

                    {
                        data?.usersData?.length > 0 ?
                            <div className="entriesTable__container entries-scrollbar">

                                <table className="table table-hover entriesTable_table table-responsive">
                                    <thead>
                                        <tr>
                                            <th scope="col">User</th>
                                            <th scope="col">Total hours</th>
                                            <th scope="col">Expected total hours</th>
                                            <th scope="col">Percentage Completed</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data.usersData?.map((userData, i) => {

                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            {userData.user.email}
                                                        </td>
                                                        <td>
                                                            <span className={getStatusClass(userData.status)}>
                                                                {userData.totalHours}
                                                            </span>

                                                        </td>
                                                        <td>
                                                            {userData.expectedTotalHours}
                                                        </td>
                                                        <td>
                                                            {userData.percentageCompleted}%
                                                        </td>
                                                    </tr>)
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            :
                            <>

                              
                            </>
                    }
                </div> */}


            </div >
        </>
    )
}
