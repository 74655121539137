import React from 'react'
import { Add } from '@mui/icons-material';
import { Button, ButtonBase } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ActivitiesList from './ActivitiesList';

const ActivitiesScreen = () => {

  const navigate = useNavigate();

  const handleCreateActivity = () => {
    navigate('/create-activity');
  }

  return (
    <div className="container mt-2">

      <div className="page-header">
        <h2>Activities</h2>

        <div>
          <button className="mb btn-green" onClick={handleCreateActivity}>Create Activity</button>
        </div>
      </div>

      <div>
        <ActivitiesList />
      </div>
    </div>
  )
}

export default ActivitiesScreen;