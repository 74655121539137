



const preferencesTypes = {
    setDefaultDates: '[preferences] Set default dates',
    setDefaultPreferences: '[preferences] Set default preferences',
    preferencesClearAll: '[preferences] Clear all'
}

export default preferencesTypes;