import moment from "moment";
import { fetchWithToken } from "../../helpers/fetch";
import entryRecordTypes from "../types/entryRecord";
import { openSnackbar, sortEntries } from "../../helpers/helpers";
import { entrySetEntries } from "./entry";

const controller = 'entryRecord';

export const startRecording = (entryRecord, view) => {
    return async (dispatch) => {

        const res = await fetchWithToken(`${controller}/start-recording`, entryRecord, 'POST');
        const body = await res.json();

        //view?.setLoading(false);
        //view?.handleClose();

        if (body.succeeded) {
            dispatch(setCurrentRecord(body.data));
        } else {
            alert(body.message);
        }
    }
}


export const startSaveRecord = (entry, view) => {
    return async (dispatch, getState) => {

        const res = await fetchWithToken(`${controller}/save-record`, entry, 'POST');
        const body = await res.json();

        view.setLoading(false);
        view.handleClose();

        if (body.succeeded) {

            openSnackbar(view.enqueueSnackbar, 'The entry has been saved', 'success');


            dispatch(setCurrentRecord(null));
            view.resetForm();

            const { entries, currentDateFrom, currentDateTo } = getState().entry;
       
            body.data.map(x => {
           
                if (moment(x.date) >= currentDateFrom && moment(x.date) <= currentDateTo) {
                    entries.push(x)
                    dispatch(entrySetEntries(sortEntries(entries)));
                }

            })


        } else {
            alert(body.message);
        }
    }
}

export const startGetEntryRecord = (view) => {
    return async (dispatch) => {

        try {
            const res = await fetchWithToken(`${controller}/get-entry-record`, null, 'GET');
            const body = await res.json();

            //view.setLoading(false);
            if (body.succeeded) {
                dispatch(setCurrentRecord(body.data));

            } else {
                alert(body.message);
            }
        } catch (e) {
            console.log(e);
        }

    }
}

export const startCancelRecord = (view) => {
    return async (dispatch) => {
        dispatch(setCurrentRecord(null));
        await fetchWithToken(`${controller}/cancel-record`, null, 'POST');
    }
}

const setCurrentRecord = (entryRecord) => ({
    type: entryRecordTypes.setCurrentRecord,
    payload: entryRecord
});


export const entryRecordClearAll = () => ({
    type: entryRecordTypes.entryRecordClearAll
})