
import { useSelect } from '@mui/base'
import React, { Component, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, Route, Routes } from 'react-router-dom'
import ActivitiesScreen from './activities/ActivitiesScreen'
import CreateActivity from './activities/create-activity/CreateActivity'
import UpdateCtivity from './activities/update-activity/UpdateActivity'
import CreateCustomer from './customers/create-customer/CreateCustomer'
import CustomersScreen from './customers/CustomersScreen'
import UpdateCustomer from './customers/update-customer/UpdateCustomer'
import CreateProject from './projects/create-project/CreateProject'
import { ProjectDetail } from './projects/ProjectDetail'
import ProjectsSceen from './projects/ProjectsSceen'
import UpdateProject from './projects/update-project/UpdateProject'
import ReportsScreen from './reports/ReportsScreen'
import { NotAuthorized } from './roleHelpers/NotAuthorizes'
import { RolesAuthRoute } from './roleHelpers/RolesAuthRoute'
import TimesSheetScreen from './timesSheet/TimesSheetScreen'
import Navbar from './ui/Navbar'
import Sidebar from './ui/Sidebar'
import { CreateUser } from './users/create-user/CreateUser'
import { UsersScreen } from './users/UsersScreen'
import { startGetactivities } from '../redux/actions/activities'
import { startGetProjects, startGetUserProjects } from '../redux/actions/projects'
import { startGetDefaultPreferences } from '../redux/actions/preferences'
import { UserDetails } from './users/userDetails/UserDetails'
import { startGetEntryRecord } from '../redux/actions/entryRecord'
import AccountLayout from './account/account-layout'
import UpdateActivity from './activities/update-activity/UpdateActivity'
import InvitationsScreen from './invitations/InvitationsScreen'
import { CompleteInvitation } from './completeInvitation/CompleteInvitation'
import { Dashboard } from './dashboard/DashboardScreen'
import { HoursCompliancev2 } from './hoursCompliance/HoursCompliancev2'


const roleAdmin = ['Admin'];
const roleManager = ['Manager'];
const roleManagerAdmin = ['Admin', 'Manager'];
const roleManagerAdminProjectManager = ['Admin', 'Manager', 'ProjectManager'];
const roleColaborator = ['Colaborator'];
const rolesManagerColaborator = ['Manager', 'Colaborator'];

const MainLayout = () => {

  const { user } = useSelector(state => state.auth);
  const isAdmin = user.roles?.some(x => x == 'Admin');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startGetProjects());
    dispatch(startGetactivities());
    dispatch(startGetEntryRecord());

  }, [])




  return (
    <>
      <div className='d-flex flex-column flex-grow-1'>



        <Navbar />

        <div className='d-flex flex-1 flex-grow-1 app-container'>

          <Sidebar />


          <div className="content" >

            <Routes>
              <Route path="/" element={<TimesSheetScreen />} />
              <Route path="/account" element={<AccountLayout />} />
              <Route path="/not-authorized" element={<NotAuthorized />} />

              <Route path="/users" element={
                <RolesAuthRoute roles={roleManagerAdmin}>
                  <UsersScreen />
                </RolesAuthRoute>
              } />
              {/* <Route path="/create-user" element={
                <RolesAuthRoute roles={roleAdmin}>
                  <CreateUser />
                </RolesAuthRoute>
              } /> */}

              <Route path="/invitations" element={
                <RolesAuthRoute roles={roleManagerAdmin}>
                  <InvitationsScreen />
                </RolesAuthRoute>
              } />

              <Route path="/user-details/:id" element={
                <RolesAuthRoute roles={roleAdmin}>
                  <UserDetails />
                </RolesAuthRoute>
              } />

              <Route path="/dashboard" element={
                <RolesAuthRoute roles={roleAdmin}>
                  <Dashboard />
                </RolesAuthRoute>
              } />


              <Route path="/reports" element={
                <RolesAuthRoute roles={roleManagerAdminProjectManager}>
                  <ReportsScreen />
                </RolesAuthRoute>
              } />

              <Route path="/hours-compliance" element={
                <RolesAuthRoute roles={roleManagerAdmin}>
                  <HoursCompliancev2 />
                </RolesAuthRoute>
              } />

              <Route path="/customers" element={
                <RolesAuthRoute roles={roleManagerAdmin}>
                  <CustomersScreen />
                </RolesAuthRoute>
              } />
              <Route path="/create-customer" element={
                <RolesAuthRoute roles={roleManagerAdmin}>
                  <CreateCustomer />
                </RolesAuthRoute>
              } />
              <Route path="/update-customer/:id" element={
                <RolesAuthRoute roles={roleManagerAdmin}>
                  <UpdateCustomer />
                </RolesAuthRoute>
              } />

              <Route path="/projects" element={
                <RolesAuthRoute roles={roleManagerAdminProjectManager}>
                  <ProjectsSceen />
                </RolesAuthRoute>
              } />

              <Route path="/update-project/:id" element={
                <RolesAuthRoute roles={roleManagerAdminProjectManager}>
                  <UpdateProject />
                </RolesAuthRoute>
              } />

              <Route path="/project-detail/:id" element={
                <RolesAuthRoute roles={roleManagerAdminProjectManager}>
                  <ProjectDetail />
                </RolesAuthRoute>
              } />

              <Route path="/create-project" element={
                <RolesAuthRoute roles={roleManagerAdmin}>
                  <CreateProject />
                </RolesAuthRoute>
              } />

              <Route path="/activities" element={
                <RolesAuthRoute roles={roleManagerAdmin}>
                  <ActivitiesScreen />
                </RolesAuthRoute>
              } />
              <Route path="/create-activity" element={
                <RolesAuthRoute roles={roleManagerAdmin}>
                  <CreateActivity />
                </RolesAuthRoute>
              } />
              <Route path="/update-activity/:id" element={
                <RolesAuthRoute roles={roleManagerAdmin}>
                  <UpdateActivity />
                </RolesAuthRoute>
              } />
            </Routes>

          </div>

        </div>

      </div>

    </>
  )
}


export default MainLayout;