import hourlyAnalisysTypes from "../types/hourlyAnalysis";

const initialState = {
    data: null
}

const hourlyAnalisysReducer = (state = initialState, action) => {
    switch(action.type) {
        case hourlyAnalisysTypes.haSetInitialData:
            return {
                ...state,
                data: action.payload
            }

        case hourlyAnalisysTypes.hourlyAnalisysClearAll:
            return initialState;
        default:
            return state;
    }
}


export default hourlyAnalisysReducer;