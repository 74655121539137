import { fetchWithToken } from '../../helpers/fetch';
import { displayError, displaySuccess } from '../helpers';
import projectsTypes from './../types/projectsTypes';
import { setActiveUser } from './users';

const controller = 'projects';

export const startGetProjects = () => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/get-all`, {}, 'GET');
        const body = await res.json();

        if (body.succeeded) {
           
            dispatch(setProjects(body.data));

        } else {
            alert(body.message);
        }
    }
}

export const startGetProjectDetails = (id, view) => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/get-details/${id}`, {}, 'GET');
        const body = await res.json();
        view.setLoading(false);
        if(body.succeeded) {
            dispatch(setActiveDetailProject(body.data));
        } else {
            alert(body.errors[0]);
        }
    }
}

export const startGetProject = (id, view) => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/get-details/${id}`, {}, 'GET');
        const body = await res.json();
        view.setLoading(false);
        if(body.succeeded) {
            dispatch(setActiveProject(body.data));
        } else {
            alert(body.errors[0]);
        }
    }
}



export const startCreateProject = (project, view) => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/create`, project, 'POST');
        const body = await res.json();
        view.setLoading(false);

        if (body.succeeded) {
            dispatch(addProject(body.data));
            view.enqueueSnackbar('The project has been created', { variant: 'success', autoHideDuration: 3000 });
            view.resetForm();

        } else {
            alert(body.message);
        }
    }
}

export const startUpdateProject = (project, view) => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/update/${project.id}`, project, 'POST');
        const body = await res.json();
        view.setLoading(false);

        if (body.succeeded) {
            dispatch(updateProject(body.data));
            view.enqueueSnackbar('The project has been updated', { variant: 'success', autoHideDuration: 3000 });
        } else {
            alert(body.message);
        }
    }
}


export const startDeleteProject = (project, view) => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/delete/${project.id}`, {}, 'GET');
        const body = await res.json();

        if (body.succeeded) {
            dispatch(deleteProject(project));
            view.enqueueSnackbar('The project has been deleted', { variant: 'success', autoHideDuration: 3000 });
            view.navigateBack();

        } else {
            alert(body.message);
        }
    }
}

export const startAddUserProject = (userProject, view) => {
    return async (dispatch, getState) => {
        const res = await fetchWithToken(`${controller}/add-user`, userProject, 'POST');
        const body = await res.json();
        view.setLoading(false);

        if (body.succeeded) {
            let { activeDetail } = getState().projects;
            activeDetail.userProjects = [ ...activeDetail?.userProjects, body.data];
            dispatch(setActiveDetailProject({ ...activeDetail}));
            displaySuccess('The user has been added to the project', view.enqueueSnackbar);
            view.handleClose();

        } else {
            displayError(body, view.enqueueSnackbar);
        }
    }
}

export const startChangeRole = (changeRole, view) => {
    return async (dispatch, getState) => {
        const res = await fetchWithToken(`${controller}/change-role`, changeRole, 'POST');
        const body = await res.json();
        view.setLoading(false);

        if (body.succeeded) {
            displaySuccess('The user role has been changed', view.enqueueSnackbar);
            view.success(changeRole);

        } else {
            view.error();
            displayError(body, view.enqueueSnackbar);
        }
    }
}


export const startRemoveUser = (projectID, userID, view) => {
    return async (dispatch, getState) => {
        const res = await fetchWithToken(`${controller}/remove-user`, {projectID, userToRemoveID: userID}, 'POST');
        const body = await res.json();
     
        if (body.succeeded) {
            view.success(projectID, userID);
            displaySuccess('The user has been removed from the project', view.enqueueSnackbar);
          
        } else {
            displayError(body, view.enqueueSnackbar);
        }
    }
}

export const startGetUserProjects = () => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/get-by-user/`, {}, 'GET');
        const body = await res.json();
 
        if (body.succeeded) {
            dispatch(setUserProjects(body.data));

        } else {
            alert(body.message);
        }
    }
}


export const startGetUserProjectsByUserID = (userID) => {
    return async (dispatch, getState) => {
        const res = await fetchWithToken(`${controller}/get-by-user/${userID}`, {}, 'GET');
        const body = await res.json();
 
        if (body.succeeded) {
            const { active } = getState().users;
            active.projects = body.data;
            dispatch(setActiveUser(active));

        } else {
            alert(body.message);
        }
    }
}

const setProjects = (projects) => ({
    type: projectsTypes.projectsSetProjects,
    payload: projects
})

const addProject = (project) => ({
    type: projectsTypes.projectsAddProject,
    payload: project
})


const updateProject = (project) => ({
    type: projectsTypes.projectsUpdateProject,
    payload: project
})


const deleteProject = (project) => ({
    type: projectsTypes.projectsDeleteProject,
    payload: project.id
})

export const updateUserProjectRole = (changeRole) => ({
    type: projectsTypes.projectsUpdateUserProjectRole,
    payload: changeRole
})

export const removeUserFromActiveProject = (userID) => ({
    type: projectsTypes.projectsRemoveUser,
    payload: userID
})

export const setActiveProject = (project) => ({
    type: projectsTypes.projectsSetActive,
    payload: project
})

export const setActiveDetailProject = (project) => ({
    type: projectsTypes.projectsSetActiveDetail,
    payload: project
})

const setUserProjects = (projects) => ({
    type: projectsTypes.projectsSetUserProjects,
    payload: projects
})

export const projectClearAll = () => ({
    type: projectsTypes.projectClearAll
})


