import usersTypes from "../types/usersTypes";


const initialState = {
    users: [],
    active: null
}

const usersReducer = (state = initialState, action) => {

    switch (action.type) {

        case usersTypes.setUsersList:
            return {
                ...state,
                users: [...action.payload]
            }

        case usersTypes.setActiveUser:
            return {
                ...state,
                active: action.payload
            }
            
            case usersTypes.updateUser:
                return {
                    ...state,
                    users: state.users.map(u => {
                        if(u.id != action.payload.id){
                            return u;
                        } else {
                            return {...action.payload }
                        }
                    })
                }
                
            
        case usersTypes.usersClearAll:
            return initialState

        case usersTypes.removeProjectFromActiveUser: 
            return {
                ...state,
                active: {
                    ...state.active,
                    userProjects: state.active.userProjects.filter(x=>x.projectID!=action.payload)
                }
            }

        case usersTypes.changeUserRoleInProjectFromActiveUser:
            return {
                ...state,
                active: {
                    ...state.active,
                    userProjects: state.active.userProjects.map(x=>x.projectID==action.payload.projectID?
                        {...x, userRoleInProject: action.payload.role} : x)
                }
            }
        default:
            return state;
    }

}

export default usersReducer;