import moment from 'moment';
import { openSnackbar, sortEntries } from '../../helpers/helpers';
import { fetchWithToken } from '../../helpers/fetch';
import entryTypes from './../types/entryTypes';

const controller = 'entries';

export const startAddEntry = (entry, view) => {
    return async (dispatch, getState) => {

        const res = await fetchWithToken(`${controller}/create`, entry, 'POST');
        const body = await res.json();
   
        view.setLoading(false);

        if (body.succeeded) {

            const { entries, currentDateFrom, currentDateTo } = getState().entry;
            if (moment(body.data.date) >= currentDateFrom && moment(body.data.date) <= currentDateTo) {
                entries.push(body.data)
                dispatch(entrySetEntries(sortEntries(entries)));
            }
            view.reset();
            openSnackbar(view.enqueueSnackbar, 'The entry has been saved', 'success');

        } else {
            alert(body.message);
        }
    }
}

export const startDuplicateEntry = (entry, view) => {
    return async (dispatch, getState) => {

        const res = await fetchWithToken(`${controller}/duplicate/${entry.id}`, null, 'POST');
        const body = await res.json();

        view.setLoading(false);


        if (body.succeeded) {

            const { entries, currentDateFrom, currentDateTo } = getState().entry;
            if (moment(body.data.date) >= currentDateFrom && moment(body.data.date) <= currentDateTo) {
                entries.push(body.data)
                dispatch(entrySetEntries(sortEntries(entries)));
            }
            openSnackbar(view.enqueueSnackbar, 'Entry was successfully duplicated', 'success');

        } else {
            alert(body.message);
        }
    }
}

export const startGetEntriesBetween = (from, to, view) => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/get-between-dates?from=${from}&to=${to}`, null, 'GET');
        const body = await res.json();
        view.setLoading(false);
        if (body.succeeded) {
            dispatch(entrySetEntries(sortEntries(body.data)));

        } else {
            alert(body.message);
        }
    }
}

// export const startGetEntries = () => {
//     return async (dispatch) => {
//         const res = await fetchWithToken(`${controller}/get-all`, null, 'GET');
//         const body = await res.json();

//         if (body.succeeded) {
//             dispatch(entrySetEntries(sortEntries(body.data)));
//         } else {
//             alert(body.message);
//         }
//     }
// }

export const startDeleteEntry = (entryID, view) => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/delete/${entryID}`, null, 'GET');
        const body = await res.json();

        view.setLoading(false);

        if (body.succeeded) {
            dispatch(deleteEntry(entryID));
            openSnackbar(view.enqueueSnackbar, 'Entry have been deleted', 'success');

        } else {
            alert(body.message);
        }
    }
}

export const startUpdateEntry = (entryID, entry, updateDates = false, resetForm, enqueueSnackbar) => {
    return async (dispatch, getState) => {
        const res = await fetchWithToken(`${controller}/update/${entryID}`, entry, 'POST');
        const body = await res.json();
        if (body.succeeded) {
            const { entries, currentDateFrom, currentDateTo } = getState().entry;

            if (moment(entry.date) >= currentDateFrom && moment(entry.date) <= currentDateTo) {

                if (!body.data.project) {
                    body.data.project = entry.project;
                }
                if (!body.data.activity) {
                    body.data.activity = entry.activity;
                }
                let newEntries = entries.map(e => {
                    return e.id !== entryID ? e :
                        { ...body.data }
                });
                if (updateDates) {
                    dispatch(entrySetEntries(sortEntries(newEntries)));

                } else {
                    dispatch(entrySetEntries(newEntries));

                }
            }
            resetForm(body.data);
            openSnackbar(enqueueSnackbar, 'The changes have been saved', 'success');

        } else {
            alert(body.message);
        }
    }
}

export const startDownloadData = async ( dateFrom, dateTo, customersIDs, projectsIDs, activitiesIDs, view) => {
    console.log(`${controller}/download-data/${dateFrom}/${dateTo}/${customersIDs}/${projectsIDs}/${activitiesIDs}`);
        const res = await fetchWithToken(`${controller}/download-data/${dateFrom}/${dateTo}/${customersIDs}/${projectsIDs}/${activitiesIDs}`, null, 'GET');
        const body = await res.json();
        view.setLoading(false);

        if (body.succeeded) {
            return body.data;
        } else {
            alert(body.message);
            return null;
        }
}

const deleteEntry = (entryID) => ({
    type: entryTypes.entryDelete,
    payload: entryID
})

export const entrySetEntries = (entries) => ({
    type: entryTypes.entrySetEntries,
    payload: entries
})

const entrySetFilteredEntries = (entries) => ({
    type: entryTypes.entrySetFilteredEntries,
    payload: entries
})

export const startSaveDateFrom = (date) => {
    return async (dispatch) => {
        dispatch(saveDateFrom(date));
    }
}

export const startSaveDateTo = (date) => {
    return async (dispatch) => {
        dispatch(saveDateTo(date));
    }
}

const saveDateFrom = (date) => ({
    type: entryTypes.entrySetDateFrom,
    payload: date
});

const saveDateTo = (date) => ({
    type: entryTypes.entrySetDateTo,
    payload: date
});

export const entrySetActive = (entry) => ({
    type: entryTypes.entrySetActive,
    payload: entry
});


export const filterByProjects = (ids) => {
    return (dispatch) => {
        dispatch({
            type: entryTypes.entryFilterByProjects,
            payload: ids
        });
        dispatch(filterEntries());
    }
};

export const filterByCustomers = (ids) => {
    return (dispatch) => {
        dispatch({
            type: entryTypes.entryFilterByCustomers,
            payload: ids
        });
        dispatch(filterEntries());
    }
};

export const filterByActivities = (ids) => {
    return (dispatch) => {
        dispatch({
            type: entryTypes.entryFilterByActivities,
            payload: ids
        });
        dispatch(filterEntries());
    }
};

const filterEntries = () => {
    return async (dispatch, getState) => {

        let result = [];

        const { filterByProjectsIDs, filterByCustomersIDs, filterByActivitiesIDs, baseEntries } = getState().entry;

        if (filterByProjectsIDs?.length < 1 && filterByCustomersIDs?.length < 1 && filterByActivitiesIDs?.length < 1) {
            dispatch(entrySetEntries(baseEntries));
            return;
        }

        if (filterByProjectsIDs?.length > 0) {
            const filteredItems = baseEntries.filter(x => filterByProjectsIDs.includes(x.projectID));
            result = result.concat(filteredItems);
        }

        if (filterByCustomersIDs?.length > 0) {
            const filteredItems = (result.length > 0 ? result : baseEntries).filter(x => filterByCustomersIDs.includes(x.project.customer.id));
            result = filteredItems;
        }

        if (filterByActivitiesIDs?.length > 0) {
            const filteredItems = (result.length > 0 ? result : baseEntries).filter(x => filterByActivitiesIDs.includes(x.activityID));
            result = filteredItems;
        }

        dispatch(entrySetFilteredEntries(result));

    }
}


export const clearAllEntries = () => ({
    type: entryTypes.entryClearAll
})