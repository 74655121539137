import { useState } from "react"


export const useForm = (initialState = {}) => {
    const [values, setValues] = useState(initialState);

    const reset = (newFormState = initialState) => {
        setValues(newFormState);
    }

    const handleInputChange = ({target}) => {
        setValues({
            ...values,
            [target.name]: target.value
        });
     
    }

    const setLoginUser = (email, password) => {
        setValues({email,password});
        console.log(values);
    }

    return [ values, handleInputChange, reset, setLoginUser ];

}