import { fetchWithToken } from "../../helpers/fetch";
import { displayError } from "../helpers";
import dashboardTypes from "../types/dashboardTypes";

const controller = 'dashboard';



export const startGetDashboard = (dateFrom, dateTo, view) => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/get-dashboard/${dateFrom}/${dateTo}`, null, 'GET');
        const body = await res.json();
        view?.setLoading(false);
        if (body.succeeded) {
            dispatch(setDashboard(body.data));
        } else {
            displayError(body, view.enqueueSnackbar);
        }
    }
}



export const setDashboard = (data) => ({
    type: dashboardTypes.dashboardSetInitialData,
    payload: data
})

export const dashbaordClearAll = () => ({
    type: dashboardTypes.dashboardClear
})