
const entryTypes = {
    entrySetEntries: '[entry] Set entries',
    entrySetFilteredEntries: '[entry] Set filtered entries',
    entryAdd: '[entry] Add entry',
    entryDelete: '[entry] Delete entry',
    entrySetActive: '[entry] Set Active',
    entrySetDateFrom: '[entry] Set current date from',
    entrySetDateTo: '[entry] Set current date to',
    entryFilterByProjects: '[entry] Filter by projects',
    entryFilterByCustomers: '[entry] Fiilter by customers',
    entryFilterByActivities: '[entry] Filter by activities',
    entryClearAll: '[entry] clear all'
}

export default entryTypes;
