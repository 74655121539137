import { Button, Paper } from "@mui/material"
import { useEffect, useState } from "react"
import { useForm } from "../../hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { startGetDefaultPreferences, startSaveDefaultPreferences } from "../../redux/actions/preferences";
import moment from "moment";
import { startGetUserProjects } from "../../redux/actions/projects";


export const Preferences = () => {

    const { enqueueSnackbar } = useSnackbar();

    const { defaultPreferences } = useSelector(state => state.preferences)

    const dispatch = useDispatch();

    const [formValues, handleInputChange, reset] = useForm({
        defaultProjectID: '-1',
        defaultActivityID: '-1',
        defaultTimeStart: '',
        defaultTimeFinish: '',
        minutesRounding: ''
    });

    const [loading, setLoading] = useState(false);

    const { userProjects } = useSelector(state => state.projects);
    const { activities } = useSelector(state => state.activities);

    const { defaultProjectID, defaultActivityID, defaultTimeStart, defaultTimeFinish, minutesRounding } = formValues;

    const handleOnSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        dispatch(startSaveDefaultPreferences({ 
            defaultProjectID: defaultProjectID == '-1' ? null : defaultProjectID, 
            defaultActivityID: defaultActivityID == '-1' ? null : defaultActivityID,
            defaultTimeStart, 
            defaultTimeFinish, 
            minutesRounding }, { setLoading, enqueueSnackbar }))
    }

    useEffect(() => {
        setLoading(true);
        dispatch(startGetUserProjects());
        dispatch(startGetDefaultPreferences({ setLoading }));
    }, []);


    useEffect(() => {
        if (defaultPreferences) {
            const { defaultProjectID, defaultActivityID, defaultTimeStart, defaultTimeFinish, minutesRounding } = defaultPreferences;

            let newValues = {
                defaultProjectID: defaultProjectID || '-1',
                defaultActivityID: defaultActivityID || '-1',
                defaultTimeStart: defaultTimeStart || '',
                defaultTimeFinish: defaultTimeFinish || '',
                minutesRounding: minutesRounding
            }
            reset(newValues);
        }
    }, [defaultPreferences]);

    return (
        <>


            {/* <div className="container mt-2">

                <div className="page-header">

                    <div className="d-flex align-items-center">

                        <h2>Preferences</h2>
                    </div>
                </div> */}


                <div className="row">
                    <div className="col-md-6">
                        <form onSubmit={handleOnSubmit} className="default-form">

                            <div>
                                <label>Project</label>

                                <select className="mi w-100 mt-2"  value={defaultProjectID} name="defaultProjectID" onChange={handleInputChange}>
                                    <option value="-1"></option>
                                    {
                                        userProjects?.map(p => (
                                            <option value={p.id} key={p.id}>{p.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="mt-3">
                                <label>Activity</label>

                                <select className="mi w-100 mt-2"  value={defaultActivityID} name="defaultActivityID" onChange={handleInputChange}>
                                    <option value="-1"></option>
                                    {
                                        activities?.map(a => (
                                            <option value={a.id} key={a.id}>{a.name}</option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div className="mt-3 d-flex justify-content-between">
                                <div>
                                    <label>Default Hour Start</label>
                                    <input type="time"  className="mi w-100 mt-2"  name="defaultTimeStart" value={defaultTimeStart} onChange={handleInputChange} />
                                </div>

                                <div>
                                    <label>Default Hour Finish</label>
                                    <input type="time" className="mi w-100 mt-2" name="defaultTimeFinish" value={defaultTimeFinish} onChange={handleInputChange} />
                                </div>
                            </div>

                            <div className="mt-3 d-flex justify-content-between">
                                <div>
                                    <label>Minute Rounding</label>
                                    <input type="text"  className="mi w-50 mt-2"  name="minutesRounding" defaultValue={minutesRounding} onChange={handleInputChange} />
                                </div>
                            </div>



                            <div className="mt-3 d-flex justify-content-end">
                                <button type="submit" className="mb btn-blue" disabled={loading} >Save Changes</button>
                            </div>

                        </form>
                    </div>
                </div>

            {/* </div> */}

        </>
    )
}
