import { Button, ButtonBase, IconButton, List, Menu, MenuItem, Paper } from '@mui/material';

import { Assessment, CalendarMonth, CoPresent, Discount, Group, Groups3 } from '@mui/icons-material';

import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { startLogout } from '../../redux/actions/auth';
import DateNow from '../helpers/DateNow';

import logo from './../../assets/tt-logo-white.png';

import logo1950 from './../../assets/1950-white.svg';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountMenu from './AccountMenu';
import MenuIcon from '@mui/icons-material/Menu';
import { ListLink, RolesCanView } from './Sidebar';


const roleAdmin = ['Admin'];
const roleManager = ['Manager'];
const roleManagerAdmin = ['Admin', 'Manager'];
const roleManagerAdminProjectManager = ['Admin', 'Manager', 'ProjectManager'];


const roleCollaborator = ['Collaborator'];
const rolesManagerCollaborator = ['Manager', 'Collaborator'];



const Navbar = () => {


    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {/* <nav className="navbar navbar-expand-lg navbar-dark bg-dark hide-lg">
                <div className="container-fluid">
                    <span className="d-flex align-items-center cursor-pointer nav-link my-navbar-brand">
                    
                        <h1 className="company">COMPANY</h1>



                    </span>



                    <ButtonBase className="icon-button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" >
                        <MenuIcon />
                    </ButtonBase>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <List component="nav" aria-label="main mailbox folders">

                            <ListLink path={'/'} text={'Entries Sheet'} icon={<CalendarMonth />} />

                            <RolesCanView roles={roleManagerAdminProjectManager}>
                                <ListLink path={'/reports'} text={'Reports'} icon={<Assessment />} />
                            </RolesCanView>

                            <RolesCanView roles={roleAdmin}>
                                <ListLink path={'/users'} text={'Users'} icon={<Group />} />
                            </RolesCanView>

                            <RolesCanView roles={roleManagerAdmin}>
                                <ListLink path={'/customers'} text={'Customers'} icon={<Groups3 />} />
                            </RolesCanView>

                            <RolesCanView roles={roleManagerAdminProjectManager}>
                                <ListLink path={'/projects'} text={'Projects'} icon={<Discount />} />

                            </RolesCanView>

                            <RolesCanView roles={roleManagerAdmin}>
                                <ListLink path={'/activities'} text={'Activities'} icon={<CoPresent />} />
                            </RolesCanView>
                        </List>
                        <form className="d-flex justify-content-end">
                            <AccountMenu />
                        </form>
                    </div>
                </div>
            </nav> */}


            <nav className="c-navbar">

                <Link to={"/"} className="navbar-brand">
                    {/* <img src={logo} className="tt-navbar-logo" /> */}

                    <span className="navbar-time-text">
                        TIME
                    </span>
                    <span className="navbar-tracker-text">
                        TRACKER
                    </span>
                </Link>

                <AccountMenu />

            </nav>
        </>
    );

}

export default Navbar;