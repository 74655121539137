import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { startGetProjects } from '../../redux/actions/projects';
import { getComparator, stableSort } from '../../helpers/tableHelpers/functions';
import { EnhancedTableHead } from '../../helpers/tableHelpers/components';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

const headCells = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Name',
    },
    {
        id: 'customerName',
        numeric: false,
        disablePadding: true,
        label: 'Customer',
    },
];

const EnhancedTableToolbar = (props) => {
    const { selectedItem, setSelectedItem, handleFilter } = props;

    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch();

    const navigate = useNavigate();


    const handleDetails = () => {
        navigate(`/project-detail/${selectedItem.id}`);
    }

    return (
        <Toolbar
            className="p-0"
            sx={{

                ...(selectedItem != null && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {selectedItem != null ? (
                <div className="px-3 d-flex justify-content-between flex-grow-1">

                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        <span className="fw-500">{selectedItem.name}</span> selected
                    </Typography>
                    <Tooltip onClick={handleDetails}>
                        <Button>
                            DETAILS
                        </Button>
                    </Tooltip>
                </div>
            ) : (
                <input className="search-input" onChange={handleFilter} placeholder="Filter..." />
            )}

        </Toolbar>
    );
}

const ProjectsList = () => {

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { projects } = useSelector(state => state.projects);

    const [projectsToDisplay, setProjectsToDisplay] = useState([])

    useEffect(() => {
        dispatch(startGetProjects());
    }, []);

    useEffect(() => {

        setProjectsToDisplay(
            projects.map(p => {
                return {
                    id: p.id,
                    name: p.name,
                    customerName: p.customer.name
                }
            })
        )

    }, [projects]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const [selectedItem, setSelectedItem] = useState();

    const handleClick = (event, id) => {
        // if (selectedItem?.id == id) {
        //     setSelectedItem(null);
        // } else {
        //     setSelectedItem(projects.filter(x => x.id == id)[0]);
        // }
        navigate(`/project-detail/${id}`);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilter = (e) => {
        const value = e.target.value.toLowerCase();
        let result = [];
        if (value) {
            result = projects.filter(x => x.name.toLowerCase().includes(value))
        } else {
            result = projects;
        }

        setProjectsToDisplay(result.map(p => {
            return {
                id: p.id,
                name: p.name,
                customerName: p.customer.name
            }
        }));

    }

    const isSelected = (id) => id == selectedItem?.id

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - projectsToDisplay.length) : 0;

    return (
        <>
   
                <EnhancedTableToolbar selectedItem={selectedItem} handleFilter={handleFilter} setSelectedItem={setSelectedItem} />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={0}
                            order={order}
                            orderBy={orderBy}
                            selectedItem={selectedItem}
                            onRequestSort={handleRequestSort}
                            rowCount={projectsToDisplay.length}
                            headCells={headCells}
                            hideCheckbox={true}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
                            {stableSort(projectsToDisplay, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((project, index) => {
                                    const isItemSelected = isSelected(project.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, project.id)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={project.id}
                                            selected={isItemSelected}
                                            className="cursor-pointer"

                                        >
                                          
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                                className='padding-10px'
                                            >
                                                {project.name}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                className='padding-10px'
                                            >
                                                {project.customerName}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25]}
                    component="div"
                    count={projectsToDisplay.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
          </>
    );
}

export default ProjectsList;