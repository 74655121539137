import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { setActiveCustomer, startDeleteCustomer, startGetCustomers } from '../../redux/actions/customers';
import { useState } from 'react';
import { getComparator, stableSort } from '../../helpers/tableHelpers/functions';
import { EnhancedTableHead } from '../../helpers/tableHelpers/components';
import { Edit } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { NotWorkingYet } from '../helpers/NotWorkingYet';
import CustomMenu from '../formControls/Menu';



const headCells = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Name',
    }
];


const EnhancedTableToolbar = (props) => {


    const { selectedItem, setSelectedItem, handleFilter } = props;

    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch();

    const navigate = useNavigate();


    const handleDeleteItem = () => {
        var res = window.confirm("Sure?");
        if (res) {
            dispatch(startDeleteCustomer(selectedItem, { enqueueSnackbar, setSelectedItem }));
        }

    }

    const handleUpdateItem = () => {
        dispatch(setActiveCustomer(selectedItem));
        navigate(`/update-customer/${selectedItem.id}`);

    }


    return (
        <Toolbar
        className="p-0"
            sx={{
                ...(selectedItem != null && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {selectedItem != null ? (

                <div className="px-3 d-flex justify-content-between flex-grow-1">

                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        <span className="fw-500">{selectedItem.name}</span> selected
                    </Typography> <button className="mb btn-blue ms-2" onClick={handleUpdateItem}>
                        <Edit /> Edit customer
                    </button>
                </div>
            ) : (
                // <Typography
                //     sx={{ flex: '1 1 100%' }}
                //     variant="h6"
                //     id="tableTitle"
                //     component="div"
                // >
                //     Customers List
                // </Typography>\
                <input className="search-input" onChange={handleFilter} placeholder="Filter..." />

            )}


        </Toolbar>
    );
}

const CustomersList = () => {

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const { customers } = useSelector(state => state.customers);

    const [customersToDisplay, setCustomersToDisplay] = useState(customers);


    useEffect(() => {
        dispatch(startGetCustomers());
    }, []);

    useEffect(() => {
        setCustomersToDisplay(customers);
    }, [customers]);


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const [selectedItem, setSelectedItem] = useState();

    const handleClick = (event, id) => {
        if (selectedItem?.id == id) {
            setSelectedItem(null);
        } else {
            setSelectedItem(customers.filter(x => x.id == id)[0]);
        }

    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handleFilter = (e) => {
        const value = e.target.value.toLowerCase();
        if (value) {
            setCustomersToDisplay(customers.filter(x => x.name.toLowerCase().includes(value)));
        } else {
            setCustomersToDisplay(customers);
        }
    }

    const isSelected = (id) => id == selectedItem?.id

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - customers.length) : 0;

        const handleDeleteItem = (customer) => {
            var res = window.confirm("Sure?");
            if (res) {
                dispatch(startDeleteCustomer(customer, { enqueueSnackbar, setSelectedItem }));
            }
    
        }
    
        const handleUpdateItem = (customer) => {
            dispatch(setActiveCustomer(customer));
            navigate(`/update-customer/${customer.id}`);
    
        }

    return (
        <>
            <EnhancedTableToolbar selectedItem={null} handleFilter={handleFilter} setSelectedItem={setSelectedItem} />
            <TableContainer>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={'medium'}
                >
                    <EnhancedTableHead
                        numSelected={0}
                        order={order}
                        orderBy={orderBy}
                        selectedItem={null}
                        onRequestSort={handleRequestSort}
                        rowCount={customers.length}
                        headCells={headCells}
                        haveActions={true}
                        hideCheckbox={true}
                    />
                    <TableBody>
                        {stableSort(customersToDisplay, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((customer, index) => {
                                const isItemSelected = isSelected(customer.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        // onClick={(event) => handleClick(event, customer.id)}
                                        // role="checkbox"
                                        // aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={customer.id}
                                        // selected={isItemSelected}
                                        // className="cursor-pointer"
                                    >
                                         {/* <TableCell padding="checkbox" style={{
                                            padding: '6px',
                                            paddingTop: '9px',
                                        }}>
                                          
                                            <div className="form-check c-form-group">
                                                <input className="form-check-input c-checkbox" type="checkbox" onChange={()=>{}} checked={isItemSelected} />
                                               
                                            </div>
                                        </TableCell> */}
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            className='padding-10px'
                                        >
                                            {customer.name}
                                        </TableCell>
                                        <TableCell className='padding-10px'>
                                            <CustomMenu actions={[
                                                { text: 'Edit', action: (event) =>{ handleUpdateItem(customer)}},
                                                { text: 'Delete', action: (event) =>{ handleDeleteItem(customer)}},
                                            ]} />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: (53) * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25]}
                component="div"
                count={customers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
        </>
    );
}

export default CustomersList;