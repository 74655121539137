import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';

export const ReportByUserResult = () => {

  const { reportByUserResults } = useSelector(state => state.reports);

  // useEffect(() => {
  //   console.log(reportByUserResults);
  // }, [reportByUserResults])


  return (

    <>
      {
        reportByUserResults && (
          <div className="reports__result-content">
            {
              reportByUserResults.allUsers ?
                <div className='p-3 border-bottom'>
                  Users Report
                </div> :  <div className="p-3 border-bottom">
                <span className="blue-grey-text">User</span> <br /> <span className="customer-name fw-500">{reportByUserResults.email}</span>
              </div>
            }
            <table className="table table-sm report-preview-table" >
              <tbody>
           
                {
                  reportByUserResults.projectsHours?.length > 0 && (
                    <tr className="detailed-header">
                      <td>
                        Project
                      </td>

                      <td className="text-align-right">
                        Hours (hh:mm)
                      </td>
                    </tr>
                  )
                }
                {
                  reportByUserResults.projectsHours?.map((p, i) => (
                    <tr key={i}>
                      <td>{p.projectName}</td>
                      <td className="text-align-right">{p.totalHours}</td>
                    </tr>
                  ))
                }

                {
                  reportByUserResults.customersHours?.length > 0 && (
                    <tr className="detailed-header">
                      <td>
                        Customer
                      </td>

                      <td className="text-align-right">
                        Hours (hh:mm)
                      </td>
                    </tr>
                  )
                }
                {
                  reportByUserResults.customersHours?.map((c, i) => (
                    <tr key={i}>
                      <td>{c.customerName}</td>
                      <td className="text-align-right">{c.totalHours}</td>
                    </tr>
                  ))
                }

                {
                  reportByUserResults.projectsCustomersHours?.length > 0 && (
                    <tr className="detailed-header">
                      <td>
                        Customer
                      </td>
                      <td>
                        Project
                      </td>
                      <td className="text-align-right">
                        Hours (hh:mm)
                      </td>
                    </tr>
                  )
                }
                {
                  reportByUserResults.projectsCustomersHours?.map((pc, i) => (
                    <tr key={i}>
                      <td>{pc.customerName}</td>
                      <td>{pc.projectName}</td>
                      <td className="text-align-right">{pc.totalHours}</td>
                    </tr>
                  ))
                }
                {
                  reportByUserResults.entries?.length > 0 && (
                    <tr className="detailed-header">

                      {
                        reportByUserResults.showDescription && <td>
                          Description
                        </td>
                      }
                      <td>
                        Project
                      </td>
                      {
                        reportByUserResults.showActivity && <td>
                          Activity
                        </td>
                      }
                      {
                        reportByUserResults.showDate && <td>
                          Date
                        </td>
                      }
                      <td className="text-align-right">
                        Hours (hh:mm)
                      </td>
                    </tr>
                  )
                }
                {

                  reportByUserResults.entries?.map((e, i) => (
                    <tr key={i}>

                      {
                        reportByUserResults.showDescription && <td>
                          {e.description}
                        </td>
                      }
                      <td>
                        {e.projectName}
                      </td>

                      {
                        reportByUserResults.showActivity && <td>
                          {e.activityName}
                        </td>
                      }
                      {
                        reportByUserResults.showDate && <td>
                          {e.date}
                        </td>
                      }
                      <td className="text-align-right">{e.totalHours}</td>
                    </tr>
                  ))
                }

                {
                  reportByUserResults.usersHours?.length > 0 && (
                    <tr className="detailed-header">
                      <td>
                        User
                      </td>

                      <td className="text-align-right">
                        Hours (hh:mm)
                      </td>
                    </tr>
                  )
                }
                {
                  reportByUserResults.usersHours?.map((uh, i) => (
                    <tr key={i}>
                      <td>{uh.fullName}</td>
                      <td className="text-align-right">{uh.totalHours}</td>
                    </tr>
                  ))
                }


                <tr className="table-footer">
                  {
                    reportByUserResults.usersHours?.length > 0 && <><td></td></>
                  }
                  {
                    reportByUserResults.showDescription && <td></td>
                  }
                  {
                    reportByUserResults.detailed && <td></td>
                  }
                  {
                    reportByUserResults.showActivity && <td></td>
                  }
                  {
                    reportByUserResults.showDate && <td></td>
                  }
                  {
                    reportByUserResults.projectsCustomersHours?.length > 0 && <><td></td><td></td></>
                  }
                  {
                    reportByUserResults.customersHours?.length > 0 && <td></td>
                  }
                  {
                    reportByUserResults.projectsHours?.length > 0 && <td></td>
                  }
                  <td className="text-align-right"><span className="fw-500 me-2"> Total: </span>{reportByUserResults.totalHours}</td>

                </tr>

              </tbody>
            </table>
          </div>
        )
      }
    </>
  )
}
