import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";

const Duration = ({ value }) => {

    const [ valueDecimal, setValueDecimal] =  useState('');

    useEffect(() => {
        if(value){
            let [hours, minutes] = value.split(':');
            let decimalMinutes = Math.floor((minutes * 100)/60).toString();
            if(decimalMinutes[decimalMinutes.length-1] == "0"){
                decimalMinutes = decimalMinutes.slice(0, decimalMinutes.length-1)
            }
            setValueDecimal(`${+hours}${decimalMinutes?'.'+decimalMinutes:''}`);
        }
    },[value])

    return (
        <>
                <Tooltip title={valueDecimal}>
                    <span>
                        {value}
                    </span>
                </Tooltip>
        </>
    )
}

export default Duration;