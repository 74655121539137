import { fetchWithToken } from "../../helpers/fetch";
import { openSnackbar } from "../../helpers/helpers";
import types from "../types/authTypes";

const controller = 'account';

export const startUpdateAccount = (user, view) => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/update-account`, user, 'POST');
        const body = await res.json();

        view.setLoading(false);
        if (body.succeeded) {
            dispatch( {
                type: types.authUpdateUser,
                payload: user
            });
            openSnackbar(view.enqueueSnackbar, 'Changes saved', 'success');

        } else {
            alert(body.message);
        }
    }
}