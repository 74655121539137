import dashboardTypes from "../types/dashboardTypes";

const initialState = {
    dashboard: null
}

const dashboardReducer = (state = initialState, action) => {
    switch(action.type) {
        case dashboardTypes.dashboardSetInitialData:
            return {
                ...state,
                dashboard: action.payload
            }

        case dashboardTypes.dashboardClear:
            return initialState;
            
        default:
            return state;
    }
}


export default dashboardReducer;