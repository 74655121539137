import { Paper } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { calculateHoursDiff, sortByHours } from "../../../helpers/helpers";
import { filterByActivities } from "../../../redux/actions/entry";
import Duration from "../../formControls/Duration";


export const EntriesActivities = () => {

    const dispatch = useDispatch();

    const { activities } = useSelector(state => state.activities);

    const { baseEntries: entries, filterByActivitiesIDs } = useSelector(state => state.entry);

    const [activityEntries, setActivityEntries] = useState([]);


    useEffect(() => {
        loadActivityEntriesTimes();
    }, [entries, activities]);

    const loadActivityEntriesTimes = () => {
        setActivityEntries([]);

        let ae = [];
        activities?.map(a => {
            let activityEntries = entries?.filter(x => x.activityID === a.id);
            let totalActivityHours = '00:00';

            activityEntries?.map(e => {
                let totalMinutes = moment.duration(totalActivityHours).asMinutes() +
                    moment.duration(e.totalTime).asMinutes();
                let totalHours = Math.trunc(totalMinutes / 60);
                let otherMinutes = totalMinutes % 60;
                const timeDiff = `${(totalHours < 10 ? `0${totalHours}` : totalHours)}:${otherMinutes < 10 ? `0${otherMinutes}` : otherMinutes}`;
                totalActivityHours = timeDiff;
            })

            ae.push({
                activity: a,
                hours: totalActivityHours === '00:00' ? null : totalActivityHours
            });
        });

        sortByHours(ae);
        setActivityEntries(ae);
    }

    const handleActivitySelected = (e, activity) => {
        if (e.target.checked) {
            dispatch(filterByActivities([...filterByActivitiesIDs, activity.id]));
        } else {
            dispatch(filterByActivities(filterByActivitiesIDs.filter(x => x !== activity.id)));
        }
    }

    return (
        <div className="border-around border-radius-4px">
            <div className="footer-header">
                <h2 className="footer-title">ACTIVITIES</h2>
            </div>
            <ul className="list-group list-group-flush footer-list entries-scrollbar">
                {
                    activityEntries?.map(ae => {
                        return (
                            <li className="list-group-item d-flex justify-content-between align-items-center footer-list-item" key={ae.activity.id}>

                                <span className={ae.hours == null ? 'text-grey' : ''}>
                                    <input
                                        disabled={ae.hours == null}
                                        className="form-check-input me-1 c-checkbox-small c-checkbox"
                                        type="checkbox"
                                        onChange={(e) => handleActivitySelected(e, ae.activity)}
                                        value={ae.activity.id}
                                        id={ae.activity.id}
                                    />
                                    <label className={'form-check-label' + (ae.hours == null ? 'text-grey' : '')} htmlFor={ae.activity.id}>{ae.activity.name}</label>
                                </span>
                             

                                <span className="text-success fw-600">
                                    <Duration value={ae.hours} />

                                </span>


                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}



