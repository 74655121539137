import { ArrowBack } from '@mui/icons-material';
import { Button, ButtonBase, IconButton, Paper, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from '../../../hooks/useForm';
import { startCreateCustomer } from '../../../redux/actions/customers';

const CreateCustomer = () => {

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [formValues, handleInputChange, reset] = useForm({
    name: ''
  });

  const { name } = formValues;


  const handleOnSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(startCreateCustomer(formValues, { resetForm: reset, enqueueSnackbar, setLoading }));
  }


  const navigateBack = () => {
    navigate("/customers");
  }

  return (
    <div className="container mt-2">
      <div className="page-header">
        <div className="d-flex align-items-center">
          <Tooltip onClick={navigateBack}>
            <IconButton>
              <ArrowBack />
            </IconButton>
          </Tooltip>
          <h2 className="ms-2">Create Customer</h2>
        </div>
      </div>


        <div className="row">
          <div className="col-md-6">
            <form onSubmit={handleOnSubmit} className="default-form">
              <div>
                <label>Customer Name</label>
                <input type="text" value={name} name="name" onChange={handleInputChange} className="mi w-100 mt-2" />
              </div>
              <div className="mt-3 d-flex justify-content-end">
           
              <button className="mb btn-blue" disabled={loading} >Create</button>

              
              </div>

            </form>
          </div>
        </div>

    </div>
  )
}

export default CreateCustomer;