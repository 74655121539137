import moment from "moment";
import { fetchWithoutToken, fetchWithToken } from "../../helpers/fetch"
import { displayError } from "../helpers";
import types from './../types/authTypes';
import { activitiesClearAll } from "./activities";
import { customersClearAll } from "./customers";
import { clearAllEntries, startSaveDateFrom, startSaveDateTo } from "./entry";
import { preferencesClearAll, setDefaultDates, setDefaultPreferences } from "./preferences";
import { projectClearAll } from "./projects";
import { reportsClearAll } from "./reports";
import { usersClearAll } from "./users";
import { hourlyAnalisysClearAll } from "./hourlyAnalysis";
import { entryRecordClearAll } from "./entryRecord";
import { openSnackbar } from "../../helpers/helpers";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";


const controller = 'auth';
export const startLogin = (email, password, view) => {
    return async (dispatch) => {
  
        let body;
        try{
            const res = await fetchWithoutToken(`${controller}/login`, { email, password }, 'POST');
            body = await res.json();
        }catch{ }
      
        view.setLoading(false);
        if(body?.succeeded) {
            localStorage.setItem('token', body.data.token);
            localStorage.setItem('token-init-date', new Date().toTimeString());

            if(body.data.preferences.dateFrom) dispatch(startSaveDateFrom(moment(body.data.preferences.dateFrom)));
            if(body.data.preferences.dateTo) dispatch(startSaveDateTo(moment(body.data.preferences.dateTo)));
            dispatch(setDefaultPreferences(body.data.preferences))

            dispatch(login({
                email: body.data.email,
                roles: body.data.roles,
                fullName: body.data.fullName,
                organization: body.data.organizationName
            }));

        } else {
            displayError(body, view.enqueueSnackbar);
        }
    }
}

export const startSignIn = (user, view) => {
    return async (dispatch) => {
  
        let body;
        try{
            const res = await fetchWithoutToken(`${controller}/sign-in`, user, 'POST');
            body = await res.json();
        }catch{ }
      
        view.setLoading(false);
        if(body?.succeeded) {
            localStorage.setItem('token', body.data.token);
            localStorage.setItem('token-init-date', new Date().toTimeString());

            if(body.data.preferences.dateFrom) dispatch(startSaveDateFrom(moment(body.data.preferences.dateFrom)));
            if(body.data.preferences.dateTo) dispatch(startSaveDateTo(moment(body.data.preferences.dateTo)));
            dispatch(setDefaultPreferences(body.data.preferences))

            dispatch(login({
                email: body.data.email,
                roles: body.data.roles,
                fullName: body.data.fullName,
                organization: body.data.organizationName
            }));
            openSnackbar(view.enqueueSnackbar, 'Organization successfully created', 'success');


        } else {
            displayError(body, view.enqueueSnackbar);
        }
    }
}


export const startChekingToken = () => {

    return async(dispatch) => {
        try{
            const res = await fetchWithToken(`${controller}/renew`, {}, 'POST');
            const body = await res.json();
        
            if(body.succeeded) {
                localStorage.setItem('token', body.data.token);
                localStorage.setItem('token-init-date', new Date().toTimeString());
    
                if(body.data.preferences.dateFrom) dispatch(startSaveDateFrom(moment(body.data.preferences.dateFrom)));
                if(body.data.preferences.dateTo) dispatch(startSaveDateTo(moment(body.data.preferences.dateTo)));
                
                dispatch(setDefaultPreferences(body.data.preferences))
                
                dispatch(login({
                    userName: body.data.userName,
                    email: body.data.email,
                    roles: body.data.roles,
                    fullName: body.data.fullName,
                    organization: body.data.organizationName
                }));
            }else{
                localStorage.removeItem('token');
                localStorage.removeItem('token-init-date');
                dispatch(finishCheking());
            }
        }catch {
            dispatch(finishCheking());
        }
       
    }
   
}

export const login = (user) => ({
    type: types.authLogin,
    payload: user
})

export const startLogout = () => {
    return (dispatch) => {
        localStorage.removeItem('token');
        localStorage.removeItem('token-init-date');
        dispatch(clearAllEntries());
        dispatch(projectClearAll());
        dispatch(activitiesClearAll());
        dispatch(customersClearAll());
        dispatch(reportsClearAll());
        dispatch(usersClearAll());
        dispatch(logout());
    
        //preferences
        dispatch(preferencesClearAll());
        //hourlyAnalysis
        dispatch(hourlyAnalisysClearAll());
        //entryRecord
        dispatch(entryRecordClearAll());
      
    }
}

const logout = () => ({
    type: types.authLogout
})

export const finishCheking = () => ({
    type: types.authCheckingFinish
});



export const starRecoverPassword = async (email, view) => {
  
    const res = await fetchWithoutToken(`${controller}/recover-password`, {email}, 'POST');
    const body = await res.json();
    view.setLoading(false);

    view.success();
   

    // openSnackbar(view.enqueueSnackbar, 'An email has been sent to recover your password.', 'success');
}

export const startResetPassword = async (email, password, token, view) => {
    
    const res = await fetchWithoutToken(`${controller}/reset-password`, {email, password, token}, 'POST');
    const body = await res.json();
    view.setLoading(false);
    
    if (body.succeeded) {
    
        view.success();
    } else {
        openSnackbar(view.enqueueSnackbar, body.message, 'error');
    }

    
}
