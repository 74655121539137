

const projectsTypes = {
    projectsSetProjects: '[projects] Set projects',
    projectsAddProject: '[projects] Add project',
    
    projectsDeleteProject: '[projects] Delete project',
    projectsUpdateProject: '[projects] Update project',

    projectsSetActive: '[projects] Set active',
    projectsSetActiveDetail: '[projects] Set active detail',

    projectsUpdateUserProjectRole: '[projects] Update user project role',
    projectsRemoveUser:'[projects] Remove user',

    projectsSetUserProjects: '[projects] Set user projects',
    projectClearAll: '[projects] Clear all'
}

export default projectsTypes;