import React from 'react'
import logo from './../../assets/logo-black.svg';


export const AppLoading = () => {
    return (
        <div className="appLoading__container">
            {/* <img src={logo} className="appLoading__logo" alt="logo" /> */}
            <div className="lds-facebook"><div></div><div></div><div></div></div>
        </div>
    )
}
