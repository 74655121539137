import { useEffect, useState } from "react"


export const useGetTimeDiff = (initialState = {}) => {

    const [startTime, setStartTime] = useState(initialState);

  
    const [diferencia, setDiferencia] = useState('');

    const [currentInterval, setCurrentInterval] = useState();


    useEffect(() => {
        if(startTime){
            const ahora = new Date();
            const fechaInicio = new Date(startTime);

            var diferenciaEnMilisegundos = ahora - fechaInicio;

            // Convierte la diferencia en horas, minutos y segundos
            var segundos = Math.floor(diferenciaEnMilisegundos / 1000) % 60;
            var minutos = Math.floor(diferenciaEnMilisegundos / (1000 * 60)) % 60;
            var horas = Math.floor(diferenciaEnMilisegundos / (1000 * 60 * 60));
            
    

            // Formatea el resultado
            var diferenciaFormateada = horas.toString().padStart(2, '0') + ':' +
                                      minutos.toString().padStart(2, '0') + ':' +
                                      segundos.toString().padStart(2, '0');

            setDiferencia(diferenciaFormateada);

            const intervalo = setInterval(() => {
                setCurrentInterval(intervalo);
                const ahora = new Date();
                const fechaInicio = new Date(startTime);
    
                var diferenciaEnMilisegundos = ahora - fechaInicio;
    
                // Convierte la diferencia en horas, minutos y segundos
                var segundos = Math.floor(diferenciaEnMilisegundos / 1000) % 60;
                var minutos = Math.floor(diferenciaEnMilisegundos / (1000 * 60)) % 60;
                var horas = Math.floor(diferenciaEnMilisegundos / (1000 * 60 * 60));
                
                // Formatea el resultado
                var diferenciaFormateada = horas.toString().padStart(2, '0') + ':' +
                                          minutos.toString().padStart(2, '0') + ':' +
                                          segundos.toString().padStart(2, '0');
    
                setDiferencia(diferenciaFormateada);
    
            }, 1000);
    
            return () => clearInterval(intervalo);
        } else {
            setDiferencia('');
        }
    }, [startTime]);


    const reset = (newFormState = initialState) => {
        setStartTime(newFormState);
    }
    
    const addChar = (value) => {
        if(value < 10){
            return '0'+value;
        }
        return value;
    }

    const stop = () => {
        clearInterval(currentInterval);
    }

    return { diferencia, reset, stop } ;

}