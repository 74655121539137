import { Add } from "@mui/icons-material";
import { Button, ButtonBase } from "@mui/material";
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { startGetUsers } from "../../redux/actions/users";
import UsersList from "./UsersList";


export const UsersScreen = () => {

    const dispatch = useDispatch();

    const navigate = useNavigate();



    const handleAddUser = () => {
        navigate('/invitations');
    }

    useEffect(()=>{
   
    },[]);

    return (

        <>
            <div className="container mt-2">
                <div className="page-header">
                    <h2>Users</h2>

                    <button className="mb btn-green" onClick={handleAddUser} >Invite User</button>
                </div>

                <div>
                    <UsersList />
                </div>
            </div>


        </>
    )
}