import preferencesTypes from "../types/preferencesTypes";

const initialState = {
    dateFrom: '',
    dateTo: '',
    defaultPreferences: null
}

const preferencesReducer = (state = initialState, action) => {
    switch (action.type) {
        case preferencesTypes.setDefaultDates:
            return {
                ...state,
                dateFrom: action.payload.dateFrom,
                dateTo: action.payload.dateTo
            }

        case preferencesTypes.setDefaultPreferences:
            return {
                ...state,
                defaultPreferences: {
                    defaultProjectID: action.payload.defaultProjectID,
                    defaultActivityID: action.payload.defaultActivityID,
                    defaultTimeStart: action.payload.defaultTimeStart,
                    defaultTimeFinish: action.payload.defaultTimeFinish,
                    minutesRounding: action.payload.minutesRounding
                },
                dateFrom: action.payload.dateFrom,
                dateTo: action.payload.dateTo
            }

        case preferencesTypes.preferencesClearAll:
            return initialState;
        default:
            return state;
    }
}


export default preferencesReducer;