import { openSnackbar } from "../../helpers/helpers";
import invitationsTypes from "../types/invitationsTypes";
import { fetchWithToken, fetchWithoutToken } from '../../helpers/fetch';
import { setDefaultPreferences } from "./preferences";
import { startSaveDateFrom, startSaveDateTo } from "./entry";
import { login } from "./auth";
import moment from "moment";

const controller = 'invitations';

export const startSendInvitation = (invitation, view) => {
    return async (dispatch) => {

        const res = await fetchWithToken(`${controller}/send-invitation`, invitation, 'POST');
        const body = await res.json();
        view?.setLoading(false);

        if (body.succeeded) {
            openSnackbar(view.enqueueSnackbar, 'Invitation email has been sent', 'success');
            dispatch(addInvitation(body.data));
        } else {
            alert(body.message);
        }
    }
}

export const startCompleteInvitation = (user, view) => {
    return async (dispatch) => {

        const res = await fetchWithoutToken(`${controller}/complete-invitation`, user, 'POST');
        const body = await res.json();
        view?.setLoading(false);

        if (body.succeeded) {
            openSnackbar(view.enqueueSnackbar, 'Account successfully created', 'success');
            localStorage.setItem('token', body.data.token);
            localStorage.setItem('token-init-date', new Date().toTimeString());

            if(body.data.preferences.dateFrom) dispatch(startSaveDateFrom(moment(body.data.preferences.dateFrom)));
            if(body.data.preferences.dateTo) dispatch(startSaveDateTo(moment(body.data.preferences.dateTo)));
            dispatch(setDefaultPreferences(body.data.preferences))

            dispatch(login({
                email: body.data.email,
                roles: body.data.roles,
                fullName: body.data.fullName
            }));
        } else {
            alert(body.message);
        }
    }
}



export const startReSendInvitation = (invitationID, view) => {
    return async (dispatch) => {

        const res = await fetchWithToken(`${controller}/resend-invitation/${invitationID}`, {}, 'GET');
        const body = await res.json();
        view?.setLoading(false);

        if (body.succeeded) {
            openSnackbar(view.enqueueSnackbar, 'Email has been resent', 'success');

        } else {
            alert(body.message);
        }
    }
}

export const startDeleteInvitation = (invitationID, view) => {
    return async (dispatch) => {

        const res = await fetchWithToken(`${controller}/delete-invitation/${invitationID}`, {}, 'GET');
        const body = await res.json();
        view?.setLoading(false);

        if (body.succeeded) {
            openSnackbar(view.enqueueSnackbar, 'Invitation was successfully deleted', 'success');
            dispatch(deleteInvitation(invitationID));
        } else {
            alert(body.message);
        }
    }
}

export const startGetInvitations = (view) => {
    return async (dispatch) => {

        const res = await fetchWithToken(`${controller}/get-invitations`, {}, 'GET');
        const body = await res.json();
        view?.setLoading(false);

        if (body.succeeded) {
            dispatch(setInvitations(body.data));
        } else {
            alert(body.message);
        }
    }
}

export const startValidateInvitation = async (token) => {
  
        const res = await fetchWithoutToken(`${controller}/get-invitation/${token}`, {}, 'GET');
        const body = await res.json();

        if (body.succeeded) {
            return body.data;
        } else {
            return null;
        }
}



const addInvitation = (invitation) => ({
    type: invitationsTypes.invitationsAdd,
    payload: invitation
});

const deleteInvitation = (invitationID) => ({
    type: invitationsTypes.invitationsDelete,
    payload: invitationID
});
 
const setInvitations = (invitations) => ({
    type: invitationsTypes.invitationsSetItems,
    payload: invitations
});
