import reportsTypes from '../types/reportsTypes';

const initialState = {
    reportByCustomerResults: null,
    reportByProjectResults: null,
    reportByUserResults: null,
    reportByUserFile: null,
    reportByProjectFile: null,
    reportByCustomerFile: null,

    loadingPreview: false,
    loadingPDF: false
}

const reportsReducer = (state = initialState, action) => {
    switch (action.type) {

        case reportsTypes.reportsSetGroupByCustomerResults:
            return {
                ...state,
                reportByUserResults:null,
                reportByProjectResults:null,
                reportByCustomerResults: action.payload ? { ...action.payload } : null
            }

        case reportsTypes.reportsSetGroupByProjectResults:
            return {
                ...state,
                reportByUserResults:null,
                reportByCustomerResults:null,
                reportByProjectResults: action.payload ? { ...action.payload } : null
            }

        case reportsTypes.reportsSetGroupByUserResults:
            return {
                ...state,
                reportByProjectResults:null,
                reportByCustomerResults:null,
                reportByUserResults: action.payload ? { ...action.payload } : null
            }

         //set reports file
        case reportsTypes.reportSetResultByUserFile: 
            return {
                ...state,
                reportByUserFile: action.payload
            }
        case reportsTypes.reportSetResultByProjectFile: 
            return {
                ...state,
                reportByProjectFile: action.payload
            }
        case reportsTypes.reportSetResultByCustomerFile: 
            return {
                ...state,
                reportByCustomerFile: action.payload
            }
        //end reports file

        case reportsTypes.reprotsLoadingPreview:
            return {
                ...state,
                loadingPreview: action.payload
            }


        case reportsTypes.reprotsLoadingPDF:
            return {
                ...state,
                loadingPDF: action.payload
            }

        case reportsTypes.reportUsers: {
            return {
                ...state,
                reportUsers: action.payload
            }
        }

        case reportsTypes.reportsClearAll:
            return initialState

        default:
            return state;
    }
}

export default reportsReducer;