import { ArrowBack } from '@mui/icons-material';
import { Button, ButtonBase, IconButton, Paper, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from '../../../hooks/useForm';
import { startGetCustomers } from '../../../redux/actions/customers';
import { startCreateProject, startGetProject, startUpdateProject } from '../../../redux/actions/projects';

const UpdateProject = () => {

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const { customers } = useSelector(state => state.customers);

  const { active } = useSelector(state => state.projects);

  const params = useParams();

  useEffect(() => {
    dispatch(startGetCustomers());
  }, [])


  const [formValues, handleInputChange, reset] = useForm({
    name: '',
    customerID: -1
  });

  const { name, customerID } = formValues;


  const handleOnSubmit = (e) => {
    e.preventDefault();
    setLoadingUpdate(true);
    dispatch(startUpdateProject({ ...formValues, id: active.id }, { resetForm: reset, enqueueSnackbar, setLoading: setLoadingUpdate }));
  }

  useEffect(() => {
    var { id } = params;
    if (active && active?.id == id) {
      setLoading(false);
      reset({
        name: active.name,
        customerID: active.customer.id
      });
    } else {
      if (!id) {
        setLoading(false);
        navigate('/projects');
      } else {
        dispatch(startGetProject(id, { setLoading }))
      }
    }
  }, [active]);

  const navigateBack = () => {
    navigate(`/project-detail/${active.id}`);
  }

  return (
    <div className="container mt-2">

      <div className="page-header">
        <div className="d-flex align-items-center">
          <Tooltip onClick={navigateBack}>
            <IconButton>
              <ArrowBack />
            </IconButton>
          </Tooltip>
          <h2 className="ms-2">Edit Project</h2>
        </div>
      </div>


      <div className="row">
        {
          loading ?
            <>
              <h1>Loading...</h1>
            </> :
            <>
              <div className="col-md-6">
                <form onSubmit={handleOnSubmit} className="default-form">
                  <div>
                    <label>Project Name</label>
                    <input type="text" value={name} name="name" onChange={handleInputChange} className="mi w-100 mt-2" />
                  </div>


                  <div className="mt-3">
                    <label>Customer</label>

                    <select className="mi mt-2" value={customerID} name="customerID" onChange={handleInputChange}>
                      <option value="-1"></option>
                      {
                        customers?.map(c => (
                          <option value={c.id} key={c.id}>{c.name}</option>
                        ))
                      }
                    </select>
                  </div>

                  <div className="mt-3 d-flex justify-content-end">
                    <button className="mb btn-blue" disabled={loadingUpdate}>Save Changes</button>
                  </div>

                </form>
              </div>
            </>
        }

      </div>

    </div>

  )
}

export default UpdateProject;