import React, { useEffect, useState } from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from "recharts";
import { useHoursHelper } from "../../hooks/useHoursHelper";



const getIntroOfPage = (label) => {

    return "test";
};

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="label">{`${label} : ${payload[0].value?.toFixed(2)}`}</p>
                {/* <p className="intro">Projects</p>
                <p className="desc">test</p> */}
            </div>
        );
    }

    return null;
};

export const CustomersChart = ({ customers }) => {

    const [data, setData] = useState([]);

    const { convertHoursToDecimal } = useHoursHelper();

    const [showChart, setShowChart] = useState(false);

    useEffect(() => {
        const customersFormated = [];
        customers.map(c => {
            customersFormated.push({ customerName: c.customerName, totalHours: convertHoursToDecimal(c.totalHours) });
        })
        setData(customersFormated)
        setShowChart(true);
    }, [customers])

    return (
        showChart ?
            <ResponsiveContainer height={400} width='100%'>

                <BarChart

                    data={data}

                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="customerName" />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend
                        payload={[
                            { value: "Hours by Customer", type: "rect", color: "#8884d8" }, // Cambia el texto aquí
                        ]}
                    />
                    <Bar dataKey="totalHours" barSize={20} fill="#8884d8" />
                </BarChart>
            </ResponsiveContainer> : <></>
    );
}
