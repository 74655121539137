import hourlyAnalisysTypes from "../types/hourlyAnalysis";
import { fetchWithToken } from "../../helpers/fetch";
import { displayError } from "../helpers";

const controller = 'hourlyAnalysis';



export const startGetInitialData = (dateFrom, dateTo, view) => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/get-initial-data?dateFrom=${dateFrom}&dateTo=${dateTo}`, null, 'GET');
        const body = await res.json();
        view?.setLoading(false);
        if (body.succeeded) {
            dispatch(setInitialData(body.data));
        } else {
            displayError(body, view.enqueueSnackbar);
        }
    }
}



export const setInitialData = (data) => ({
    type: hourlyAnalisysTypes.haSetInitialData,
    payload: data
})

export const hourlyAnalisysClearAll = () => ({
    type: hourlyAnalisysTypes.hourlyAnalisysClearAll
})