import customersTypes from '../types/customersTypes';

const initialState = {
    customers: [],
    userCustomers: [],
    active: null
}

const customersReducer = (state = initialState, action) => {
    switch (action.type) {

        case customersTypes.customersSetCustomers:
            return {
                ...state,
                customers: [...action.payload]
            }


        case customersTypes.customersAddCustomer:
            return {
                ...state,
                customers: [{ ...action.payload }, ...state.customers]
            }

        case customersTypes.customersUpdateCustomer:
            return {
                ...state,
                customers: state.customers.map(a => {
                    return a.id == action.payload.id ? { ...action.payload } : a
                })
            }

        case customersTypes.customersDeleteCustomer:
            return {
                ...state,
                customers: state.customers.filter(a => a.id !== action.payload)
            }

        case customersTypes.customersSetActive:
            return {
                ...state,
                active: action.payload
            }

        case customersTypes.customersSetUserCustomers:
            return {
                ...state,
                userCustomers: action.payload
            }

        case customersTypes.customersClearAll:
            return initialState

        default:
            return state;
    }
}

export default customersReducer;