import entryRecordTypes from "../types/entryRecord";

const initialState = {
    currentRecord: null
}

const entryRecordReducer = (state = initialState, action) => {
    switch (action.type) {

        case entryRecordTypes.setCurrentRecord:
            return {
                ...state,
                currentRecord: action.payload
            }

        case entryRecordTypes.entryRecordClearAll:
            return initialState
        default:
            return state;
    }
}

export default entryRecordReducer;