import { Tooltip } from "recharts";
import { InvitationsList } from "./InvitationsList";
import { SendInvitation } from "./SendInvitation";
import { IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";


const InvitationsScreen = () => {

  const navigate = useNavigate();

  const navigateBack = () => {
    navigate("/users");
  }

  return (
    <div className="container mt-2">

      <div className="page-header">
          <h2 className="ms-2">Invite new member</h2>
      </div>

      <div className="row">
        <div className="col-12 col-sm-3">
          <SendInvitation />
        </div>
        <div className="col-12 col-sm-9">
          <InvitationsList />
        </div>
      </div>

    </div>
  )
}

export default InvitationsScreen;