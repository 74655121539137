import { IconButton, Paper, Skeleton, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startGetEntriesBetween } from "../../../redux/actions/entry";
import EntriesTableItem from "./EntriesTableItem";
import { HistoryToggleOff, PlayArrow } from "@mui/icons-material";


const EntriesTable = () => {

    const dispatch = useDispatch();

    const { entries } = useSelector(state => state.entry);

    const { currentDateFrom, currentDateTo } = useSelector(state => state.entry);

    const { dateTo, dateFrom } = useSelector(state => state.preferences);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (currentDateFrom && currentDateTo) {
            loadEntries();
        }
        else setLoading(false);
    }, [currentDateFrom, currentDateTo])

    const loadEntries = () => {
        if (currentDateFrom && currentDateTo) {
            setLoading(true);
            dispatch(startGetEntriesBetween(currentDateFrom?.format("YYYY-MM-DD HH:mm:ss"), currentDateTo?.format("YYYY-MM-DD HH:mm:ss"), { setLoading }));
        } else {
            setLoading(false);
        }
    }

    return (
        <>
            {
                !loading ?
                    <>
                        {
                            entries?.length > 0 ?
                                <div className="entriesTable__container entries-scrollbar">

                                    <table className="table entriesTable_table table-responsive">
                                        <thead>
                                            <tr>
                                                <th scope="col">Date</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Project</th>
                                                <th scope="col">In - Out</th>
                                                <th scope="col">hh:mm</th>

                                                <th>
{/* 
                                                    <Tooltip title="Download data">
                                                        <IconButton className="entriesHeader__icon-btn" size="small">
                                                            <PlayArrow />
                                                        </IconButton>
                                                    </Tooltip>
    */}

                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                entries?.map((entry, index) => {
                                                    return (
                                                        <EntriesTableItem
                                                            key={entry.id}
                                                            entries={entries}
                                                            entry={entry}
                                                            lastEntry={entries[index - 1]}
                                                            nextEntry={entries[index + 1]}
                                                            groupByDays={false}
                                                        />
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <>

                                    <div className="no-entries ">
                                        <HistoryToggleOff className="no-entries-icon" />
                                        <h2>No entries between selected dates</h2>
                                    </div>
                                </>
                        }
                    </> :
                    <>
                        <Box>
                            {/* <Skeleton /> */}
                            <Skeleton variant="rectangular" className="mb-2" animation="wave" />
                            <Skeleton variant="rectangular" className="mb-2" animation="wave" />
                            <Skeleton variant="rectangular" className="mb-2" animation="wave" />
                            <Skeleton variant="rectangular" className="mb-2" animation="wave" />
                            <Skeleton variant="rectangular" className="mb-2" animation="wave" />
                            <Skeleton variant="rectangular" className="mb-2" animation="wave" />
                            <Skeleton variant="rectangular" className="mb-2" animation="wave" />
                            <Skeleton variant="rectangular" className="mb-2" animation="wave" />
                            <Skeleton variant="rectangular" className="mb-2" animation="wave" />
                            <Skeleton variant="rectangular" className="mb-2" animation="wave" />
                            <Skeleton variant="rectangular" className="mb-2" animation="wave" />
                            <Skeleton variant="rectangular" className="mb-2" animation="wave" />
                            <Skeleton variant="rectangular" className="mb-2" animation="wave" />
                            <Skeleton variant="rectangular" className="mb-2" animation="wave" />
                        </Box>
                    </>

            }

        </>
    )
}

export default EntriesTable;


{/* <thead className="entries-table-thead">
                        <tr>
                            <th scope="col">Date</th>
                            <th scope="col">In - Out</th>
                            <th scope="col">Description</th>
                            <th scope="col">Project</th>
                            <th scope="col">h:m</th>

                            <th></th>
                        </tr>
                    </thead> */}