import { ArrowBack, ExitToApp } from '@mui/icons-material'
import { CircularProgress, FormControl, IconButton, MenuItem, Paper, Select, Tooltip } from '@mui/material'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { removeProjectFromActiveUser, startGetUser } from '../../../redux/actions/users'
import moment from 'moment/moment'
import { startChangeRole, startGetUserProjects, startGetUserProjectsByUserID, startRemoveUser } from '../../../redux/actions/projects'
import { useSnackbar } from 'notistack'

export const UserDetails = () => {

  const { active: activeUser } = useSelector(state => state.users)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const [loadingUser, setLoadingUser] = useState(true);

  useEffect(() => {
    const { id } = params;
    dispatch(startGetUser(id, { setLoadingUser }));
  }, []);

  useEffect(() => {
    // console.log(activeUser);
  }, [activeUser])

  const navigateBack = () => {
    navigate("/users");
  }


  return (
    <div className="container mt-2">

      <div className="page-header">
        <div className="d-flex align-items-center">
          <Tooltip onClick={navigateBack}>
            <IconButton>
              <ArrowBack />
            </IconButton>
          </Tooltip>
          <h2 className="ms-2">User Details</h2>
        </div>
      </div>

      {loadingUser ?
        <UserLoadingSpinner />
        :
        <>
          {activeUser ?
            <>
              <h2 className="user-fullName">{activeUser.fullName}</h2>

              <div className="row">
                <div className="row col-12 col-md-6">
                  <div>
                    <h3 className="user-section-title">Info</h3>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="d-flex flex-column mb-3">
                      <span className="user-prop-name">Email</span>
                      <span> {activeUser.email} </span>
                    </div>

                    <div className="d-flex flex-column mb-3">
                      <span className="user-prop-name">UserName</span>
                      <span> {activeUser.userName} </span>
                    </div>


                    <div className="d-flex flex-column mb-3">
                      <span className="user-prop-name">Phone</span>
                      <span> {activeUser.phoneNumber} </span>
                    </div>

                    <div className="d-flex flex-column mb-2">
                      <span className="user-prop-name">Roles</span>
                      <span> {activeUser.roles.join(", ")} </span>
                    </div>
                  </div>
                </div>


                <div className="col-12 col-md-6">
                  <div>
                    <h3 className="user-section-title">User Projects</h3>
                  </div>

                  <UserProjects user={activeUser} />
                </div>
              </div>

            </>
            :
            <UserNotFound />
          }
        </>
      }


    </div>
  )
}

const UserLoadingSpinner = () => {
  return (
    <div className="d-flex justify-content-center">
      <CircularProgress />
    </div>
  )
}

const UserNotFound = () => {
  return (
    <div className="no-user-found">
      <h2>No entries between selected dates</h2>
    </div>
  )
}

const UserProjects = ({ user }) => {

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleRemoveUser = (projectID) => {
    const res = window.confirm('Sure?');
    if (res) {
      dispatch(startRemoveUser(projectID, user.id, { enqueueSnackbar, success: userRemovedFromProject }))
    }
  }

  const userRemovedFromProject = (projectID, userID) => {
    dispatch(removeProjectFromActiveUser(projectID));
  }

  return (
    <>
      <h2></h2>

      <div className="d-flex flex-column">
        {
          user.userProjects?.length > 0 ?
            user.userProjects.map((up, index) => {
              return (
                <ProjectRole key={up.projectID} up={up} handleRemoveUser={handleRemoveUser} />
              )
            }) :
            <span>The user does not belong to any project</span>
        }
      </div>
    </>
  );
}

const ProjectRole = ({ up, handleRemoveUser }) => {

  const roles = [{ value: 0, name: 'Member' }, { value: 1, name: 'Project Manager' }];

  const [userRoleInProject, setUserRoleInProject] = useState(up.userRoleInProject);
  const [initialUserRoleInProject, setInitialUserRoleInProject] = useState(up.userRoleInProject);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const handleInputChange = (e) => {
    setUserRoleInProject(e.target.value)

    const changeRole = {
      projectID: up.projectID,
      userToChangeID: up.userID,
      userRoleInProject: Number(e.target.value)
    };
    setLoading(true);
    dispatch(startChangeRole(changeRole, { enqueueSnackbar, setLoading, success, error }));
  }

  const success = (changeRole) => {

  }

  const error = () => {
    setUserRoleInProject(initialUserRoleInProject);
  }

  return (
    <div className="user-project-container">
      <div>
        <span>{up.projectName}</span>
      </div>
      <div className="d-flex align-items-center">

        <FormControl sx={{ m: 1, minWidth: 180 }} className="fs-12" variant="standard" size="small">
          <Select
            className="fs-14"
            value={userRoleInProject}
            onChange={handleInputChange}
          >
            <MenuItem disabled value={-1}>
              <em>Role</em>
            </MenuItem>
            {
              roles.map((c) => <MenuItem
                disabled={loading}
                key={c.value}
                value={c.value}
              >{c.name}</MenuItem>)
            }
          </Select>
        </FormControl>
        <Tooltip title="Remove from project">
          <IconButton aria-label="remove" onClick={(e) => handleRemoveUser(up.projectID)}>
            <ExitToApp />
          </IconButton>
        </Tooltip>
      </div>

    </div>

  )
}


