import { Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { sortByHours, sumEntriesHours } from "../../../helpers/helpers";
import { filterByCustomers } from "../../../redux/actions/entry";
import Duration from "../../formControls/Duration";


export const EntriesCustomers = () => {

    const dispatch = useDispatch();
    const { userCustomers } = useSelector(state => state.customers);
    const { baseEntries: entries, filterByCustomersIDs } = useSelector(state => state.entry);

    const [customerEntries, setCustomerEntries] = useState([]);

    useEffect(() => {
        loadCustomerEntriesTimes();
    }, [entries, userCustomers]);

    const loadCustomerEntriesTimes = () => {

        setCustomerEntries([]);
        let ce = [];
        userCustomers?.map(c => {
            let customerEntries = entries?.filter(x => x.project.customer.id === c.id);
            let totalCustomerHours = sumEntriesHours(customerEntries);
            ce.push({
                customer: c,
                hours: totalCustomerHours === '00:00' ? null : totalCustomerHours
            });

        });

        sortByHours(ce);
        setCustomerEntries(ce);
    }

    const handleCustomerSelected = (e, customer) => {
        if (e.target.checked) {
            dispatch(filterByCustomers([...filterByCustomersIDs, customer.id]));
        } else {
            dispatch(filterByCustomers(filterByCustomersIDs.filter(x => x !== customer.id)));
        }
    }

    return (

        <div className="border-around border-radius-4px">
            <div className="footer-header">
                <h2 className="footer-title">CUSTOMERS</h2>
            </div>
            <ul className="list-group list-group-flush footer-list entries-scrollbar">
                {
                    customerEntries?.map(ce => {
                        return (
                            <li className="list-group-item d-flex justify-content-between align-items-center footer-list-item" key={ce.customer.id}>
                                <span className={ce.hours == null ? 'text-grey' : ''}>
                                    <input
                                        disabled={ce.hours == null}
                                        className="form-check-input me-1 c-checkbox-small c-checkbox"
                                        type="checkbox"
                                        onChange={(e) => handleCustomerSelected(e, ce.customer)}
                                        value={ce.customer.id}
                                        id={ce.customer.id}
                                    />
                                    <label className={'form-check-label' + (ce.hours == null ? 'text-grey' : '')} htmlFor={ce.customer.id}>{ce.customer.name}</label>
                                </span>

                                {/* <span className="badge bg-violet">{ce.hours}</span> */}
                                <span className="text-success fw-600">

                                    <Duration value={ce.hours} />

                                </span>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}



