const CustomCheckBox = ({ id, text, checked, handleChange, disabled = false }) => {

    return (
        <div className="form-check c-form-group mt-12px">
            <input className="form-check-input c-checkbox" type="checkbox" id={id} onChange={handleChange} disabled={disabled} checked={checked} />
            <label className="form-check-label c-checkbox-label" htmlFor={id} >
                {text}
            </label>
        </div>
    )
}

export default CustomCheckBox;