import { Add } from '@mui/icons-material';
import { Button, ButtonBase } from '@mui/material';
import TouchRipple from '@mui/material/ButtonBase/TouchRipple';
import React from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomersList from './CustomersList';

const CustomersScreen = () => {

  const navigate = useNavigate();


  const handleCreateCustomer = () => {
    navigate('/create-customer');
  }

  return (

    <div className="container mt-2">

      <div className="page-header">
        <h2>Customers</h2>

        <div>
       
          <button className="mb btn-green" onClick={handleCreateCustomer} >Create Customer</button>

        </div>
      </div>

      <div>
        <CustomersList />
      </div>

    </div>

  )
}

export default CustomersScreen;