import invitationsTypes from "../types/invitationsTypes";

const initialState = {
    invitations: []
}

const invitationsReducer = (state = initialState, action) => {
    switch (action.type) {

        case invitationsTypes.invitationsAdd:
            return {
                invitations:  [...state.invitations, action.payload] 
            }

        case invitationsTypes.invitationsDelete:
            return {
                invitations: state.invitations.filter(x => x.id != action.payload)
            }

        case invitationsTypes.invitationsSetItems:
            return {
                invitations: action.payload
            }

        default:
            return state;
    }
}

export default invitationsReducer;