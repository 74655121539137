import { Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Toolbar, alpha } from "@mui/material";
import { EnhancedTableHead } from "../../helpers/tableHelpers/components";
import CustomMenu from "../formControls/Menu";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { startDeleteInvitation, startGetInvitations, startReSendInvitation } from "../../redux/actions/invitations";
import { useEffect, useState } from "react";
import { getComparator, stableSort } from "../../helpers/tableHelpers/functions";


const headCells = [
    {
        id: 'email',
        numeric: false,
        disablePadding: true,
        label: 'Email',
    }
];

export const InvitationsList = () => {



    const [loading, setLoading] = useState(false);



    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('email');

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { invitations } = useSelector(state => state.invitations);

    const [invitationsToDisplay, setInvitationsToDisplay] = useState(invitations);

    useEffect(() => {
        setLoading(true);
        dispatch(startGetInvitations({setLoading, enqueueSnackbar}));
    }, []);

    useEffect(() => {
        setInvitationsToDisplay(invitations);
    }, [invitations]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilter = (e) => {
        const value = e.target.value.toLowerCase();
        if (value) {
            setInvitationsToDisplay(invitations.filter(x => x.email.toLowerCase().includes(value)));
        } else {
            setInvitationsToDisplay(invitations);
        }
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - invitations.length) : 0;

    const handleDeleteItem = (invitationID) => {
        var res = window.confirm("Sure?");
        if (res) {
            dispatch(startDeleteInvitation(invitationID, { setLoading, enqueueSnackbar }));
        }

    }

    const handleResendInvitation = (invitationID) => {
        dispatch(startReSendInvitation(invitationID, { setLoading, enqueueSnackbar }));
    }


    return (

        <div className="p-3 d-flex flex-column">
            <label>Pending Invitations</label>
            {
                invitations.length === 0 ?
                    <span className="text-secondary">No items</span>
                    :
                    <>
                        <EnhancedTableToolbar handleFilter={handleFilter} />
                        <TableContainer>
                            <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size={'medium'}
                            >
                                <EnhancedTableHead
                                    numSelected={0}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={invitations.length}
                                    headCells={headCells}
                                    haveActions={true}
                                    hideCheckbox={true}
                                />
                                <TableBody>
                                    {stableSort(invitationsToDisplay, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((invitation, index) => {
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    hover
                                                    tabIndex={-1}
                                                    key={invitation.id}
                                                >

                                                    <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        className='padding-10px'
                                                    >
                                                        {invitation.email}
                                                    </TableCell>

                                                    <TableCell className='padding-10px'>
                                                        <CustomMenu actions={[
                                                            { text: 'Resend Email', action: (event) => { handleResendInvitation(invitation.id) } },
                                                            { text: 'Delete Invitation', action: (event) => { handleDeleteItem(invitation.id) } },
                                                        ]} />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: (53) * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25]}
                            component="div"
                            count={invitations.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
            }

        </div>
    );
}

const EnhancedTableToolbar = (props) => {

    const { selectedItem, setSelectedItem, handleFilter } = props;

    return (
        <Toolbar
            className="p-0"
            sx={{

                ...(selectedItem != null && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {selectedItem != null ? (
                <></>
            ) : (
                <input className="search-input" onChange={handleFilter} placeholder="Filter..." />
            )}


        </Toolbar>
    );
}
