import { useState } from "react";


export const usePopover = (recordPopoverID) => {

    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? recordPopoverID : undefined;

    return [handleOpen, handleClose, open, anchorEl, id];
}