import React, { useEffect } from 'react'
import { Add } from '@mui/icons-material';
import { Button, ButtonBase } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ProjectsList from './ProjectsList';
import { RolesCanDisplay } from '../roleHelpers/RolesCanDisplay';

const roleManagerAdmin = ['Admin', 'Manager'];


const ProjectsSceen = () => {

  const navigate = useNavigate();

  const handleCreateProject = () => {
    navigate('/create-project');
  }


  return (
    <div className="container mt-2">

      <div className="page-header">
        <h2>Projects</h2>

        <div>
          <RolesCanDisplay roles={roleManagerAdmin}>

            <button className="mb btn-green" onClick={handleCreateProject} >Create Project</button>

          </RolesCanDisplay>

        </div>
      </div>

      <div>
        <ProjectsList />
      </div>


    </div>
  )
}

export default ProjectsSceen;