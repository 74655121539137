


import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { Tooltip } from '@mui/material';

const options = [{text: 'Generate PDF file', type: 'PDF'}, {text: 'Generate CSV file', type: 'CSV'}];

const ButtonGenerateReport = ({ generateFile, loading }) => {

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const handleClick = () => {
        // console.info(`You clicked ${options[selectedIndex]}`);
        generateFile(options[selectedIndex].type);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };


    //   <Button className="w-100" variant="contained" color="secondary" disabled={true}>GENERATE REPORT</Button>

    return (
        <React.Fragment>
                <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button" className="w-100">

                    {/* <Button disabled={loading} onClick={handleClick} className="flex-1"> {options[selectedIndex].text}</Button> */}
                    <button className="mb btn-blue flex-1 btn-group-1" disabled={loading} onClick={handleClick}>{options[selectedIndex].text}</button>


                    <button className="mb btn-blue btn-group-2 p-0 px-2" disabled={loading} onClick={handleToggle} 
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    ><ArrowDropDownIcon style={{margin: '0px'}}/></button>


                </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={option.type}
                                            disabled={index === 1}
                                            selected={index === selectedIndex}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                        >
                                            {option.type}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
}

export default ButtonGenerateReport;