import { Add, PlayArrow } from "@mui/icons-material";
import { Fab, FormControl, IconButton, MenuItem, Paper, Popover, Select, TextField, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { calculateHoursDiff, openSnackbar } from "../../helpers/helpers";
import { useForm } from "../../hooks/useForm";
import { startAddEntry } from "../../redux/actions/entry";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { useSnackbar } from 'notistack';
import autosize from "autosize";
import { RecordEntry } from "../recordEntry/RecordEntry";
import Duration from "../formControls/Duration";

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 200,
        },
    },
};

const AddHours = () => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { userProjects } = useSelector(state => state.projects);
    const { activities } = useSelector(state => state.activities);
    const { defaultPreferences } = useSelector(state => state.preferences);

    const [duration, setDuration] = useState('00:00');

    const [loading, setLoading] = useState(false);


    const [formValues, handleInputChange, reset] = useForm({
        timeStart: '',
        timeFinish: '',
        projectID: -1,
        activityID: -1,
        description: '',
        date: moment().format("YYYY-MM-DD")
    });

    const {
        timeStart,
        timeFinish,
        projectID,
        activityID,
        description,
        date } = formValues;

    useEffect(() => {
        calculateDuration();
    }, [timeStart, timeFinish])

    const textareaEl = useRef()

    useEffect(() => {
        textareaEl.current.focus();
      }, []);
      
    useEffect(() => {
        autosize(textareaEl.current);
    }, [description])



    useEffect(() => {
        if (defaultPreferences) {
            handlePreferences();
        }
    }, [defaultPreferences, userProjects, activities])



    const handleSubmit = (e) => {
        if (formValues.activityID == -1) {
            openSnackbar(enqueueSnackbar, 'Select activity', 'error');
            return;
        }
        if (formValues.projectID == -1) {
            openSnackbar(enqueueSnackbar, 'Select project', 'error');
            return;
        }
        setLoading(true);
        e.preventDefault();
        dispatch(startAddEntry(formValues, { reset: resetForm, enqueueSnackbar, setLoading }));
    }

    const resetForm = () => {
        handleInputChange({
            target: {
                name: 'description',
                value: ''
            }
        })

        setTimeout(() => {
            textareaEl.current.style.height = '25px';
            autosize(textareaEl.current);
        }, 0)
    }

    const calculateDuration = () => {
        if (timeStart && timeFinish) {
            setDuration(calculateHoursDiff(timeStart, timeFinish));
        }
    }

    const handleChange = (newValue) => {
        handleInputChange({
            target: {
                name: 'date',
                value: newValue
            }
        })
    };

    const style = {
        minHeight: '24px',
        resize: 'none',
        boxSizing: 'border-box'
    };

    const handlePreferences = () => {

        const { defaultProjectID, defaultActivityID, defaultTimeStart, defaultTimeFinish } = defaultPreferences;

        let newValues = {
            projectID: '-1',
            activityID: '-1',
            timeStart: defaultTimeStart || '',
            timeFinish: defaultTimeFinish || '',
            description: '',
            date: moment().format("YYYY-MM-DD")
        }
        if (userProjects?.length > 0 && defaultProjectID != '-1') {
            newValues.projectID = defaultProjectID;
        }

        if (activities?.length > 0 && defaultActivityID != '-1') {
            newValues.activityID = defaultActivityID;
        }

        reset(newValues);
    }



    return (
        <>
            {/* <h1 className="entries-title">Add entry</h1> */}
            <div className="add-entry-container border-radius-4px flex-wrap border-around">

                <div className="d-flex align-items-center textarea-container flex-grow-1 pe-2">
                    {
                        !loading ?
                            <textarea
                                className="entry-description"
                                name="description"
                                style={style}
                                ref={textareaEl}
                                placeholder="What you are working on?"
                                value={description}
                                onChange={handleInputChange}
                                rows={1} /> : <span className="fake-desc">{description}</span>
                    }

                </div>

                <div className="d-flex flex-wrap add-entry-seconds-controls">
                    <div className="d-flex flex-wrap">

                        {/* <FormControl sx={{ m: 1, minWidth: 180 }} className="fs-12" variant="standard" size="small">
                            <Select
                                className="fs-14"
                                value={projectID}
                                name="projectID"
                                onChange={handleInputChange}
                                MenuProps={MenuProps}
                            >
                                <MenuItem disabled value={-1}>
                                    <em>Project</em>
                                </MenuItem>

                                {
                                    userProjects?.map(p => (
                                        <MenuItem key={p.id} value={p.id}>{p.name} ({p.customer.name})</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl> */}
                        {/* 
                        <FormControl sx={{ m: 1, minWidth: 120 }} variant="standard" size="small">
                            <Select
                                className="fs-14"
                                value={activityID}
                                name="activityID"
                                onChange={handleInputChange}
                                MenuProps={MenuProps}
                            >
                                <MenuItem disabled value={-1}>
                                    <em>Activity</em>
                                </MenuItem>
                                {
                                    activities?.map(a => (
                                        <MenuItem key={a.id} value={a.id}>{a.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl> */}

                        <div className="d-flex align-items-center flex-wrap" style={{ height: 42 }}>

                            <select className="mi project-select" value={projectID || -1} name="projectID" onChange={handleInputChange}>
                                <option disabled value="-1">Project</option>
                                {
                                    userProjects?.map(p => (
                                        <option key={p.id} value={p.id}>{p.name} ({p.customer.name})</option>
                                    ))
                                }
                            </select>

                            <select className="ms-2 mi activity-select" value={activityID || -1} name="activityID" onChange={handleInputChange}>
                                <option disabled value="-1">Activity</option>
                                {
                                    activities?.map(a => (
                                        <option key={a.id} value={a.id}>{a.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="d-flex align-items-center flex-wrap" style={{ height: 42 }}>


                            <div className="d-flex align-items-center ms-2 flex-wrap">
                                <input type="time" className="mi add-entry__time" name="timeStart" value={timeStart} onChange={handleInputChange} />
                                <span className="mx-1">-</span>
                                <input type="time" className="mi add-entry__time" name="timeFinish" value={timeFinish} onChange={handleInputChange} />
                            </div>

                            <div className="d-flex align-items-center ms-2">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Paper className="add-entry-paper-range-input-date" >
                                        <DesktopDatePicker
                                            className="default-input-date"
                                            inputFormat="DD/MM/YYYY"
                                            name="date"
                                            value={date}
                                            onChange={handleChange}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Paper>
                                </LocalizationProvider>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex align-items-center flex-wrap" style={{ height: 42 }}>

                        <div className="d-flex align-items-center mx-3">
                            <span className="fw-500">
                                <Duration value={duration}/>
                            </span>
                        </div>
                        <IconButton className="btn-icon-blue" color="primary" onClick={handleSubmit} disabled={loading}>
                            <Add />
                        </IconButton>
                    </div>

                </div>
            </div>


            
           
        </>
    )
}

export default AddHours;