import { Assessment, CalendarMonth, CoPresent, Dashboard, Discount, Group, Groups3, Timelapse } from '@mui/icons-material';
import { List, ListItem, ListItemIcon } from '@mui/material';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {  NavLink, useLocation, useNavigate } from 'react-router-dom';
import { startLogout } from '../../redux/actions/auth';
import { useEffect } from 'react';

const roleAdmin = ['Admin'];
const roleManager = ['Manager'];
const roleManagerAdmin = ['Admin', 'Manager'];
const roleManagerAdminProjectManager = ['Admin', 'Manager', 'ProjectManager'];


const roleCollaborator = ['Collaborator'];
const rolesManagerCollaborator = ['Manager', 'Collaborator'];

const Sidebar = () => {

    const { user } = useSelector(state => state.auth);

    const location = useLocation();

    const dispatch = useDispatch();

    const navigate = useNavigate();


    const handleLogout = () => {
        dispatch(startLogout());
    }

    const handleNavigateAccount = () => {
        handleChange();
        navigate('/account');
    }

    const handlePreferences = () => {
        handleChange();
        navigate('/preferences');
    }
    
    const [checked, setChecked] = useState(false);

    const handleChange = () => {
        setChecked( !checked );
    };

    return (
        <>
            <div className="sidebar show-lg">
                <div className='sidebar-menu'>

                    {/* <img src={logo} className="logo-1950" alt="logo" /> */}
                    {/* <h1 className="company">COMPANY</h1> */}

                    <List component="nav" className="p-0" aria-label="main mailbox folders">

                        <RolesCanView roles={roleAdmin}>
                            <ListLink path={'/dashboard'} text={'Dashboard'} icon={<Dashboard style={{fontSize: 20}} />} />
                        </RolesCanView>

                        <ListLink path={'/'} text={'Entries Sheet'} icon={<CalendarMonth style={{fontSize: 20}} />} />

                        <RolesCanView roles={roleManagerAdminProjectManager}>
                            <ListLink path={'/reports'} text={'Reports'} icon={<Assessment style={{fontSize: 20}} />} />
                        </RolesCanView>
                    
                        <RolesCanView roles={roleManagerAdminProjectManager}>
                            <ListLink path={'/hours-compliance'} text={'Hrs Compliance'} icon={<Timelapse style={{fontSize: 20}} />} />
                        </RolesCanView> 
                        
                        <RolesCanView roles={roleManagerAdmin}>
                            <ListLink path={'/users'} text={'Users'} icon={<Group style={{fontSize: 20}} />} />
                        </RolesCanView>

                        <RolesCanView roles={roleManagerAdmin}>
                            <ListLink path={'/customers'} text={'Customers'} icon={<Groups3 style={{fontSize: 20}} />} />
                        </RolesCanView>

                        <RolesCanView roles={roleManagerAdminProjectManager}>
                            <ListLink path={'/projects'} text={'Projects'} icon={<Discount style={{fontSize: 20}} />} />

                        </RolesCanView>

                        <RolesCanView roles={roleManagerAdmin}>
                            <ListLink path={'/activities'} text={'Activities'} icon={<CoPresent style={{fontSize: 20}} />} />
                        </RolesCanView>

                        {/* <RolesCanView roles={roleManagerAdminProjectManager}>
                            <ListLink path={'/preferences'} text={'preferences'} icon={<CoPresent style={{fontSize: 20}} />} />
                        </RolesCanView> */}
                    </List>
                </div>

                <div className="sidebar-footer">
                    <span className='sidebar-org-name'>{ user.organization }</span>
                    <span>{ user.roles.join(', ')}</span>
                </div>
                <div>
                {/* <div className='d-flex mb-2'>
                                <Link to="/preferences">Preferences</Link>
                            </div> */}
             
                    {/* <div className="div-account-sidebar" >
                
                        <Collapse in={checked} collapsedSize={46} className='div-collapse'>
                            <ButtonBase className="btn-account-sidebar" onClick={handleChange} variant="text" >
                                <div className='d-flex align-items-center'>
                                    <div>
                                        <AccountCircle className="account-circle-icon" />
                                    </div>
                                    <div className="d-flex flex-column align-items-start" >
                                        <span className="ms-2 account-circle-fullname">{user.fullName}</span>
                                    </div>
                                </div>
                            </ButtonBase>
                            <div>
                                <List
                                    sx={{ width: '100%' }}
                                    component="nav"
                                    aria-labelledby="nested-list-subheader"
                                >
                                    <ListItemButton onClick={handleNavigateAccount} style={{ color: 'white' }}>
                                        <ListItemIcon style={{ color: 'white' }}>
                                            <Settings />
                                        </ListItemIcon>
                                        <ListItemText primary="Settings" />
                                    </ListItemButton>
                                    <ListItemButton onClick={handlePreferences} style={{ color: 'white' }}>
                                        <ListItemIcon style={{ color: 'white' }}>
                                            <SettingsSuggest />
                                        </ListItemIcon>
                                        <ListItemText primary="Preferences" />
                                    </ListItemButton>
                                    <ListItemButton onClick={handleLogout} style={{ color: 'white' }}>
                                        <ListItemIcon style={{ color: 'white' }}>
                                            <Logout />
                                        </ListItemIcon>
                                        <ListItemText primary="Logout" />
                                    </ListItemButton>
                                </List>
                            </div>
                        </Collapse>
                    </div> */}
                </div>
            </div>

        </>
    );

}

export const RolesCanView = ({ children, roles }) => {

    const { user } = useSelector(state => state.auth)
    const canAccess = user?.roles?.some(userRole => roles.includes(userRole));

    return canAccess ? children : <></>
}

export const ListLink = ({ path, text, icon }) => {

    const location = useLocation();

    return (
        <ListItem

            component={NavLink}
            to={path}
            className="list-item"
            selected={location.pathname === path}
        >
            <ListItemIcon className='nav-link-icon' style={{ width: 14, height: '16px'  }}>
                <span className={'sidebar-icon '+(location.pathname === path ? ' active-nav-link' : '')}> {icon}</span>
            </ListItemIcon>
            <span className={'nav-link-text ' + (location.pathname === path ? 'active-nav-link' : '')}>{text}</span>
            {/* <ListItemText className='nav-link' primary={text} /> */}
        </ListItem>
    )
}
export default Sidebar;