import { Close, Difference, FilterList, Pause, PlayArrow } from "@mui/icons-material"
import { Button, Fab, IconButton, Popover, Tooltip, Typography } from "@mui/material"
import autosize from "autosize";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { calculateHoursDiff } from "../../helpers/helpers";
import { useForm } from "../../hooks/useForm";
import { startCancelRecord, startRecording, startSaveRecord } from "../../redux/actions/entryRecord";
import { useGetTimeDiff } from "../../hooks/useGetTimeDiff";
import { usePopover } from "../../hooks/usePopover";
import moment from "moment";

const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const currentTime = `${hours}:${minutes}:${seconds}`;
    return currentTime;
}

const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Se suma 1 porque los meses empiezan en 0
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const currentDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    return currentDateTime;
}

export const RecordEntry = () => {
    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [valid, setValid] = useState(false);
    const [time, setTime] = useState('');


    const { userProjects } = useSelector(state => state.projects);
    const { activities } = useSelector(state => state.activities);
    const { defaultPreferences } = useSelector(state => state.preferences);
    const { currentRecord } = useSelector(x => x.entryRecord);

    const { diferencia, reset: resetTimeDiffHook, stop } = useGetTimeDiff(currentRecord ? currentRecord.startDateAndTime : null);
    const textareaEl = useRef();

    const [formValues, handleInputChange, reset] = useForm({
        // timeStart: '',
        description: '',
        projectID: -1,
        activityID: -1
    });

    const {
        // timeStart,
        description,
        projectID,
        activityID } = formValues;


    useEffect(() => {
        autosize(textareaEl.current);
    }, [description])

    useEffect(() => {
        if (defaultPreferences && !currentRecord) {
            handlePreferences();
        }
    }, [defaultPreferences, userProjects, activities])



    //start popper manage
    const recordPopoverID = "record-popover";
    const [handleOpen, handleClose, open, anchorEl, id] = usePopover(recordPopoverID);
    //end popper manage


    const handlePreferences = () => {
        const { defaultProjectID, defaultActivityID, defaultTimeStart } = defaultPreferences;

        let newValues = {
            description: '',
            projectID: '-1',
            activityID: '-1',
            // timeStart: getCurrentTime(),
        }
        if (userProjects?.length > 0 && defaultProjectID != '-1') {
            newValues.projectID = defaultProjectID;
        }

        if (activities?.length > 0 && defaultActivityID != '-1') {
            newValues.activityID = defaultActivityID;
        }

        reset(newValues);
    }

   

    useEffect(() => {
        setValid(validEntry());
    }, [activityID, projectID, description]);

    const validEntry = () => {
        return activityID != -1 && projectID != -1;
    }

    const handleCancelRecord = () => {
        handleClose();
        resetTimeDiffHook(null);
        handlePreferences();
        dispatch(startCancelRecord());
    }

    const handleSaveRecord = () => {
        stop();
        const newEntry = {
            activityID,
            projectID,
            description,
            timeStart: time,
            timeFinish: moment().format('HH:mm:ss'),
            currentDate: moment().format('DD-MM-yyyy'),
        }

        setTime(getCurrentTime());
        setLoading(true);
        dispatch(startSaveRecord(newEntry, { setLoading, handleClose, resetForm, enqueueSnackbar }));
    }

    const [recording, setRecording] = useState(false);

    const handleStartRecording = (e) => {
        e.preventDefault();

        const timeStart = getCurrentTime();
        const datetime = getCurrentDateTime();
        const recordEntry = {
            activityID,
            projectID,
            description,
            timeStart: timeStart,
            date: moment().format('DD-MM-yyyy')
        }

        setTime(timeStart);
        handleClose();
        resetTimeDiffHook(datetime);
        setRecording(true);
        dispatch(startRecording(recordEntry, { setLoading, handleClose }));
    }

    useEffect(() => {
        resetTimeDiffHook(currentRecord ? currentRecord.startDateAndTime : null)
        if (currentRecord) {
            setTime(currentRecord.timeStart);
            var newFormValues = {
                description: currentRecord.description,
                projectID: currentRecord.project.id,
                activityID: currentRecord.activity.id
            };
            reset(newFormValues);
            setRecording(true);

        } else {
            setRecording(false);

        }
    }, [currentRecord])


    const resetForm = () => {
        setTime('');

        handlePreferences();
    }

    const handleDescriptionChange = (e) => {
        handleInputChange(e);
        if (currentRecord) {
            updateRecord({ ...formValues, description: e.target.value }, time);
        }
    }

    const handleProjectChange = (e) => {
        handleInputChange(e);
        if (currentRecord) {
            updateRecord({ ...formValues, projectID: e.target.value }, time);
        }
    }

    const handleActivityChange = (e) => {
        handleInputChange(e);
        if (currentRecord) {
            updateRecord({ ...formValues, activityID: e.target.value }, time);
        }
    }

    const handleChangeTime = (e) => {

        const valid = validTimeChange(currentRecord.startDateAndTime, e.target.value)
        if (valid) {
            setTime(e.target.value);
            if (currentRecord) {
                updateRecord({ ...formValues }, e.target.value);
            }
        } else {
            console.log("invalid time");
        }
    }

    const validTimeChange = (startDate, newTime) => {
        // Get the hours and minutes from the string
        let [hours, minutes] = newTime.split(':');

        // Create a new date with the same date and the provided time
        let dateWithTime = new Date(startDate);
        dateWithTime.setHours(hours);
        dateWithTime.setMinutes(minutes);

        // Get the current date and time
        let currentDate = new Date();

        if (dateWithTime > currentDate) {
            return false;
        }
        return true;
    }

    useEffect(()=>{
        var favicon = "";

        if(diferencia!=""){
            document.title =diferencia;
            favicon = "recording.ico";
          
        } else {
            document.title = "Time Tracker";
            favicon = "favicon.ico";
        }

        const link = document.querySelector("link[rel~='icon']");
        if (link) {
          link.href = favicon;
        } else {
          const newLink = document.createElement('link');
          newLink.rel = 'icon';
          newLink.href = favicon;
          document.head.appendChild(newLink);
        }
       

    }, [diferencia])



    const updateRecord = (values, time) => {
        values.timeStart = time;
        dispatch(startRecording(values, null));
    }


    return (
        <>

            {
                recording ?
                    <button className="mb recordEntry__currentRecord" onClick={handleOpen} >
                        <Pause />
                        <span>
                            {diferencia}
                        </span>
                    </button>
                    :
                    <Tooltip title="Record time">
                        <IconButton className="entriesHeader__icon-btn btn-icon-violet " color="secondary" onClick={handleOpen} >
                            <PlayArrow />
                        </IconButton>
                    </Tooltip>
            }

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transitionDuration={0}
            >
                <div className="default-form" >
                    <div className="recordEntry_popover-container">
                        <div className="d-flex justify-content-between align-items-center popover-header">
                            <span>Record time</span>
                            <IconButton type="button" onClick={handleClose} className="recordEntry__icon-btn" size="small" >
                                <Close />
                            </IconButton>
                        </div>
                        <div>
                            <textarea placeholder="Description" className="mi w-100 mb-3 recordEntry_teaxtarea" rows="4" name="description" value={description} onChange={handleDescriptionChange}>
                            </textarea>
                            <select className="mi w-100 project-select mb-3" value={projectID || -1} name="projectID" onChange={handleProjectChange}>
                                <option disabled value="-1">Project</option>
                                {
                                    userProjects?.map(p => (
                                        <option key={p.id} value={p.id}>{p.name} ({p.customer.name})</option>
                                    ))
                                }
                            </select>
                            <select className="mi w-100 activity-select mb-3" value={activityID || -1} name="activityID" onChange={handleActivityChange}>
                                <option disabled value="-1">Activity</option>
                                {
                                    activities?.map(a => (
                                        <option key={a.id} value={a.id}>{a.name}</option>
                                    ))
                                }
                            </select>


                            {
                                currentRecord &&
                                <div className="d-flex align-items-center justify-content-end mb-3">
                                    <label className="fs-14px">Start time</label>
                                    <input type="time" className="mi add-entry__time recordEntry__time ms-2" name="timeStart" value={time} onChange={handleChangeTime} />
                                </div>
                            }


                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <span className="fw-500"> {diferencia} </span>

                            <div className="d-flex justify-content-center align-items-center">


                                {
                                    currentRecord ?
                                        <>
                                            <button type="button" onClick={handleCancelRecord} className="mb btn-gray" disabled={loading || !valid}>
                                                Cancel
                                            </button>
                                            <button type="button" onClick={handleSaveRecord} className="mb btn-red ms-2" disabled={loading || !valid}>
                                                Stop
                                            </button>
                                        </>
                                        :
                                        <button type="button" onClick={handleStartRecording} className="mb btn-blue" disabled={loading || !valid}>
                                            Start
                                        </button>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </Popover>
        </>
    )
}