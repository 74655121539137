
const usersTypes = {
    setUsersList: '[users] Set users list',
    addUser: '[user] Add user',
    updateUser: '[user] Update user',
    setActiveUser: '[users] Set active user',
    usersClearAll: '[users] Clear all',
    removeProjectFromActiveUser: '[users] Remove project from active user',
    changeUserRoleInProjectFromActiveUser: '[users] Change user role in project from active user',
}

export default usersTypes;