const reportsType = {
    reprotsLoadingPreview: '[reports] Set loading results',
    reprotsLoadingPDF: '[reports] Set loading PDF',
    

    reportsSetGroupByProjectResults: '[reports] Set project results',
    reportsSetGroupByCustomerResults: '[reports] Set customer results',
    reportsSetGroupByUserResults: '[reports] Set users results',

    reportSetResultByUserFile: '[reports] Set result by user file',
    reportSetResultByProjectFile: '[reports] Set result by project file',
    reportSetResultByCustomerFile: '[reports] Set result by customer file',
    
    reportsClearAll: '[reports] ClearAll',

    reportUsers: ['[reports] Set users to report']
}

export default reportsType;