import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startGetEntriesBetween } from '../../redux/actions/entry';
import { startGetUserCustomers } from '../../redux/actions/customers';
import { startGetactivities } from '../../redux/actions/activities';
import { startGetUserProjects } from '../../redux/actions/projects';
import AddHours from './AddEntry';
import EntriesHeader from './EntriesHeader';
import EntriesTable from './entries/EntriesTable';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Paper, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';

const TimesSheetScreen = () => {

    const dispatch = useDispatch();

    const { entries, filterByCustomersIDs, filterByActivitiesIDs, filterByProjectsIDs } = useSelector(state => state.entry);

    const { currentDateFrom, currentDateTo } = useSelector(state => state.entry);

    useEffect(() => {
        //loadEntries();
    }, [currentDateFrom, currentDateTo])

    useEffect(() => {
        dispatch(startGetactivities());
        dispatch(startGetUserCustomers());
        dispatch(startGetUserProjects());
    }, []);

    useEffect(() => {
    }, [filterByCustomersIDs, filterByActivitiesIDs, filterByProjectsIDs]);





    return (
        <>
            <div className="times-sheet-container p-3">
                <AddHours />
                <EntriesHeader />

                {/* <div className='row mb-3'>
                    <div className='col-12 col-sm-6'>
                        <div className='c-card border-around border-radius-4px p-3'>
                            <h1>add hours</h1>

                        </div>
                    </div>
                    <div className='col-12 col-sm-6'>
                        <div className='c-card border-around border-radius-4px p-3'>
                            <h1>record</h1>
                        </div>

                    </div>
                </div> */}
                <EntriesTable />
            </div>
        </>
    )

}


export default TimesSheetScreen;