import { fetchWithToken } from '../../helpers/fetch';
import { openSnackbar } from '../../helpers/helpers';
import activitiesTypes from './../types/acitivitiesTypes';

const controller = 'activities';

export const startGetactivities = () => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/get-all`, {}, 'GET');
        const body = await res.json();

        if (body.succeeded) {

            dispatch(setActivities(body.data));

        } else {
            alert(body.message);
        }
    }
}

export const startGetActiveActivity = (id, view) => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/get/${id}`, {}, 'GET');
        const body = await res.json();
        view.setLoading(false);
        if (body.succeeded) {
            dispatch(setActiveActivity(body.data));
        } else {
            alert(body.message);
        }
    }
}

export const startCreateActivity = (activity, view) => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/create`, activity, 'POST');
        const body = await res.json();
        view.setLoading(false);

        if (body.succeeded) {
            activity.id = body.message;
            dispatch(addActivity(activity));
            view.enqueueSnackbar('The activity has been created', { variant: 'success', autoHideDuration: 3000 });
            view.resetForm();

        } else {
            alert(body.message);
        }
    }
}

export const startUpdateActivity = (activity, view) => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/update/${activity.id}`, activity, 'POST');
        const body = await res.json();
        view.setLoading(false);
        
        if (body.succeeded) {
            dispatch(updateActivity(activity));
            view.enqueueSnackbar('The activity has been updated', { variant: 'success', autoHideDuration: 3000 });
        } else {
            alert(body.message);
        }
    }
}

export const startDeleteActivity = (activity, view) => {
    return async (dispatch) => {
        const res = await fetchWithToken(`${controller}/delete/${activity.id}`, {}, 'GET');
        const body = await res.json();

        if (body.succeeded) {
            dispatch(deleteActivity(activity));
            view.enqueueSnackbar('The activity has been deleted', { variant: 'success', autoHideDuration: 3000 });
            view.setSelectedItem(null);
        } else {
            openSnackbar(view.enqueueSnackbar, body.message, 'error');
        }
    }
}

const setActivities = (activities) => ({
    type: activitiesTypes.activitiesSetActivities,
    payload: activities
})


const addActivity = (activity) => ({
    type: activitiesTypes.activitiesAddActivity,
    payload: activity
})

const updateActivity = (activity) => ({
    type: activitiesTypes.activitiesUpdateActivity,
    payload: activity
})


const deleteActivity = (activity) => ({
    type: activitiesTypes.activitiesDeleteActivity,
    payload: activity.id
})

export const setActiveActivity = (activity) => ({
    type: activitiesTypes.activitiesSetActive,
    payload: activity
})

export const activitiesClearAll = () => ({
    type: activitiesTypes.activitiesClearAll
})