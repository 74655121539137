

import { Alert, Button, IconButton, InputBase, Snackbar, TextField } from '@mui/material';
import { Close, Info } from '@mui/icons-material';

import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../hooks/useForm'
import { starRecoverPassword, startLogin, startSignIn } from '../../redux/actions/auth';

// import logo from './../../assets/logo-black.svg';
import bgimage from './../../assets/1.webp';
import logo from './../../assets/logos/tt-logo-black.svg';
// import bgimage from './../../assets/logo-black.png';
// import bgimage from './../../assets/test-bg-1.jpg';
// import logo from "./../../assets/tt-logo-white.png";

import { withStyles } from '@mui/material/styles'
import { useSnackbar } from 'notistack';
import md5 from 'md5';
import { Link, NavLink, useNavigate } from 'react-router-dom';

export const RecoverPassword = () => {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [formValues, handleInputChange, reset, setLoginUser] = useForm({
        email: ""
    });

    const { email } = formValues;


    const handleSubmit = async (e) => {
        e.preventDefault();

        var validForm = validateForm();

        if (validForm) {
            setLoading(true);
            const result = await starRecoverPassword(email, { setLoading, success });
        }
    }



    const [emailError, setEmailError] = useState(false);


    const handleEmailChange = (e) => {
        handleInputChange(e);
        const value = e.target.value;
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(value)) {
            setEmailError(true);
        } else {
            setEmailError(false);
        }
    }


    const validateForm = () => {

        let valid = true;

        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(email)) {
            setEmailError(true);
            valid = false;
        }

        return valid;
    }

    const errors = () => emailError;


    const [open, setOpen] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };




    const success = () => {

        enqueueSnackbar("✅ An email has been sent to recover your password.", {
            // variant: 'success',
            autoHideDuration: 3000,
            anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
            action: (key) => (
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={() => closeSnackbar(key)}
                >
                    <Close fontSize="small" />
                </IconButton>
            ),
        });


    }


    const action = (
        <>
            <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <Close fontSize="small" />
            </IconButton>
        </>
    );

    return (
        <>
            <div className="auth-container">

                <div>
                    <div className="auth-header d-flex flex-column">
                        {/* <img src={logo} className="tt-logo" /> */}
                        <span className="login-title">
                            <span className="login-title-time-text">
                                TIME
                            </span>
                            <span className="login-title-tracker-text">
                                TRACKER
                            </span>
                        </span>
                    </div>

                    <div className="d-flex justify-content-center">
                        <h1 className="auth-title">RECOVER PASSWORD</h1>
                    </div>
                    <form className="auth-form" onSubmit={handleSubmit} >
                        <div>

                            <input type="text" placeholder="Email" className="mi mi-large mt-2 w-100" name="email" defaultValue={email || null} onChange={handleEmailChange} />
                            <span className="form-error-msg">
                                {
                                    emailError && 'Enter a valid email address.'
                                }
                            </span>

                        </div>

                        <div className="d-flex flex-column mt-2">
                            <button type="submit" className={`mb btn-darkk btn-login-large w-100 position-relative ${loading ? ' spinner login-spinner' : ''}`} disabled={
                                loading || errors()}>
                                {
                                    !loading && <span>Recover Password</span>
                                }
                            </button>
                            <Link to="/auth/login" className="mt-4 auth-link">Login</Link>
                        </div>

                        <div className="version-container">
                            <span className="version">v0.1</span>
                        </div>
                    </form>
                </div>


            </div>
        </>
    )
}
