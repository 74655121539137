import types from './../types/authTypes';

const initialState = {
    checking: true,
    logged: false,
    user: null,
    loading: false
};

const authReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.authLogin:
            return {
                ...state,
                user: { ...action.payload },
                checking: false,
                logged: true
            }

        case types.authCheckingFinish:
            return {
                ...state,
                user: { ...action.payload },
                checking: false
            }

        case types.authUpdateUser:
            return {
                ...state,
                user: { ...state.user, userName: action.payload.userName, fullName: action.payload.fullName }
            }



        case types.authLogout:
            return {
                checking: false,
                logged: false
            }

        default:
            return state;
    }

}

export default authReducer;