import projectsTypes from './../types/projectsTypes';

const initialState = {
    projects: [],
    userProjects: [],
    active: null,
    activeDetail: null,
}

const projectsReducer = (state = initialState, action) => {
    switch (action.type) {

        case projectsTypes.projectsSetProjects:
            return {
                ...state,
                projects: [...action.payload]
            }

        case projectsTypes.projectsAddProject:
            return {
                ...state,
                projects: [{ ...action.payload }, ...state.projects]
            }


        case projectsTypes.projectsUpdateProject:
            return {
                ...state,
                projects: state.projects.map(a => {
                    return a.id == action.payload.id ? { ...action.payload } : a
                })
            }

        case projectsTypes.projectsDeleteProject:
            return {
                ...state,
                projects: state.projects.filter(a => a.id !== action.payload)
            }

        case projectsTypes.projectsSetActive:
            return {
                ...state,
                active: action.payload
            }

        case projectsTypes.projectsSetActiveDetail:
            return {
                ...state,
                activeDetail: action.payload
            }

        case projectsTypes.projectsUpdateUserProjectRole:

            let userProjects = state.activeDetail.userProjects?.map(x => {

                if(x.userID == action.payload.userToChangeID) {
                    return {
                        ...x,
                        userRoleInProject: action.payload.userRoleInProject
                    }
                } else {
                    return x;
                }
            })

            return {
                ...state,
                activeDetail: {...state.activeDetail, userProjects: userProjects}
            }

        case projectsTypes.projectsRemoveUser:
            return {
                ...state,
                activeDetail: {...state.activeDetail, userProjects: state.activeDetail.userProjects?.filter(x => (x.userID != action.payload) )}
            }

        case projectsTypes.projectsSetUserProjects:
            return {
                ...state,
                userProjects: action.payload
            }

        case projectsTypes.projectClearAll: 
            return initialState;
        default:
            return state;
    }
}

export default projectsReducer;