import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startGetProjects } from '../../../redux/actions/projects';
import { setResultsByProjectFile, startDownloadReportByProject, startGetReportByProject } from '../../../redux/actions/reports';
import ButtonGenerateReport from '../../formControls/ButtonGenerateReport';
import { InputSeatch } from '../../formControls/InputSeatch';
import DateRange from '../../formControls/DateRange';
import CustomCheckBox from '../../formControls/CustomCheckBox';
import { openSnackbar } from '../../../helpers/helpers';

export const ReportByProject = () => {

    const { enqueueSnackbar } = useSnackbar();


    const { projects } = useSelector(state => state.projects);

    const { reportByProjectFile } = useSelector(state => state.reports);

    const [projectsToDisplay, setProjectsToDisplay] = useState([]);

    const dispatch = useDispatch();

    const [selectedProject, setSelectedProject] = useState('');

    const [groupByUsers, setGroupByUsers] = useState(false);

    const [allProjects, setAllProjects] = useState(false);

    const [loading, setLoading] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);

    const [dateFrom, setDateFrom] = useState();
    const [dateTo, setDateTo] = useState();


    const handleOnChangeFrom = (df) => {
        setDateFrom(df);
    }

    const handleOnChangeTo = (dt) => {
        setDateTo(dt);
    }

    useEffect(() => {
        dispatch(startGetProjects());
    }, []);

    useEffect(() => {
        setProjectsToDisplay(projects);
    }, [projects]);

    useEffect(() => {
        setGroupByUsers(false);
    }, [allProjects]);

    useEffect(() => {

        if (reportByProjectFile) {
            const byteArray = new Uint8Array(atob(reportByProjectFile).split('').map(char => char.charCodeAt(0)));
            const blob = new Blob([byteArray], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'reporte.pdf';
            document.body.appendChild(link);
            link.click();
            dispatch(setResultsByProjectFile(null));

        }
    }, [reportByProjectFile])


    const handleGetPreview = () => {
        if (selectedProject == '' && !allProjects) {
            openSnackbar(enqueueSnackbar, 'Select Project', 'error');
            return;
        }
        setLoading(true);
        dispatch(startGetReportByProject(dateFrom,
            dateTo, selectedProject == '' ? 0 : selectedProject, groupByUsers, allProjects, { enqueueSnackbar, setLoading }));
    }

    const filterProjects = (e) => {
        const value = e.target.value;
        if (value) {
            const results = projects.filter(x => x.name.toLowerCase().includes(value.toLowerCase()));
            setProjectsToDisplay(results);
        } else {
            setProjectsToDisplay(projects);
        }
    }

    const allProjectsClicked = () => {
        setAllProjects(!allProjects);
    }

    const generateFile = (type) => {
        setLoadingDownload(true);
        dispatch(startDownloadReportByProject(dateFrom,
            dateTo, selectedProject == '' ? 0 : selectedProject, groupByUsers, allProjects, { enqueueSnackbar, setLoading: setLoadingDownload }));
    }

    return (
        <div className="d-flex flex-column flex-grow-1 justify-content-between">
            <div>

                <div className="c-form-group">
                    <label>Select Projects</label>
                    <InputSeatch onChange={filterProjects} placeholder={"Filter..."} />
                    <ProjectsList projects={projectsToDisplay} selectedProject={selectedProject} setSelectedProject={setSelectedProject} allProjects={allProjects} />
                </div>




                <div className="form-check c-form-group mt-12px">
                    <input className="form-check-input c-checkbox" type="checkbox" onChange={allProjectsClicked} checked={allProjects} id="allProjects" />
                    <label className="form-check-label c-checkbox-label" htmlFor="allProjects">
                        All Projects
                    </label>
                </div>

                <Options
                    groupByUsers={groupByUsers}
                    setGroupByUsers={setGroupByUsers}
                    allProjects={allProjects}
                />
            </div>

            <div className="d-flex flex-column mt-12px">
                <DateRange onChangeFrom={handleOnChangeFrom} onChangeTo={handleOnChangeTo} />
                <div className='mt-12px'>

                    <button className="mb btn-blue w-100" disabled={loading} onClick={handleGetPreview} >Preview Data</button>
                    <div className="mt-12px d-flex">
                        <ButtonGenerateReport generateFile={generateFile} loading={loadingDownload} />
                    </div>
                </div>
                {/* <ReportByUserResult /> */}
            </div>
        </div>
    )
}

const Options = ({ groupByUsers, setGroupByUsers, allProjects }) => {

    const handleGroupByUsers = () => {
        setGroupByUsers(!groupByUsers);
    }

    return (

        // <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

        //     <ListItem
        //         disablePadding
        //     >
        //         <ListItemButton role={undefined} onClick={handleGroupByUsers} disabled={allProjects}
        //             sx={{ py: 0, minHeight: 32 }} dense
        //         >
        //             <ListItemIcon style={{ minWidth: 24 }}>
        //                 <Checkbox
        //                     edge="start"
        //                     tabIndex={-1}
        //                     checked={groupByUsers}
        //                     className="p-0"
        //                     disableRipple
        //                     disabled={allProjects}
        //                     inputProps={{ 'aria-labelledby': 'checkbox-list-label' }}
        //                 />
        //             </ListItemIcon>
        //             <ListItemText id={'checkbox-list-label'} primary="Group by users" />
        //         </ListItemButton>
        //     </ListItem>


        // </List>
        <div className="c-form-group mt-12px">
            <label>Options</label>
            <CustomCheckBox id={'groupByUsers'} text="Group by users" checked={groupByUsers} disabled={allProjects} handleChange={handleGroupByUsers} />
        </div>
    )
}

const ProjectsList = ({ projects, selectedProject, setSelectedProject, allProjects }) => {

    const handleToggle = (event) => {
        setSelectedProject(event.target.value);
    }

    useEffect(() => {
        setSelectedProject('');
    }, [allProjects])


    return (

        <div className="reports__items-list">
            <select value={selectedProject}
                className="mi mt-2"
                name="selecteProject"
                disabled={allProjects}
                onChange={handleToggle}>
                <option value="" disabled></option>

                {
                    projects?.map((p) => <option key={p.id} value={p.id}>{p.name}</option>)
                }
            </select>
        </div>

    )
}