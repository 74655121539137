import { ArrowBack } from '@mui/icons-material';
import { Button, ButtonBase, IconButton, Paper, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from '../../../hooks/useForm';
import { startGetCustomers } from '../../../redux/actions/customers';
import { startCreateProject } from '../../../redux/actions/projects';
import { openSnackbar } from '../../../helpers/helpers';

const CreateProject = () => {

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const { customers } = useSelector(state => state.customers);

  const [loading, setLoading] = useState(false);


  useEffect(() => {
    dispatch(startGetCustomers());
  }, [])


  const [formValues, handleInputChange, reset] = useForm({
    name: '',
    customerID: -1
  });

  const { name, customerID } = formValues;


  const handleOnSubmit = (e) => {
    e.preventDefault();
    if(customerID == -1){
      openSnackbar(enqueueSnackbar, 'Select Customer', 'error');
      return;
    }
    setLoading(true);
    dispatch(startCreateProject(formValues, { resetForm: reset, enqueueSnackbar, setLoading }));
  }

  const navigate = useNavigate();

  const navigateBack = () => {
    navigate("/projects");
  }

  return (
    <div className="container mt-2">

      <div className="page-header">

        <div className="d-flex align-items-center">
          <Tooltip onClick={navigateBack}>
            <IconButton>
              <ArrowBack />
            </IconButton>
          </Tooltip>
          <h2 className="ms-2">Create Project</h2>
        </div>
      </div>


        <div className="row">
          <div className="col-md-6">
            <form onSubmit={handleOnSubmit} className="default-form">
              <div>
                <label>Project Name</label>
                <input type="text" value={name} name="name" onChange={handleInputChange} className="mi w-100 mt-2" />
              </div>


              <div className="mt-3">
                <label>Customer</label>

                <select className="mi mt-2" value={customerID} name="customerID" onChange={handleInputChange}>
                  <option value="-1"></option>
                  {
                    customers?.map(c => (
                      <option value={c.id} key={c.id}>{c.name}</option>
                    ))
                  }
                </select>
              </div>

              <div className="mt-3 d-flex justify-content-end">
              <button className="mb btn-blue" disabled={loading} >Create</button>
              </div>

            </form>
          </div>
        </div>

        </div>
  )
}

export default CreateProject;