import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReportByCustomerResult } from "./types/ReportByCustomerResult";
import { ReportByProjectResult } from "./types/ReportByProjectResult";
import { ReportByUserResult } from "./types/ReportByUserResult";


const ReportResult = () => {

    const { reportByCustomerResults, reportByProjectResults, reportByUserResults } = useSelector(state => state.reports);

    useEffect(() => {
        // console.log(reportByCustomerResults);
        // console.log(reportByProjectResults);
        // console.log(reportByUserResults);
    },[])
    return (
        <>
            <ReportByCustomerResult />
            <ReportByProjectResult />
            <ReportByUserResult />
            {

                (reportByCustomerResults == null && reportByProjectResults == null && reportByUserResults == null) ? <div className="reports__result-no-content  "></div> : <></>
            }
        </>
    )
}

export default ReportResult;