
export const displayError = (body, enqueueSnackbar) => {

    let error = 'Unknown Error.'
    if(body?.message) {
        error = body?.message;

    } else if(body?.errors?.length > 0) {
        error = body?.errors[0];
    }
    enqueueSnackbar(error, { variant: 'error', autoHideDuration: 3000 });
}

export const displaySuccess = (msg, enqueueSnackbar) => {

    enqueueSnackbar(msg, { variant: 'success', autoHideDuration: 3000 });
}