import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import DateRange from "../formControls/DateRange";
import moment from "moment";
import { startGetDashboard } from "../../redux/actions/dashboard";
import { useSnackbar } from "notistack";
import { CustomersHours } from "./itemsHours/CustomersHours";
import { ProjectsHours } from "./itemsHours/ProjectsHours";
import { ActivitiesHours } from "./itemsHours/ActivitiesHours";


import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { CustomersChart } from "../charts/CustomersChart";
import { HoursByMonth } from "./itemsHours/HoursByMonth";


const MONTH = 'month';
const LAST_MONTH = 'lsat_month';
const WEEK = 'week';



export const Dashboard = () => {


    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);


    const { dashboard } = useSelector(state => state.dashboard);
    const [currentDateFrom, setCurrentDateFrom] = useState('');
    const [currentDateTo, setCurrentDateTo] = useState('');

    useEffect(() => {
        if (currentDateFrom && currentDateTo) {
            setLoading(true);
            dispatch(startGetDashboard(currentDateFrom, currentDateTo, { enqueueSnackbar, setLoading }));
        }
    }, [currentDateFrom, currentDateTo])

 

    const handleOnChangeFrom = (dateFrom) => {
        setCurrentDateFrom(dateFrom);
    }

    const handleOnChangeTo = (dateTo) => {
        setCurrentDateTo(dateTo);
    }
  

    return (

        <>
            <div className=" mt-2">
                <div className="page-header container">
                    <h2>Dashboard</h2>

                    <div className="date-range-container">
                        <DateRange onChangeFrom={handleOnChangeFrom} onChangeTo={handleOnChangeTo} />
                    </div>

                    {/* <button className="mb btn-green" onClick={handleAddUser} >Invite User</button> */}
                </div>



                {
                    dashboard && <>
                        <div className="dashboard__stats ">
                            <div className="container">
                                <div className="row p-0 m-0 justify-content-center">

                                    <div className="col-6 col-sm-3">
                                        <h2>{dashboard.totalHours.toFixed(2)} </h2>
                                        <h3>HOUR{dashboard.totalHours > 1 && 'S'}</h3>
                                    </div>
                                    <div className="col-6 col-sm-3">
                                        <h2>{dashboard.customersHours.length} </h2>
                                        <h3>CUSTOMER{dashboard.customersHours.length > 1 && 'S'}</h3>
                                    </div>
                                    <div className="col-6 col-sm-3">
                                        <h2>{dashboard.projectsHours.length} </h2>
                                        <h3>PROJECT{dashboard.projectsHours.length > 1 && 'S'}</h3>
                                    </div>
                                    <div className="col-6 col-sm-3">
                                        <h2>{dashboard.usersData.length} </h2>
                                        <h3>COLLABORATOR{dashboard.usersData.length > 1 && 'S'}</h3>
                                    </div>
                                </div>
                            </div>


                        </div>

                        {
                            dashboard && <>
                                <div className="container">
                                    <div className="row dashboard__hours-by-items" >

                                        {/* 
                                <div className="col-12 col-sm-4 dashboard__hours-by-item">
                                    <CustomersHours customers={dashboard.customersHours} />
                                </div>
                                <div className="col-12 col-sm-4 dashboard__hours-by-item">
                                    <ProjectsHours projects={dashboard.projectsHours} />
                                </div> */}
                                        <div className="col-12 col-sm-12">
                                            <div className="container dashboard_timeline">
                                                <h2>Customers Hours</h2>
                                                <CustomersChart customers={dashboard.customersHours} />
                                            </div>
                                        </div>
                                        {/* <div className="col-12 col-sm-4 dashboard__hours-by-item">
                                            <HoursByMonth />
                                        </div> */}
                                    </div>
                                    {/* <UsersList /> */}
                                </div>
                            </>
                        }

                    </>
                }

                {/* <div className="container dashboard_timeline">
                    <h2>Hours Timeline</h2>
                    <HoursTimeLine />
                </div>  */}


            </div>


        </>
    )
}