import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

export const ReportByCustomerResult = () => {

  const { reportByCustomerResults } = useSelector(state => state.reports);


  // useEffect(() => {
  //   console.log(reportByCustomerResults);
  // }, [reportByCustomerResults])


  return (
    <>
      {reportByCustomerResults && (
        <div className="reports__result-content">
          {
            reportByCustomerResults.allCustomers ?     
            <div className='p-3 border-bottom'>
              Customers Report
            </div> : 
              <div className="p-3 border-bottom">
              <span className="blue-grey-text">Customer</span> <br /> <span className="customer-name fw-500">{reportByCustomerResults.customerName}</span>
            </div>
          }
          <table className={'table table-sm report-preview-table'} >
            <tbody>

              {/* {
                reportByCustomerResults.customersHours.length == 0 &&
                <tr>
                  <td> <span className="blue-grey-text">Customer</span> <br /> <span className="customer-name fw-500">{reportByCustomerResults.customerName}</span></td>
                  {
                    reportByCustomerResults.usersProjectsHours?.length > 0 && <td></td>
                  }
                  <td></td>
                </tr>
              } */}

              {
                reportByCustomerResults.usersProjectsHours?.length > 0 && (
                  <tr className="detailed-header">
                    <td>
                      User
                    </td>

                    <td>
                      Project
                    </td>

                    <td className="text-align-right">
                      Hours
                    </td>
                  </tr>
                )
              }
              {
                reportByCustomerResults.usersProjectsHours?.map((up, i) => (
                  <tr key={i}>
                    <td>{up.fullName}</td>
                    <td>{up.projectName}</td>
                    <td className="text-align-right">{up.totalHours} h</td>
                  </tr>
                ))
              }

              {
                reportByCustomerResults.projectsHours?.length > 0 && (
                  <tr className="detailed-header">
                    <td>
                      Project
                    </td>

                    <td className="text-align-right">
                      Hours
                    </td>
                  </tr>
                )
              }
              {
                reportByCustomerResults.projectsHours?.map((p, i) => (
                  <tr key={i}>
                    <td>{p.projectName}</td>
                    <td className="text-align-right">{p.totalHours} h</td>
                  </tr>
                ))
              }
              {
                reportByCustomerResults.usersHours?.length > 0 && (
                  <tr className="detailed-header">
                    <td>
                      User
                    </td>
                    <td className="text-align-right">
                      Hours
                    </td>
                  </tr>
                )
              }

              {
                reportByCustomerResults.usersHours?.map((u, i) => (
                  <tr key={i}>
                    <td>{u.fullName}</td>
                    <td className="text-align-right">{u.totalHours} h</td>
                  </tr>
                ))
              }





              {
                reportByCustomerResults.customersHours?.length > 0 && (
                  <tr className="detailed-header">
                    <td>
                      Customer
                    </td>
                    <td className="text-align-right">
                      Hours
                    </td>
                  </tr>
                )
              }

              {
                reportByCustomerResults.customersHours?.map((ch, i) => (
                  <tr key={i}>
                    <td>{ch.customerName}</td>
                    <td className="text-align-right">{ch.totalHours} h</td>
                  </tr>
                ))
              }




              <tr className="table-footer">
                <td></td>

                {
                  reportByCustomerResults.usersProjectsHours?.length > 0 && <td></td>
                }
                <td className="text-align-right"><span className="fw-500 me-2"> Total: </span>{reportByCustomerResults.totalHours} h</td>

              </tr>

            </tbody>
          </table>
        </div>
      )}
    </>
  )
}
