import { Provider } from "react-redux";
import { store } from "./redux/store/store";
import AppRouter from "./routers/AppRouter";
import { SnackbarProvider } from 'notistack';


function WorkTracker() {


  return (
    <SnackbarProvider maxSnack={6}>
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </SnackbarProvider>
  );
}

export default WorkTracker;
